import { Http } from "../Http";
import { AxiosPromise } from "axios";
import apiCall from "../api/apiCall";

const testApi = {
	RequestArchive: async (input) => {
		const userId = input.userId;
		const userToken = input.userToken;
		const clientId = input.clientId;
		const dateFrom = input.dateFrom.toISOString();
		const dateTo = input.dateTo.toISOString();
		const siteId = input.siteId;
		const samplePlanId = input.samplePlanId;

		const url = `Test/RequestArchive?userId=${userId}&userToken=${userToken}&clientId=${clientId}&dateFrom=${dateFrom}&dateTo=${dateTo}&siteId=${siteId}&samplePlanId=${samplePlanId}`;

		const response = await apiCall(url, "get");
		return response;
	},

	GetAllArchives: async (input) => {
		const userId = input.userId;
		const userToken = input.userToken;

		const url = `Test/GetAllArchives?userId=${userId}&userToken=${userToken}`;
		const response = await apiCall(url, "get");
		return response;
	},

	DownloadArchive: async (input) => {
		const userId = input.userId;
		const userToken = input.userToken;
		const archiveId = input.archiveId;

		const url = `Test/DownloadArchive?userId=${userId}&userToken=${userToken}&archiveId=${archiveId}`;
		const response = await apiCall(url, "get");
		return response;
	},
};

export default testApi;
