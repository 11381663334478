import apiCall from "../api/apiCall";

const testHistoryApi = {
	getAllBySite: async (input) => {
		const url =
			"TestHistory/GetAllBySite" +
			"?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken +
			"&siteId=" +
			input.siteId +
			"&take=" +
			"&skip=" +
			input.skip +
			input.take;
		const response = await apiCall(url, "get");
		return response;
	},

	getAllWithImages: async (input) => {
		let sortingColumn = input.sorting[0]?.id || "date";
		if (sortingColumn === "timeStamp") sortingColumn = "date";
		const sortingDirection = input.sorting[0]?.desc || true;

		const siteId = input.siteId || 0;
		const samplePlanId = input.samplePlanId || 0;
		const testPointId = input.testPointId || 0;
		const completedByUserId = input.completedByUserId || 0;

		function isValidDate(date) {
			return date instanceof Date && !isNaN(date);
		}

		const defaultDateFrom = "2021-01-01T00:00:00.000Z";
		const defaultDateTo = new Date().toISOString();

		const dateFrom =
			input.dateFrom && isValidDate(new Date(input.dateFrom))
				? new Date(input.dateFrom).toISOString()
				: defaultDateFrom;

		const dateTo =
			input.dateTo && isValidDate(new Date(input.dateTo))
				? new Date(input.dateTo).toISOString()
				: defaultDateTo;

		const url =
			"TestHistory/GetAllWithImages" +
			"?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken +
			"&skip=" +
			input.skip +
			"&take=" +
			input.take +
			"&siteId=" +
			siteId +
			"&samplePlanId=" +
			samplePlanId +
			"&testPointId=" +
			testPointId +
			"&completedByUserId=" +
			completedByUserId +
			"&dateFrom=" +
			dateFrom +
			"&dateTo=" +
			dateTo +
			"&orderBy=" +
			sortingColumn +
			"&descending=" +
			sortingDirection +
			"&search=" +
			input.search;

		const response = await apiCall(url, "get");
		return response;
	},

	getOfflineTestHistory: async (input) => {
		const url =
			"TestHistory/GetOfflineTestHistory" +
			"?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken;
		const response = await apiCall(url, "get");
		return response;
	},
};

export default testHistoryApi;
