import * as React from "react";
import { useState } from "react";
import FCInputField from "../../utils/library/textbox";
import FCButton from "../../utils/library/button";
import { Validators, validateInput } from "../../utils/library/Validator";
import { CheveronCircleLeftSvgIcon } from "../../utils/common/icon-hooks.js";
import accountApi from "../../utils/api/accountApi";

const Register = () => {
	const [company, setCompany] = useState("");
	const [email, setEmail] = useState("");
	const [pwd, setPwd] = useState("");
	const [cnfPwd, setCnfPwd] = useState("");
	const [username, setUsername] = useState("");
	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [isCompanyEntered, setIsCompanyEntered] = useState(false);
	const [isUsernameEntered, setIsUsernameEntered] = useState(false);
	const [isFirstnameEntered, setIsFirstnameEntered] = useState(false);
	const [isLastnameEntered, setIsLastnameEntered] = useState(false);
	const [isEmailEntered, setIsEmailEntered] = useState(false);
	const [isPwdEntered, setIsPwdEntered] = useState(false);
	const [isCnfPwdEntered, setIsCnfPwdEntered] = useState(false);
	const [isValidEmail, setIsValidEmail] = useState(false);
	const [isValidPassword, setIsValidPassword] = useState(false);
	const [isValidConfPassword, setIsValidConfPassword] = useState(false);
	const [isPasswordNotMatched, setIsPasswordNotMatched] = useState(false);
	const [errorMessage, setErrorMessage] = useState(true);
	// const [userAuthData, setUserAuthData] = useState({});
	const [isSuccess, setIsSuccess] = useState(false);
	const [error, setError] = useState(false);

	const validators = [
		{
			check: Validators.required,
			message: "Please enter the required fields.",
		},
	];
	const emailValidator = [
		{
			check: Validators.email,
			message: "Enter the email id.",
		},
	];
	const passwordValidator = [
		{
			check: Validators.password,
			message: "Please enter valid password.",
		},
	];

	const handleChange = (event) => {
		if (event.target.name === "password") {
			setPwd(event.target.value);
			setIsPwdEntered(validateInput(validators, event.target.value));
			setIsValidPassword(validateInput(passwordValidator, event.target.value));
		}
		if (event.target.name === "cnfPassword") {
			setCnfPwd(event.target.value);
			setIsCnfPwdEntered(validateInput(validators, event.target.value));
			setIsValidConfPassword(
				validateInput(passwordValidator, event.target.value),
			);
		}
		if (event.target.name === "company") {
			setCompany(event.target.value);
			setIsCompanyEntered(validateInput(validators, event.target.value));
		}
		if (event.target.name === "email") {
			setEmail(event.target.value);
			setIsEmailEntered(validateInput(validators, event.target.value));
			setIsValidEmail(validateInput(emailValidator, event.target.value));
		}
		if (event.target.name === "username") {
			setUsername(event.target.value);
			setIsUsernameEntered(validateInput(validators, event.target.value));
		}
		if (event.target.name === "firstname") {
			setFirstname(event.target.value);
			setIsFirstnameEntered(validateInput(validators, event.target.value));
		}
		if (event.target.name === "lastname") {
			setLastname(event.target.value);
			setIsLastnameEntered(validateInput(validators, event.target.value));
		}
	};

	const handleRegisterAccount = (event) => {
		event.preventDefault();
		setIsValidPassword(validateInput(passwordValidator, pwd));
		setIsValidConfPassword(validateInput(passwordValidator, pwd));
		setIsPwdEntered(validateInput(validators, pwd));
		setIsCnfPwdEntered(validateInput(validators, cnfPwd));
		setIsCompanyEntered(validateInput(validators, company));
		setIsEmailEntered(validateInput(validators, email));
		setIsValidEmail(validateInput(emailValidator, email));
		setIsUsernameEntered(validateInput(validators, username));
		setIsFirstnameEntered(validateInput(validators, firstname));
		setIsLastnameEntered(validateInput(validators, lastname));

		if (pwd !== cnfPwd) {
			setIsPasswordNotMatched(true);
			setIsValidPassword(true);
			setIsValidConfPassword(true);
			return;
		}

		//The backend will do the validations for Company exist, email exist
		//and password validation
		if (isPageValid()) {
			// dispatch(
			// 	registerAccount({
			// 		companyName: company,
			// 		username: username,
			// 		password: pwd,
			// 		cnfPassword: cnfPwd,
			// 		email: email,
			// 		firstName: firstname,
			// 		lastName: lastname,
			// 	})
			// );
			handleRegister();
		}
	};

	const handleRegister = async () => {
		const response = await accountApi.register({
			companyName: company,
			username: username,
			password: pwd,
			cnfPassword: cnfPwd,
			email: email,
			firstName: firstname,
			lastName: lastname,
		});

		if (response.status === 200) {
			// setUserAuthData(response);
			setIsSuccess(true);
		} else {
			setError(response.statusText);
		}
	};

	const isPageValid = () => {
		if (
			company !== "" &&
			pwd !== "" &&
			cnfPwd !== "" &&
			firstname !== "" &&
			username !== "" &&
			email !== "" &&
			lastname !== ""
		) {
			return true;
		}
		return false;
	};

	const goToLoginPage = (event) => {
		event.preventDefault();
		window.location.href = "./login";
	};
	// if (authData && !authData.status.statusValue) {
	// 	//alert(authData.status.statusDisplay);
	// 	if (
	// 		authData.status.statusDisplay.toLowerCase().indexOf("invalid email") > 0
	// 	) {
	// 		//setIsValidEmail(false);
	// 	}
	// }

	// if (userAuthData.data && !userAuthData.status === 200) {
	// 	if (userAuthData.statusText.toLowerCase().indexOf("invalid email") > 0) {
	// 		setIsValidEmail(false);
	// 	}
	// }

	return (
		<React.Fragment>
			<div className="min-h-screen flex items-center justify-center bg-primary p-12 px-4 sm:px-6 lg:px-8">
				<div className="max-w-md w-full space-y-8 py-6 pl-6 pr-12 bg-white rounded-2xl border-4">
					<div className="mt-8">
						<img
							className="mx-auto h-12 w-auto"
							src="../../../../assets/logo/logo.png"
							alt="FreshCheck"
						/>
					</div>
					{/* if status value is an empty string and isError is false */}
					{/* {!authData.status.statusValue && !authData.isError && ( */}
					{!error && (
						<div className="pb-20">
							<form
								className="pt-3"
								onSubmit={(event) => handleRegisterAccount(event)}
							>
								<button
									type="button"
									onClick={(event) => goToLoginPage(event)}
									className="inline-block border border-transparent rounded-full bg-white-600 align-top pt-3"
								>
									<CheveronCircleLeftSvgIcon />
								</button>
								<div className="inline-block rounded-2xl -mt-5 ml-4 py-6 w-4/5">
									<FCInputField
										isBorder={false}
										className="bg-fcinput"
										type="text"
										id="company"
										placeholder="Company Name"
										icon="lock"
										isError={isCompanyEntered}
										onChange={handleChange}
										value={company}
									/>

									<FCInputField
										isBorder={false}
										className="bg-fcinput"
										type="text"
										id="username"
										placeholder="Username"
										icon="user"
										isError={isUsernameEntered}
										onChange={handleChange}
										value={username}
									/>

									<FCInputField
										isBorder={false}
										className="bg-fcinput"
										type="text"
										id="firstname"
										placeholder="First Name"
										icon="email"
										isError={isFirstnameEntered}
										onChange={handleChange}
										value={firstname}
									/>

									<FCInputField
										isBorder={false}
										className="bg-fcinput"
										type="text"
										id="lastname"
										placeholder="Last Name"
										icon="email"
										isError={isLastnameEntered}
										onChange={handleChange}
										value={lastname}
									/>

									<FCInputField
										isBorder={false}
										className="bg-fcinput"
										type="text"
										id="email"
										placeholder="Email"
										icon="email"
										isError={isEmailEntered || isValidEmail}
										onChange={handleChange}
										value={email}
									/>

									<FCInputField
										isBorder={false}
										className="bg-fcinput"
										type="password"
										id="password"
										placeholder="Password"
										icon="pwd"
										isError={isPwdEntered || isValidPassword}
										onChange={handleChange}
										value={pwd}
									/>

									<FCInputField
										isBorder={false}
										className="bg-fcinput"
										type="password"
										id="cnfPassword"
										placeholder="Confirm Password"
										icon="pwd"
										isError={isCnfPwdEntered || isValidConfPassword}
										onChange={handleChange}
										value={cnfPwd}
									/>
									<div>
										<FCButton
											bgColor="bg-fcbutton"
											type="submit"
											icon="rightarrow"
											value="Create Account"
										/>
									</div>
								</div>
							</form>
							{
								<>
									<p className="text-lg h-2 text-red-500 mt-5  text-black  text-center">
										{/* This was the reponse message returned from the api if there was an error */}
										{/* <p>{authData.status.statusDisplay}</p> */}
										<p>{error}</p>

										<p>
											{(isValidPassword || isValidConfPassword) &&
												!isPasswordNotMatched &&
												"Password should contain 8 characters with, 1 upper, 1 lower and 1 number."}
										</p>

										<p>
											{isPasswordNotMatched &&
												"New Password is not matching with Confirm Password."}
										</p>

										<p>{isValidEmail && "Invalid email."}</p>

										<p>
											{" "}
											{((!isValidEmail && isCompanyEntered) ||
												isPwdEntered ||
												isCnfPwdEntered ||
												isUsernameEntered ||
												isFirstnameEntered ||
												isLastnameEntered ||
												isEmailEntered) &&
												"Please enter the required fields."}
										</p>
									</p>
								</>
							}
						</div>
					)}
					{/* {authData.isAccountRegistered && authData.status.statusValue && ( */}
					{isSuccess && (
						<div className="pb-40">
							<p className="text-lg h-2  text-black  text-center pb-20">
								Account created successfully.
							</p>
							<div>
								<form onSubmit={(event) => goToLoginPage(event)}>
									<FCButton
										bgColor="bg-fcbutton"
										type="submit"
										icon="rightarrow"
										value="Login"
									/>
								</form>
							</div>
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default Register;

// const mapStateToProps = (state) => {
// 	const { authData } = state;
// 	return {
// 		authData,
// 	};
// };

// export default connect(mapStateToProps)(Register);
