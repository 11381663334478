import * as React from "react";
import { connect } from "react-redux";

const TsetComponent = () => {
	const [isLogout, setIsLogout] = React.useState(false);
	return (
		<>
			<main className="-mt-24 pb-8">
				<div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
					<h1 className="sr-only">Page title</h1>
					{/*<!-- Main 3 column grid -->*/}
					<div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
						{/*<!-- Left column -->*/}
						<div className="grid grid-cols-1 gap-4 lg:col-span-2">
							<section aria-labelledby="section-1-title">
								<h2 className="sr-only" id="section-1-title">
									Section title
								</h2>
								<div className="rounded-2xl bg-white overflow-hidden shadow">
									<div className="p-6">{/*<!-- Your content -->*/}</div>
								</div>
							</section>
						</div>

						{/*<!-- Right column -->*/}
						<div className="grid grid-cols-1 gap-4">
							<section aria-labelledby="section-2-title">
								<h2 className="sr-only" id="section-2-title">
									Section title
								</h2>
								<div className="rounded-2xl bg-white overflow-hidden shadow">
									<div className="p-6">{/*<!-- Your content -->*/}</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default TsetComponent;

// class TsetComponent extends React.Component {
// 	state = { isLogout: false };
// 	constructor(props) {
// 		super(props);
// 	}

// 	componentDidMount() {}

// 	render() {
// 		return (
// 			<>
// 				<main className="-mt-24 pb-8">
// 					<div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
// 						<h1 className="sr-only">Page title</h1>
// 						{/*<!-- Main 3 column grid -->*/}
// 						<div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
// 							{/*<!-- Left column -->*/}
// 							<div className="grid grid-cols-1 gap-4 lg:col-span-2">
// 								<section aria-labelledby="section-1-title">
// 									<h2 className="sr-only" id="section-1-title">
// 										Section title
// 									</h2>
// 									<div className="rounded-2xl bg-white overflow-hidden shadow">
// 										<div className="p-6">{/*<!-- Your content -->*/}</div>
// 									</div>
// 								</section>
// 							</div>

// 							{/*<!-- Right column -->*/}
// 							<div className="grid grid-cols-1 gap-4">
// 								<section aria-labelledby="section-2-title">
// 									<h2 className="sr-only" id="section-2-title">
// 										Section title
// 									</h2>
// 									<div className="rounded-2xl bg-white overflow-hidden shadow">
// 										<div className="p-6">{/*<!-- Your content -->*/}</div>
// 									</div>
// 								</section>
// 							</div>
// 						</div>
// 					</div>
// 				</main>
// 			</>
// 		);
// 	}
// }

// export default connect()(TsetComponent);
