import React, { useState, useEffect } from "react";
import TestPoint from "../../testpoint/testpoint";
import { getUserData } from "../../../utils/common/functions";
// import { user } from "../../../utils/models/model";

const TestPointPage = () => {
	const [user, setUser] = useState(null);

	useEffect(() => {
		let usrData = getUserData();
		if (usrData) {
			setUser(usrData);
		}
	}, []);

	return (
		<div className="px-20">
			{user?.roleId === 1 ? (
				<TestPoint />
			) : (
				<h2 className="text-center mt-5">
					You do not have access to view this page
				</h2>
			)}
		</div>
	);
};

export default TestPointPage;

// class TestPointComponent extends React.Component {
// 	state = {
// 		user: user,
// 	};
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			user: user,
// 		};
// 	}

// 	componentDidMount() {
// 		let usrData = getUserData();

// 		if (this.props.usersData?.usersResult.length > 0) {
// 			let usr = this.props.usersData?.usersResult.find(
// 				(item) => item.id === usrData.id
// 			);
// 			if (usr) this.setState({ user: usr });
// 		}
// 	}

// 	componentDidUpdate(prevProps, prevState) {
// 		let usrData = getUserData();

// 		if (
// 			prevProps.usersData?.usersResult !== this.props.usersData?.usersResult
// 		) {
// 			if (this.props.usersData?.usersResult.length > 0) {
// 				let usr = this.props.usersData?.usersResult.find(
// 					(item) => item.id === usrData.id
// 				);
// 				if (usr) this.setState({ user: usr });
// 			}
// 		}
// 	}

// 	render() {
// 		console.log("testpoint page props", this.props);
// 		const {} = this.state;
// 		return (
// 			<div className="px-20">
// 				{this.state.user?.roleId === 1 ? (
// 					<TestPoint />
// 				) : (
// 					<h2 className="text-center mt-5">
// 						You do not have access to view this page
// 					</h2>
// 				)}
// 			</div>
// 		);
// 	}
// }

// const mapStateToProps = (state) => {
// 	const { usersData } = state;
// 	return {
// 		usersData,
// 	};
// };

// export default connect(mapStateToProps)(TestPointComponent);
