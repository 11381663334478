import * as React from "react";
import Login from "../../account/login";
import "./login.css";

const LoginComponent = () => {
	return <Login />;
};

export default LoginComponent;

// class LoginComponent extends React.Component {
// 	constructor(props) {
// 		super(props);
// 	}

// 	render() {
// 		return <Login />;
// 	}
// }

// export default connect()(LoginComponent);
