import React, { useState, useEffect } from "react";
import ReactTable from "react-table-6";
import testApi from "../../../utils/api/testApi";
import { getUserData } from "../../../utils/common/functions";
import { DownloadIcon } from "../../../utils/common/icon-hooks.js";
import { showSweetAlert } from "../../Shared/CustomAlert";

const ArchivePage = () => {
	const [tableData, setTableData] = useState([]);
	const [searchText, setSearchText] = useState(String);
	const [originalArchives, setOriginalArchives] = useState([]);

	// fetch the archives
	useEffect(() => {
		getArchives();
	}, []);

	const downloadArchive = async ({ event, value }) => {
		event.preventDefault();
		const userData = getUserData();

		const response = await testApi.DownloadArchive({
			userId: userData.id,
			userToken: userData.userToken,
			archiveId: value._original.id,
		});

		if (response.status !== 200) {
			showSweetAlert("errorCustomMessage", `${response.data}`);
			return;
		}

		const base64Data = response.data;

		// Decode base64 to binary data
		const binaryString = atob(base64Data);
		const binaryLength = binaryString.length;
		const bytes = new Uint8Array(binaryLength);

		for (let i = 0; i < binaryLength; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}

		// Create a Blob from the byte array
		const blob = new Blob([bytes], { type: "application/zip" });

		// Create a downloadable URL and trigger download
		const downloadableUrl = window.URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = downloadableUrl;
		link.download = value._original.fileName;
		link.style.display = "none";

		document.body.appendChild(link);
		link.click();

		// Clean up
		document.body.removeChild(link);
		window.URL.revokeObjectURL(downloadableUrl);
	};

	const getArchives = async () => {
		const userData = getUserData();

		const response = await testApi.GetAllArchives({
			userId: userData.id,
			userToken: userData.userToken,
		});

		setOriginalArchives(response.data);
		setTableData(response.data);
	};

	// commenting for now - we might need search in future
	const handleArchiveSearch = (txtValue) => {
		setSearchText(txtValue);
		if (txtValue === "") {
			setTableData(originalArchives);
		} else {
			// filter the archives based on the search term
			const filterResult = originalArchives.filter(function (item) {
				return item.name.toLowerCase().indexOf(txtValue.toLowerCase()) >= 0;
			});
			setTableData(filterResult);
		}
	};

	const columns = [
		{
			Header: <div style={{ textAlign: "left" }}>Date Requested</div>,
			accessor: "dateCreated",
			Cell: ({ value }) => new Date(value).toLocaleDateString(),
		},
		{
			Header: <div style={{ textAlign: "left" }}>Expiry Date</div>,
			Cell: ({ row }) => {
				// expiry is 30 days from the start date
				const created = new Date(row._original.dateCreated);
				const expiry = created.setDate(created.getDate() + 30);
				return new Date(expiry).toLocaleDateString();
			},
		},
		{
			Header: <div style={{ textAlign: "left" }}>Days until Expiry</div>,
			Cell: ({ row }) => {
				const created = new Date(row._original.dateCreated);
				const expiry = created.setDate(created.getDate() + 30);
				const today = new Date();
				const daysUntilExpiry = Math.ceil(
					(expiry - today) / (1000 * 60 * 60 * 24),
				);
				return daysUntilExpiry > 0 ? `${daysUntilExpiry} days left` : "Expired";
			},
		},
		// add the file name
		{
			Header: <div style={{ textAlign: "left" }}>File Name</div>,
			accessor: "fileName",
			Cell: ({ value }) => {
				// remove the .zip from the end of the filename
				return value.replace(".zip", "");
			},
		},
		{
			Header: <div style={{ textAlign: "center" }}>Download</div>,
			accessor: "name",
			Cell: ({ row }) => (
				<div
					style={{
						textAlign: "center",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<a
						href="#"
						onClick={(event) =>
							downloadArchive({
								event,
								value: row,
							})
						}
					>
						<DownloadIcon />
					</a>
				</div>
			),
		},
	];

	return (
		<div className="w-full h-full mt-10 px-4">
			<div>
				<h1 className="text-1xl text-gray-500 text-center">Archives</h1>
			</div>

			{/*
			<div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-2 lg:gap-8 w-full py-4">
				<FCInputSearchField
					className=""
					placeholder="Search"
					id="search"
					value={searchText}
					onChange={(event) => handleArchiveSearch(event)}
				/>
			</div>
			*/}

			<div className="mt-5">
				<ReactTable
					data={tableData}
					columns={columns}
					showPagination={false}
					resizable={false}
					noDataText={
						<h2 className="text-center align-middle">No archives found</h2>
					}
					style={{ height: "200px" }}
					className="mt-9 -highlight border-0 mb-1 align-left"
					pageSize={tableData.length > 0 ? tableData.length : 0}
				/>
			</div>
		</div>
	);
};

export default ArchivePage;
