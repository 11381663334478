import React, { useState, useEffect } from "react";
import Users from "../../users/users";
import UserDetails from "../../users/userdetails";
import FCMessageDialog from "../../../utils/library/dialog";
import { getUserData } from "../../../utils/common/functions";
import { useParams } from "react-router";

const UsersPage = () => {
	const [user, setUser] = useState();
	const [showAlert, setShowAlert] = useState(false);
	const [isSuccessAlert, setIsSuccessAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	// get the params from the url
	const { id } = useParams();

	useEffect(() => {
		let usrData = getUserData();
		if (usrData) {
			setUser(usrData);
		}

		// Needs changing ?
		var alertMessage = window.localStorage.getItem("alertMessage");
		if (alertMessage && alertMessage !== "") {
			setShowAlert(true);
			setAlertMessage(alertMessage);
			setIsSuccessAlert(true);
		} else {
		}
		window.localStorage.setItem("alertMessage", "");
	}, []);

	const closeDialog = () => {
		setShowAlert(false);
	};

	return (
		<div className="px-20">
			{user?.roleId === 1 ? (
				<>
					{showAlert && (
						<FCMessageDialog
							isSuccess={isSuccessAlert}
							modalCallBack={() => closeDialog()}
							message={alertMessage}
						/>
					)}
					{id >= 0 && <UserDetails userId={id} seeAlert={showAlert} />}
					{(id < 0 || id === undefined) && <Users seeAlert={showAlert} />}
				</>
			) : (
				<h2 className="text-center mt-5">
					You do not have access to view this page
				</h2>
			)}
		</div>
	);
};

export default UsersPage;

// class UsersComponent extends React.Component {
// 	state = {
// 		id: 0,
// 		showAlert: false,
// 		isSuccessAlert: false,
// 		alertMessage: "",
// 		showConfirm: false,
// 		title: "",
// 		user: user,
// 	};

// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			id: this.props.match.params.id,
// 			showAlert: false,
// 			isSuccessAlert: false,
// 			alertMessage: "",
// 			showConfirm: false,
// 			title: "",
// 			user: user,
// 		};
// 		this.showAlert = this.showAlert.bind(this);
// 	}

// 	showAlert(isShow, isSuccess, message) {
// 		this.setState({
// 			showAlert: isShow,
// 			isSuccessAlert: isSuccess,
// 			alertMessage: message,
// 		});
// 	}

// 	closeDialog = () => {
// 		this.setState({ showAlert: false });
// 	};

// 	componentWillMount() {
// 		this.setState({
// 			id: this.props.match.params ? this.props.match.params.id : -1,
// 		});
// 	}

// 	componentDidUpdate(previousProps, previousState) {
// 		if (previousProps.match.params !== this.props.match.params) {
// 			this.setState({
// 				id: this.props.match.params ? this.props.match.params.id : -1,
// 			});
// 		}

// 		let usrData = getUserData();

// 		if (
// 			previousProps.usersData?.usersResult !== this.props.usersData?.usersResult
// 		) {
// 			if (this.props.usersData?.usersResult.length > 0) {
// 				let usr = this.props.usersData?.usersResult.find(
// 					(item) => item.id === usrData.id
// 				);
// 				if (usr) this.setState({ user: usr });
// 			}
// 		}
// 	}

// componentDidMount() {
// 	var alertMessage = window.localStorage.getItem("alertMessage");
// 	if (alertMessage && alertMessage != "") {
// 		this.setState({
// 			showAlert: true,
// 			alertMessage: alertMessage,
// 			isSuccessAlert: true,
// 		});
// 	} else {
// 	}
// 	window.localStorage.setItem("alertMessage", "");

// 	let usrData = getUserData();

// 	if (this.props.usersData?.usersResult.length > 0) {
// 		let usr = this.props.usersData?.usersResult.find(
// 			(item) => item.id === usrData.id
// 		);
// 		if (usr) this.setState({ user: usr });
// 	}
// }

// 	render() {
// 		const { id, showAlert, isSuccessAlert, alertMessage } = this.state;
// 		return (
// 			<div className="px-20">
// 				{this.state.user?.roleId === 1 ? (
// 					<>
// 						{showAlert && (
// 							<FCMessageDialog
// 								isSuccess={isSuccessAlert}
// 								modalCallBack={() => this.closeDialog()}
// 								message={alertMessage}
// 							/>
// 						)}
// 						{id >= 0 && (
// 							<UserDetails userId={this.state.id} seeAlert={this.showAlert} />
// 						)}
// 						{(id < 0 || id === undefined) && (
// 							<Users seeAlert={this.showAlert} />
// 						)}
// 					</>
// 				) : (
// 					<h2 className="text-center mt-5">
// 						You do not have access to view this page
// 					</h2>
// 				)}
// 			</div>
// 		);
// 	}
// }

// const mapStateToProps = (state) => {
// 	const { usersData } = state;
// 	return {
// 		usersData,
// 	};
// };

// export default connect(mapStateToProps)(UsersComponent);
