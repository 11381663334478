import { Http } from "../Http";
import { AxiosPromise } from "axios";
import apiCall from "../api/apiCall";
import moment from "moment";

const dashboardApi = {
	getAllReport: async (input) => {
		const obj = JSON.stringify(input);
		const url = "Reporting/GetAllReports";
		const response = await apiCall(url, "get");
		return response;
	},

	getGlobalComparisonData: async (input) => {
		const obj = JSON.stringify(input);
		const url = "Reporting/GetGlobalComparison";
		const response = await apiCall(url, "get");
		return response;
	},

	getDashboardData: async (input) => {
		const userId = input.userId;
		const userToken = input.userToken;
		const siteId = input.siteId;
		// const dateFrom = input.dateTo.toISOString();
		// const dateTo = input.dateFrom.toISOString();
		const samplePlanId = input.samplePlanId ?? 0;
		const userIdFilter = input.userIdFilter ?? 0;

		// const dateFrom = "2020-12-31T23:59:59.000Z";
		// const dateTo = "2022-07-31T23:00:00.000Z";
		const dateFrom = new Date(input.dateFrom).toISOString();
		const dateTo = new Date(input.dateTo).toISOString();

		const url =
			"Reporting/GetDashboardData" +
			"?userId=" +
			userId +
			"&userToken=" +
			userToken +
			"&siteId=" +
			siteId +
			"&dateFrom=" +
			dateFrom +
			"&dateTo=" +
			dateTo +
			"&samplePlanId=" +
			samplePlanId +
			"&userIdFilter=" +
			userIdFilter;
		const response = await apiCall(url, "get");
		return response;
	},

	getDownloadReport: async (input) => {
		const userId = input.userId;
		const userToken = input.userToken;
		const siteId = input.siteId;
		// const dateFrom = input.dateTo.toISOString();
		// const dateTo = input.dateFrom.toISOString();
		const samplePlanId = input.samplePlanId ?? 0;
		const userIdFilter = input.userIdFilter ?? 0;

		//const dateFrom = "2020-12-31T23:59:59.000Z";
		//const dateTo = "2022-07-31T23:00:00.000Z";
		const dateFrom = new Date(input.dateFrom).toISOString();
		const dateTo = new Date(input.dateTo).toISOString();

		const url =
			"Reporting/DownloadReport" +
			"?userId=" +
			userId +
			"&userToken=" +
			userToken +
			"&siteId=" +
			siteId +
			"&dateFrom=" +
			dateFrom +
			"&dateTo=" +
			dateTo +
			"&samplePlanId=" +
			samplePlanId +
			"&userIdFilter=" +
			userIdFilter;
		const response = await apiCall(url, "post");
		return response;
	},

	getDownloadData: async (input) => {
		const userId = input.userId;
		const userToken = input.userToken;
		const siteId = input.siteId;
		// const dateFrom = input.dateTo.toISOString();
		// const dateTo = input.dateFrom.toISOString();
		const samplePlanId = input.samplePlanId ?? 0;
		const userIdFilter = input.userIdFilter ?? 0;

		//const dateFrom = "2020-12-31T23:59:59.000Z";
		//const dateTo = "2022-07-31T23:00:00.000Z";
		const dateFrom = new Date(input.dateFrom).toISOString();
		const dateTo = new Date(input.dateTo).toISOString();

		const url =
			"Reporting/DownloadData" +
			"?userId=" +
			userId +
			"&userToken=" +
			userToken +
			"&siteId=" +
			siteId +
			"&dateFrom=" +
			dateFrom +
			"&dateTo=" +
			dateTo +
			"&samplePlanId=" +
			samplePlanId +
			"&userIdFilter=" +
			userIdFilter;
		const response = await apiCall(url, "post");
		return response;
	},

	getTestHistoryData: async (input) => {
		const obj = JSON.stringify(input);
		const url = "Reporting/GetTestHistory";
		const response = await apiCall(url, "get");
		return response;
	},
};

export default dashboardApi;

// export class DashboardAPI {

// 	public static getAllReport(input: any) {
// 		var obj = JSON.stringify(input);
// 		return Http.axios().post('/Reporting/GetAllReports', obj, { headers: { 'Content-Type': 'application/json' } })
// 			.then(response => {
// 				return response;
// 			}).catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}

// 	public static getGlobalComparisonData(input: any) {
// 		var obj = JSON.stringify(input);
// 		return Http.axios().post('/Reporting/GetGlobalComparison', obj, { headers: { 'Content-Type': 'application/json' } })
// 			.then(response => {
// 				return response;
// 			}).catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}

// 	public static getDashboardData(input: any) {
// 		var obj = JSON.stringify(input);
// 		return Http.axios().post('/Reporting/GetDashboardData', obj, { headers: { 'Content-Type': 'application/json' } })
// 			.then(response => {
// 				return response;
// 			}).catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}

// 	public static getDownloadReport(input: any) {
// 		var obj = JSON.stringify(input);
// 		return Http.axios().post('/Reporting/DownloadReport', obj, { headers: { 'Content-Type': 'application/json' } })
// 			.then(response => {
// 				return response;
// 			}).catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}

// 	public static getDownloadData(input: any) {
// 		var obj = JSON.stringify(input);
// 		return Http.axios().post('/Reporting/DownloadData', obj, { headers: { 'Content-Type': 'application/json' } })
// 			.then(response => {
// 				return response;
// 			}).catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}

// 	public static getTestHistoryData(input: any) {
// 		var obj = JSON.stringify(input);
// 		return Http.axios().post('/Reporting/GetTestHistory', obj, { headers: { 'Content-Type': 'application/json' } })
// 			.then(response => {
// 				return response;
// 			}).catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}
// }
