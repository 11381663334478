import * as React from "react";
import ResetPwd from "../../account/resetpwd";
import "./login.css";

const ResetPwdComponent = () => {
	return (
		<div className="">
			<ResetPwd />
		</div>
	);
};

export default ResetPwdComponent;

// class ResetPwdComponent extends React.Component {
// 	state = {
// 		email: "",
// 		token: "",
// 	};
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			email: props.match.params.email,
// 			token: props.match.params.token,
// 		};
// 	}

// 	render() {
// 		return <ResetPwd token={this.state.token} email={this.state.email} />;
// 	}
// }

// export default connect()(ResetPwdComponent);
