import * as React from "react";
import { useState } from "react";
import FCProfileInputField from "../../utils/library/profiletextbox";
import FCButtonWithClick from "../../utils/library/buttonwithclick";
import { Validators, validateInput } from "../../utils/library/Validator";
import "./userprofile.css";
import {
	getUserData,
	getBase64,
	addDefaultSrc,
	setUserAvator,
} from "../../utils/common/functions";
import userApi from "../../utils/api/userApi";

const UserProfile = ({
	setShowChangePwd,
	userProfileData,
	setUserProfileData,
	selectedUser,
	setSelectedUser,
}) => {
	const userProfile = {
		deleted: false,
		clientId: 0,
		userId: 0,
		userToken: "",
		userName: "",
		firstName: "",
		lastName: "",
		email: "",
		currentPassword: "",
		confirmPassword: "",
		newPassword: "",
		imageUrl: "",
	};

	const validators = [
		{
			check: Validators.required,
			message: "Company name is required.",
		},
	];

	const [fileSelector, setFileSelector] = useState(null);
	const [base64, setBase64] = useState("");
	// const [selectedUser, setSelectedUser] = useState(userProfile);
	const [isUserLoaded, setIsUserLoaded] = useState(false);
	const [isUserSaved, setIsUserSaved] = useState(false);
	const [isAvatarUpdated, setIsAvatarUpdated] = useState(false);
	const [isUserNameEntered, setIsUserNameEntered] = useState(false);
	const [isFirstNameEntered, setIsFirstNameEntered] = useState(false);
	const [isLastNameEntered, setIsLastNameEntered] = useState(false);
	const [isEmailEntered, setIsEmailEntered] = useState(false);
	const [isRequiredError, setIsRequiredError] = useState(false);
	const [userData, setUserData] = useState(null);
	// const [userProfileData, setUserProfileData] = useState(initialState);

	const [error, setError] = useState(false);

	const buildFileSelector = () => {
		const fileSelector = document.createElement("input");
		fileSelector.setAttribute("type", "file");
		return fileSelector;
	};

	React.useEffect(() => {
		setFileSelector(buildFileSelector);
		var usrData = getUserData();
		setUserData(usrData);
		// dispatch(
		// 	getUserProfile({ userId: usrData.id, userToken: usrData.userToken })
		// );
		getUser({
			userId: usrData.id,
			userToken: usrData.userToken,
			id: usrData.id,
		});
	}, []);

	const getUser = async (input) => {
		const response = await userApi.getSingleUserWithImg({
			userId: input.id,
			userToken: input.userToken,
			id: input.id,
		});
		if (response.status === 200) {
			setUserProfileData({
				...userProfile,
				userResult: response.data,
			});
			setSelectedUser(response.data);
		}

		if (response.status !== 200) {
			setError(response.message);
		}
	};

	const updateUserProfile = async (input) => {
		const response = await userApi.addOrUpdateUser(input);
		return response;
	};

	const [index, setIndex] = React.useState(0);

	React.useEffect(() => {
		setIndex(index + 1);
		let userData = userProfileData.userResult;
		if (userProfileData && !error) {
			if (!isUserLoaded) {
				setSelectedUser(userProfileData.userResult);
				setIsUserLoaded(true);
			}
			if (!isUserSaved) {
				// seeAlert(
				// 	true,
				// 	userProfileData.status.statusValue,
				// 	userProfileData.status.statusDisplay
				// );
				setIsUserSaved(true);
			}
			if (!isAvatarUpdated) {
				setFileSelector(buildFileSelector);
				// seeAlert(
				// 	true,
				// 	userProfileData.status.statusValue,
				// 	userProfileData.status.statusDisplay
				// );
				setIsAvatarUpdated(true);
			}
		}
	}, [userProfileData]);

	const saveProfile = () => {
		// seeAlert(false, false, "");
		setIsFirstNameEntered(validateInput(validators, selectedUser.firstName));
		setIsLastNameEntered(validateInput(validators, selectedUser.lastName));
		setIsUserNameEntered(validateInput(validators, selectedUser.username));
		setIsEmailEntered(validateInput(validators, selectedUser.email));

		if (validateNullOrUndefined()) {
			setIsRequiredError(false);
			selectedUser.userId = userData.id;
			// dispatch(updateUserProfile(selectedUser));
			updateUserProfile(selectedUser);
		} else {
			setIsRequiredError(true);
		}
		setIsUserSaved(false);
	};

	// const onCancel = () => {
	// 	window.location.href = "./";
	// };

	const onCancel = () => {
		setShowChangePwd(false);
	};

	const onInputChange = (event) => {
		if (event.name === "username") {
			setIsUserNameEntered(validateInput(validators, event.value));
		}
		if (event.name === "firstName") {
			setIsFirstNameEntered(validateInput(validators, event.value));
		}
		if (event.name === "lastLame") {
			setIsLastNameEntered(validateInput(validators, event.value));
		}
		if (event.name === "email") {
			setIsEmailEntered(validateInput(validators, event.value));
		}
		setSelectedUser((prevState) => ({
			...prevState,
			[event.name]: event.value,
		}));
	};

	const validateNullOrUndefined = () => {
		if (
			typeof selectedUser.firstName !== "undefined" &&
			selectedUser.firstName &&
			typeof selectedUser.lastName !== "undefined" &&
			selectedUser.lastName &&
			typeof selectedUser.userName !== "undefined" &&
			selectedUser.userName &&
			typeof selectedUser.email !== "undefined" &&
			selectedUser.email
		) {
			return true;
		} else {
			return false;
		}
	};

	const updateUserAvatar = async (input) => {
		const response = await userApi.updateUserAvatar(input);
		return response;
	};

	const handleFileSelect = async (event) => {
		event.preventDefault();
		// seeAlert(false, false, "");
		fileSelector.click();
		fileSelector.onchange = function (e) {
			if (e.currentTarget.files) {
				getBase64(e.currentTarget.files[0], (result) => {
					setIsAvatarUpdated(false);
					var imagePlainText = result.replace(
						/^data:image\/[a-z]+;base64,/,
						"",
					);
					//dispatch to save..
					// dispatch(
					// 	updateAvatar({
					// 		userId: userData.id,
					// 		userToken: userData.userToken,
					// 		imageUrl: imagePlainText,
					// 	})
					// );
					updateUserAvatar({
						userId: userData.id,
						userToken: userData.userToken,
						imageUrl: imagePlainText,
					});
					setBase64(result);
					var node = document.getElementById("userImg");
					node.setAttribute("src", result);
					setUserAvator(result);
				});
			}
		};
	};

	return (
		<React.Fragment>
			<div className="py-3">
				<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
					<h1 className="text-1xl  text-gray-500 text-center">My Account</h1>
				</div>

				<div className="mt-8 w-1/2 text-lg  text-gray-600 text-center mx-auto">
					<div>
						<img
							className="inline-block object-cover h-16 w-16 rounded-full"
							src={base64 == "" ? selectedUser?.imageUrl : base64}
							alt=""
							onError={addDefaultSrc}
						/>
					</div>
				</div>
				<div className="mt-2 w-1/2 text-lg text-gray-500  text-center underline mx-auto">
					<a href="#" onClick={(event) => handleFileSelect(event)}>
						Change Profile
					</a>
				</div>

				<div className="mt-5 w-full  mb-4 text-gray-500">
					<div className="py-4 w-1/3 overflow-hidden h-1/2 mx-auto">
						<div className="mb-3 mx-auto">
							<FCProfileInputField
								required={true}
								id="username"
								onChange={(event) => onInputChange(event.target)}
								placeholder="Username"
								value={selectedUser?.username}
								isError={isUserNameEntered}
								icon="user"
								isReadOnly={true}
							/>
						</div>
						<div className="mb-3  mx-auto">
							<FCProfileInputField
								required={true}
								id="firstName"
								onChange={(event) => onInputChange(event.target)}
								placeholder="FirstName"
								value={selectedUser?.firstName}
								isError={isFirstNameEntered}
								icon="email"
							/>
						</div>
						<div className="mb-3  mx-auto">
							<FCProfileInputField
								id="lastName"
								onChange={(event) => onInputChange(event.target)}
								placeholder="LastName"
								value={selectedUser?.lastName}
								isError={isLastNameEntered}
								icon="email"
							/>
						</div>
						<div className="mb-3 mx-auto">
							<FCProfileInputField
								required={true}
								id="email"
								onChange={(event) => onInputChange(event.target)}
								placeholder="Email"
								value={selectedUser?.email}
								isError={isEmailEntered}
								icon="email"
							/>
						</div>

						<div className="mt-1  flex mx-auto mb-10 mt-10">
							{/* <a
								href="./userprofile/changepwd"
								className="w-full  text-center text-lg text-gray-500 text-center underline"
							>
								Change Password
							</a> */}
							<p
								onClick={() => {
									setShowChangePwd(true);
								}}
								className="w-full cursor-pointer text-center text-lg text-gray-500 text-center underline"
							>
								Change Password
							</p>
						</div>

						<div className="mt-1 mb-3 flex mx-auto">
							<FCButtonWithClick
								onHandleClick={onCancel}
								bgColor="bg-fcbutton mr-3"
								type="button"
								icon=""
								value="Cancel"
							/>
							<FCButtonWithClick
								onHandleClick={saveProfile}
								bgColor="bg-primary"
								type="button"
								icon=""
								value="Save"
							/>
						</div>
						<div className="text-red-500  text-center">
							{isRequiredError && <span>Please enter the required fields</span>}
							{error && <span>{error}</span>}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default UserProfile;
