import axios from "axios";
import apiConfig from "../../config/apiConfig";
// import Cookies from "js-cookie";

const apiCall = async (url, method = "get", data = null) => {
	// const cookie = JSON.parse(Cookies.get("userData"));
	// const token = cookie?.token;

	const headers = {
		Authorization: `Bearer ZjYxZTRjNjQtNTIzYi00NWI1LWFlNTMtMDg1MWNlZGFkZmMw`,
		"Content-Type": "application/json",
	};

	const axiosConfig = {
		method,
		headers,
	};

	axiosConfig.url = apiConfig.BASE_URL + url;

	if (data) {
		axiosConfig.data = data;
	}

	// const refreshAccessToken = async () => {
	//   const cookie = JSON.parse(Cookies.get("userData"));

	//   let data = {
	//     token: cookie?.token,
	//     refreshToken: cookie?.refreshToken,
	//   };

	//   const refreshedToken = await axios.post(
	//     apiConfig.BASE_URL + apiConfig.AUTHENTICATION.REFRESH_TOKEN_ENDPOINT,
	//     data,
	//   );

	//   Cookies.set("userData", JSON.stringify(refreshedToken?.data));

	//   return refreshedToken;
	// };

	let response;
	try {
		response = await axios(axiosConfig);

		// If the response is successful (status 200), return the data
		return response;
	} catch (error) {
		// If error response is 401 - attempt to refresh the token
		// if (error.response && error.response.status === 401) {
		//   const newToken = await refreshAccessToken();
		//   axiosConfig.headers = {
		//     Authorization: `Bearer ${newToken}`,
		//   };

		//   const retryResponse = await axios(axiosConfig);
		//   return retryResponse;
		// } else {
		// Handle other kinds of errors (e.g., network issues, server errors)

		return error.response;
		// }
	}
};

export default apiCall;
