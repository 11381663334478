import * as React from "react";
import { usePopper } from "react-popper";
import Select, { StylesConfig } from "react-select";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import FCSiteBox from "../../utils/library/sitebox";
import FCCheckBox from "../../utils/library/checkbox";
import FCInputSearchField from "../../utils/library/textboxsearch";
import FCSelectSiteFieldWithNoValue from "../../utils/library/selectsitefieldwithnovalue";
import FCSiteInputField from "../../utils/library/sitetextbox";
import { Validators, validateInput } from "../../utils/library/Validator";
import FCButtonWithClick from "../../utils/library/buttonwithclick";
import FCMessageDialog from "../../utils/library/dialog";
import FCConfirmAlert from "../../utils/library/confirmalert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../custom.css";
import {
	DotVerticalSvgSvgIcon,
	TrashSvgIcon,
	PlusCircleSvgIconTransparent,
	EditSvgIcon,
	CloseIcon,
} from "../../utils/common/icon-hooks.js";
// import { getSitesByClient, getAllSites } from "../../store/actions/site";
import { getUserData, getBase64 } from "../../utils/common/functions";
// import { getTemplates } from "../../store/actions/samplePlanQuote";
import { CSVLink } from "react-csv";
import moment from "moment";
import * as XLSX from "xlsx";
import { useHistory } from "react-router-dom";
import samplePlanApi from "../../utils/api/sampleplanApi";
import sampleplanQuoteApi from "../../utils/api/sampleplanQuoteApi";
import siteApi from "../../utils/api/siteApi";
import testPointApi from "../../utils/api/testpointApi";
import { showSweetAlert } from "../Shared/CustomAlert";

const SamplePlan = ({
	sampleplanQuoteData,
	setSampleplanQuoteData,
	changepage,
	selectedTemplateId,
	setSelectedTemplateId,
}) => {
	const samplePlanModel = {
		id: 0,
		name: "",
		frequency: "",
		dayOfWeek: "",
		dayOfMonth: 0,
		isLive: false,
		testPoints: [
			{
				id: 0,
				name: "",
				location: "",
				where: "",
			},
		],
		sites: [
			{
				id: 0,
				name: "",
			},
		],
	};

	const initialState = {
		userId: 0,
		usertoken: "",
		isSaved: false,
		isLive: false,
		isError: false,
		isSamplePlansLoaded: false,
		isTestPointsLoaded: false,
		isPlanSaved: false,
		isPlanDeleted: false,
		// will need to set this
		// samplePlanInput: samplePlanModel,
		samplePlanInput: null,
		// will need to set this
		// samplePlans: [initialSamplePlanModel],
		samplePlans: [],
		// will need to set this
		// testPoints: [testpoint],
		testPoints: null,
		selectedSamplePlanId: 0,
		selectedSamplePlan: null,
	};

	const initialSite = {
		deleted: false,
		id: 0,
		clientId: 0,
		userId: 0,
		userToken: "",
		name: "",
		addressLine1: "",
		addressLine2: "",
		town: "",
		city: "",
		county: "",
		postcode: "",
		country: "GB",
		selected: false,
	};

	const initialSiteState = {
		userId: 0,
		usertoken: "1234",
		isError: false,
		isSaved: false,
		isSiteLoaded: false,
		isSitesLoaded: false,
		isAllSitesLoaded: false,
		isSiteSaved: false,
		isSiteDeleted: false,
		sites: [initialSite],
		site: initialSite,
	};

	const validators = [
		{
			check: Validators.required,
			message: "All fields are required.",
		},
	];

	//For Popover
	const history = useHistory();
	const [popVisible, setPopVisibility] = useState(false);
	const [referenceRef, setReferenceRef] = useState(null);
	const [popperRef, setPopperRef] = useState(null);
	const { styles, attributes } = usePopper(referenceRef, popperRef, {
		placement: "left-start",
		modifiers: [
			{
				name: "offset",
				enabled: true,
				options: {
					offset: [0, 10],
				},
			},
		],
	});
	const handlePopupClick = (event) => {
		setPopVisibility(!popVisible);
	};
	let selectRefDay = null;
	let selectRefWeeks = null;
	const frequencyOptions = [
		{ label: "Weekly", value: 1 },
		{ label: "Fortnightly", value: 2 },
		{ label: "Every 4 Weeks", value: 3 },
		{ label: "Monthly", value: 4 },
	];
	const weekDays = [
		{ label: "Mon", value: 1 },
		{ label: "Tue", value: 2 },
		{ label: "Wed", value: 3 },
		{ label: "Thu", value: 4 },
		{ label: "Fri", value: 5 },
		{ label: "Sat", value: 6 },
		{ label: "Sun", value: 7 },
	];

	const cancelButtonRef = useRef(null);
	const Styles = {
		option: (provided) => ({
			...provided,
			color: "#002366",
		}),
		control: (provided, state) => ({
			...provided,
			boxShadow: "none",
			border: "none",
			backgroundColor: "none",
		}),
		singleValue: (provided) => ({
			...provided,
			color: "#002366",
		}),
		placeholder: (base) => ({
			...base,
			fontSize: "1em",
			color: "gray",
			fontWeight: 400,
		}),
	};

	const [isShowConfirmAlert, setIsShowConfirmAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	const [isNameEntered, setIsNameEntered] = useState(false);
	const [isSamplePlanSaved, setIsSamplePlanSaved] = useState(false);
	const [isRequiredError, setIsRequiredError] = useState(false);
	const [isSamplePlanDeleted, setIsSamplePlanDeleted] = useState(false);
	const [templatesAdded, setTemplatesAdded] = useState(false);
	// const [selectedTemplateId, setSelectedTemplateId] = useState(-1);
	const [templateList, setTemplateList] = useState([{ label: "", value: 0 }]);
	const [templateDialog, setTemplateDialog] = useState(false);
	const [selectedSiteId, setSelectedSiteId] = useState(0);
	const [selectedSite, setSelectedSite] = useState({ label: "", value: 0 });
	const [isSitesLoaded, setIsSitesLoaded] = useState(false);
	const [isAllSitesLoaded, setIsAllSitesLoaded] = useState(false);
	const [siteOptions, setSiteOptions] = useState([]);
	const [samplePlans, setSamplePlans] = useState([samplePlanModel]);

	const [isImportColumnsSelected, setIsImportColumnsSelected] = useState(true);
	const [isImportFileSelected, setIsImportFileSelected] = useState(true);
	const [selectedImportFile, setSelectedImportFile] = useState(null);
	const [isImportPlanSaved, setIsImportPlanSaved] = useState(true);

	const [days, setDays] = useState([{ label: "", value: "-1" }]);
	const [selectedDay, setSelectedDay] = useState({ label: "", value: "-1" });
	const [selectedWeekDays, setSelectedWeekDays] = useState([0]);
	const [selectedWeekDaysOption, setSelectedWeekDaysOption] = useState([
		{ label: "", value: 0 },
	]);
	const [frequency, setFrequency] = useState({ label: "Weekly", value: 1 });
	const [isDayOrWeekDropDown, setIsDayOrWeekDropDown] = useState(false);
	const [isLive, setIsLive] = useState(false);
	const [addedTestPoints, setAddedTestPoints] = useState([]);
	const [addedSites, setAddedSites] = useState([]);
	const [allSites, setAllSites] = useState([]);
	const [selectedPlanId, setSelectedPlanId] = useState(0);
	const [isSamplePlansLoaded, setIsSamplePlansLoaded] = useState(false);
	const [isTestPointsLoaded, setIsTestPointsLoaded] = useState(false);
	const [selectedPlan, setSelectedPlan] = useState(samplePlanModel);
	const [showAlert, setShowAlert] = useState(false);
	const [addMode, setAddMode] = useState(false);
	const [viewMode, setViewMode] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [isSuccessAlert, setIsSuccessAlert] = useState(false);

	const [userData, setUserData] = useState(null);
	const [exportCSVData, setExportCSVData] = useState([]);
	const [exportCSVFileName, setExportCSVFileName] = useState(
		"_sample_plans_" + moment().format("YYYY-MM-DD") + ".csv",
	);
	const [csvMappingcolumns, setCSVMappingColumns] = useState([]);
	const [importedData, setImportedData] = useState([]);
	const [columnExist, setColumnExist] = useState(false);
	const [importMappingDialog, setImportMappingDialog] = useState(false);
	const [importedCSVBase64, setImportedCSVBase64] = useState("");
	const [isImportError, setIsImportError] = useState(false);

	const [sampleplanData, setSampleplanData] = useState(initialState);
	const [siteData, setSiteData] = useState(initialSiteState);

	// const [searchTerm, setSearchTerm] = useState("");
	const [testPointSearchTerm, setTestPointSearchTerm] = useState("");
	const [siteSearchTerm, setSiteSearchTerm] = useState("");
	const [originalTestPoints, setOriginalTestPoints] = useState([]);
	const [originalSites, setOriginalSites] = useState([]);

	const handleSearch = (txtValue, type) => {
		if (type === "testPoint") {
			setTestPointSearchTerm(txtValue);
		}
		if (type === "site") {
			setSiteSearchTerm(txtValue);
		}

		if (type === "testPoint") {
			if (txtValue.trim() === "") {
				// If search term is empty, show all original test points
				setTestPoints(originalTestPoints);
			} else {
				// Filter the test points based on the search term
				const filterResult = originalTestPoints.filter((item) =>
					item.name.toLowerCase().includes(txtValue.toLowerCase()),
				);
				setTestPoints(filterResult);
			}
		}

		if (type === "site") {
			if (txtValue.trim() === "") {
				// If search term is empty, show all original test points
				setAllSites(originalSites);
			} else {
				// Filter the test points based on the search term
				const filterResult = originalSites.filter((item) =>
					item.name.toLowerCase().includes(txtValue.toLowerCase()),
				);
				setAllSites(filterResult);
			}
		}
	};

	React.useEffect(() => {
		var selFreq = frequencyOptions.find(
			(obj) =>
				obj.label === selectedPlan?.frequency ||
				obj.value === parseInt(selectedPlan?.frequency),
		);
		selFreq && setFrequency({ label: selFreq.label, value: selFreq.value });
		setIsLive(selectedPlan?.isLive);
		setSelectedPlanId(selectedPlan?.id);
	}, [selectedPlan]);

	React.useEffect(() => {
		if (siteData.sites && siteData.sites.length > 0) {
			getSiteDropdownOptions(siteData);
		}
	}, [siteData]);

	const getAllSites = async (input) => {
		const response = await siteApi.getAllSites({
			userId: input.userId,
			userToken: input.userToken,
			clientId: input.clientId,
		});
		setSiteData({ ...siteData, sites: response.data });
		setAllSites(response.data);
		setOriginalSites(response.data);
		return response;
	};

	const [testPoints, setTestPoints] = useState([]);
	const getAllTestPoints = async (input) => {
		const response = await testPointApi.getAllTestPoint({
			userId: input.userId,
			userToken: input.userToken,
		});
		const testPointData = response.data;
		setSampleplanData({ ...sampleplanData, testPoints: testPointData });
		setTestPoints(testPointData);
		setOriginalTestPoints(testPointData);
	};

	const getTemplates = async (input) => {
		const response = await sampleplanQuoteApi.getTemplateList({
			userId: input.userId,
			userToken: input.userToken,
			templateId: input.templateId,
		});
		// format the templates for the dropdown select
		const templateList = response.data.map((item) => {
			return {
				label: item.name,
				value: item.id,
			};
		});
		setTemplateList(templateList);
		// return response;
	};

	const deleteSamplePlan = async (input) => {
		const response = await samplePlanApi.deleteSamplePlan({
			// this id is the selected plan id
			id: input.id,
			userId: input.userId,
			userToken: input.userToken,
		});
		return response;
	};

	const saveSamplePlan = async (input) => {
		const response = await samplePlanApi.saveSamplePlan(input);
		return response;
	};

	const getSamplePlans = async (input) => {
		const response = await samplePlanApi.getSamplePlans({
			userId: input.userId,
			userToken: input.userToken,
		});
		setSamplePlans(response.data);
		setSampleplanData({ ...sampleplanData, samplePlans: response.data });
	};

	const onSiteChange = (event) => {
		if (event === null) {
			setSamplePlans(sampleplanData.samplePlans);
			return;
		}
		setSelectedSiteId(event.value);
		setSelectedSite(event);
		setViewMode(true);
		setAddMode(false);
		setEditMode(false);
		if (sampleplanData.samplePlans && sampleplanData.samplePlans.length > 0) {
			var filterResult = sampleplanData.samplePlans.filter(function (item) {
				return item.sites.find((val) => val.id === event.value);
			});
			setSamplePlans(filterResult);
		}
	};

	const selectSamplePlan = (input) => {
		return {
			type: "SELECT_SAMPLEPLAN",
			input: input,
		};
	};

	const onSamplePlanChange = (event, plan) => {
		event.preventDefault();
		if (typeof plan === "undefined") {
			return;
		}
		setAddedSites([]);
		setAddedTestPoints([]);
		event.preventDefault();
		setSelectedPlanId(plan?.id || 0);
		setSelectedPlan(plan);
		// dispatch(selectSamplePlan(plan));
		selectSamplePlan(plan);

		setViewMode(true);
		setAddMode(false);
		setEditMode(false);
		setIsLive(plan?.isLive);
		var selFreq = frequencyOptions.find(
			(obj) =>
				obj.label === plan?.frequency ||
				obj.value === parseInt(selectedPlan?.frequency),
		);
		selFreq && setFrequency({ label: selFreq.label, value: selFreq.value });
		if (plan?.frequency === "4") {
			setIsDayOrWeekDropDown(false);
			setSelectedDay({ label: plan?.dayOfMonth, value: plan?.dayOfMonth });
			setSelectedWeekDaysOption([]);
			setSelectedWeekDays([0]);
		} else {
			setIsDayOrWeekDropDown(true);
			//setSelectedWeekDays(plan.dayOfWeek);
			var opt = [];
			var values = [];
			if (plan) {
				plan.dayOfWeek.split(",").forEach((item) => {
					var result = weekDays.find((o) => o.value === parseInt(item));
					if (result) {
						opt.push({ label: result.label, value: result.value });
						values.push(result.value);
					}
				});
			}
			setSelectedWeekDaysOption(opt);

			var items = new Array(0);
			opt.forEach(function (item) {
				items.push(item.value);
			});
			setSelectedWeekDays(items);
		}
		//set the selected Test points
		var selectedPoints = [];
		if (plan) {
			plan.testPoints &&
				plan.testPoints.forEach((item) => {
					selectedPoints.push(item.id);
				});
		}
		setAddedTestPoints(selectedPoints);
		//set the selected sites
		var selectedSites = [];
		if (plan) {
			plan.sites &&
				plan.sites.forEach((item) => {
					selectedSites.push(item.id);
				});
		}
		setAddedSites(selectedSites);
	};

	const onInputChange = (event) => {
		if (event.name === "name") {
			setIsNameEntered(validateInput(validators, event.value));
		}

		setSelectedPlan((prevState) => ({
			...prevState,
			[event.name]: event.value,
		}));
	};

	const handleAddSamplePlan = (event, isNew) => {
		event && event.preventDefault();
		if (isNew) {
			setTemplateDialog(false);
			setSelectedPlanId(0);
			setSelectedPlan(null);
			selectSamplePlan(null);
			setAddMode(true);
			setEditMode(false);
			//setPopVisibility(!popVisible);
			setAddedTestPoints([]);
			setAddedSites([]);
			setSelectedWeekDaysOption([]);
			setIsLive(false);
			setFrequency({ label: "Weekly", value: 1 });
			setSelectedDay({ label: "1", value: "1" });
		} else {
			setAddMode(false);
			setEditMode(true);
		}
		setSelectedPlan((prevState) => ({
			...prevState,
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
		}));

		setViewMode(false);
		setShowAlert(false);
	};

	const showDeleteConfirmAlert = (event) => {
		event.preventDefault();
		setIsShowConfirmAlert(true);
	};

	const handleDeleteSamplePlan = async (event) => {
		event.preventDefault();
		setShowAlert(false);
		selectSamplePlan(null);
		const userData = getUserData();
		if (selectedPlan) {
			const response = await deleteSamplePlan({
				id: selectedPlan.id,
				userId: userData.id,
				userToken: userData.userToken,
			});
			showSweetAlert("success", "Sample Plan deleted successfully");
			if (response.status === 200) {
				getSamplePlans({
					userId: userData.id,
					userToken: userData.userToken,
				});
			}
		}
		setIsSamplePlanDeleted(false);
		setIsShowConfirmAlert(false);
	};

	const handleConfirmCancel = (event) => {
		setIsShowConfirmAlert(false);
	};

	const handleCancel = (event) => {
		setEditMode(false);
		setAddMode(false);
		setViewMode(true);
		setIsRequiredError(false);
		samplePlans && onSamplePlanChange(event, sampleplanData.selectedSamplePlan);
		setIsNameEntered(false);
	};

	const validateNullOrUndefined = () => {
		if (
			typeof selectedPlan.name !== "undefined" &&
			selectedPlan.name &&
			selectedPlan?.testPoints?.length !== 0 &&
			allSites?.length !== 0
		) {
			return true;
		} else {
			return false;
		}
	};

	const addorUpdateSamplePlan = async (e) => {
		setIsNameEntered(validateInput(validators, selectedPlan.name));
		if (validateNullOrUndefined()) {
			setIsRequiredError(false);
			setIsSamplePlanSaved(false);

			//get selected test points
			var pointIds = [];
			var siteIds = [];
			//testPoints.map((titem) => { titem.selected && pointIds.push(titem.id); })
			addedTestPoints.forEach((id) => {
				pointIds.push(id);
			});
			//vet selected site ids
			//allSites.map((sitem) => { sitem.selected && siteIds.push(sitem.id); });
			addedSites.forEach((id) => {
				siteIds.push(id);
			});
			if (selectedWeekDays.length > 1) {
				selectedWeekDays.sort(function (a, b) {
					return a - b;
				});
			}

			var samplePlanObj = {
				userId: userData.id,
				userToken: userData.userToken,
				id: selectedPlanId,
				name: selectedPlan.name,
				isLive: isLive,
				frequency: frequency.value.toString(),
				dayOfWeek: frequency.value <= 3 ? selectedWeekDays.toString() : "",
				dayOfMonth: frequency.value === 4 ? parseInt(selectedDay.value) : 0,
				testpointIds: pointIds,
				siteIds: siteIds,
			};
			// add or update
			let response;
			if (samplePlanObj.id > 0) {
				// update
				response = await samplePlanApi.updateSamplePlan(samplePlanObj);
			} else {
				// add
				response = await samplePlanApi.saveSamplePlan(samplePlanObj);
			}

			if (response.status === 200) {
				window.location.reload();
			} else {
				showAlert(response.statusText);
			}
			// saveSamplePlan(samplePlanObj);
			// window.location.reload();
		} else {
			setIsRequiredError(true);
		}
		setSelectedWeekDaysOption([]);
		setIsLive(false);
	};

	// not this block
	const GetAllSamplePlans = async (usrData) => {
		setIsSamplePlansLoaded(false);
		// dispatch(
		// 	getAllSamplePlans({ userId: usrData?.id, userToken: usrData?.userToken })
		// );
		// TODO  - check this
		getSamplePlans({
			userId: usrData.id,
			userToken: usrData?.userToken,
		});
	};

	const handleIsLiveChange = () => {
		setIsLive(!isLive);
	};

	const handleFrequencyChange = (event) => {
		if (selectRefDay !== null) {
			selectRefDay.clearValue();
		}
		if (selectRefWeeks !== null) {
			selectRefWeeks.clearValue();
		}
		setFrequency({ label: event.label, value: event.value });
		if (event.value <= 3) {
			setIsDayOrWeekDropDown(true);
		} else {
			setIsDayOrWeekDropDown(false);
		}
	};

	const handleWeekChange = (event) => {
		if (event === null) {
			return;
		}
		//setSelectedDay(-1); //have to set the default value
		if (event.length > 0) {
			var items = new Array(0);
			var options = [];
			items.splice(0, 1);
			event.forEach(function (item) {
				items.push(item.value);
				options.push(item);
			});
			setSelectedWeekDays([]);
			setSelectedWeekDays(items);
			setSelectedWeekDaysOption(options);
		}
		if (event.length === 0) {
			setSelectedWeekDays([]);
		}
	};

	const handleDayChange = (event) => {
		if (event === null) {
			return;
		}
		setSelectedWeekDays([]);
		setSelectedDay({ label: event.value, value: event.value });
	};

	//Test point change event
	const onTestPointChange = (event) => {
		var pointId = event.target.getAttribute("data-id");
		var selectedPoints = [];
		var foundPointId = addedTestPoints.findIndex(
			(val) => val === parseInt(pointId),
		);
		if (foundPointId > -1) {
			addedTestPoints.splice(foundPointId, 1);
		} else {
			addedTestPoints.push(parseInt(pointId));
		}

		addedTestPoints.forEach((item) => {
			selectedPoints.push(parseInt(item));
		});
		setAddedTestPoints(selectedPoints);
	};

	//Site change event
	const onAllSiteChange = (event) => {
		var siteId = event.target.getAttribute("data-id");
		var selectedSites = [];
		var foundSiteId = addedSites.findIndex((val) => val === parseInt(siteId));
		if (foundSiteId > -1) {
			addedSites.splice(foundSiteId, 1);
		} else {
			addedSites.push(parseInt(siteId));
		}

		addedSites.forEach((item) => {
			selectedSites.push(parseInt(item));
		});
		setAddedSites(selectedSites);
	};

	React.useEffect(() => {
		var usrData = getUserData();
		setUserData(usrData);

		getSamplePlans({
			userId: usrData.id,
			userToken: usrData.userToken,
		});

		// get all client sites
		getAllSites({
			userId: usrData.id,
			userToken: usrData.userToken,
			clientId: usrData.clientId,
		});

		getAllSites({
			userId: usrData.id,
			userToken: usrData.userToken,
			clientId: usrData.clientId,
		});

		getAllTestPoints({
			userId: usrData.id,
			userToken: usrData.userToken,
		});

		getTemplates({
			userId: usrData.id,
			userToken: usrData.userToken,
			templateId: selectedTemplateId,
		});

		setViewMode(true);
		setAddMode(false);
		setEditMode(false);
		setShowAlert(false);

		setSelectedPlan((prevState) => ({
			...prevState,
			userId: usrData.id,
			userToken: usrData.userToken,
			clientId: usrData.clientId,
		}));

		//Set the days and weeks,
		days.shift();
		for (var i = 1; i <= 31; i++) {
			days.push({ label: i.toString(), value: i.toString() });
		}
		setDays(days);

		// commented out as usrData doesnt contain a client name property
		// setExportCSVFileName(usrData.clientName.trim() + exportCSVFileName);
		mainFunction();
	}, []);

	const mainFunction = () => {
		if (sampleplanData) {
			if (sampleplanData.isPlanDeleted && !isSamplePlanDeleted) {
				if (sampleplanData.status?.statusValue) {
					const index = samplePlans.findIndex(
						(obj) => obj.id === selectedPlan.id,
					);
					const newData = [
						...samplePlans.slice(0, index),
						...samplePlans.slice(index + 1),
					];
					setAlertMessage("Deleted successfully.");

					setSamplePlans(newData);
					if (newData[0]) {
						prepareCSV(newData);
						setSelectedPlan(newData[0]);
						setSelectedPlanId(newData[0].id);
						//dispatch(selectSamplePlan(newData[0]));
					}

					//GetAllSamplePlans(userData);
				}
				setIsSamplePlanDeleted(true);
				setShowAlert(true);
				setIsSuccessAlert(sampleplanData.status.statusValue);
			}

			if (sampleplanData.isPlanSaved && !isSamplePlanSaved) {
				if (sampleplanData.status.statusValue) {
					setEditMode(false);
					setAddMode(false);
					setViewMode(true);
					setAlertMessage("Saved successfully.");
					GetAllSamplePlans(userData);
				} else {
					setAlertMessage(sampleplanData.status.statusDisplay);
				}
				setShowAlert(true);
				setIsSamplePlanSaved(true);
				setIsSuccessAlert(sampleplanData.status.statusValue);
			}

			if (sampleplanData.isImportPlanSaved && !isImportPlanSaved) {
				if (sampleplanData.status?.statusValue) {
					setAlertMessage(
						"Imported successfully.\n" + sampleplanData.status.statusDisplay,
					);
					GetAllSamplePlans(userData);

					//GetSitesByClient
					// dispatch(
					// 	getSitesByClient({
					// 		userId: userData.id,
					// 		userToken: userData.userToken,
					// 		clientId: userData.clientId,
					// 	})
					// );
					getAllSites({
						userId: userData.id,
						userToken: userData.userToken,
						clientId: userData.clientId,
					});
					// samplePlanApi.getSitesByClient({
					// 	userId: userData.id,
					// 	userToken: userData.userToken,
					// 	clientId: userData.clientId,
					// });
					//GetAll sites
					// dispatch(
					// 	getAllSites({
					// 		userId: userData.id,
					// 		userToken: userData.userToken,
					// 		clientId: userData.clientId,
					// 	})
					// );
					getAllSites({
						userId: userData.id,
						userToken: userData.userToken,
						clientId: userData.clientId,
					});
					//GetAll test points
					// dispatch(
					// 	getAllTestPoints({
					// 		userId: userData.id,
					// 		userToken: userData.userToken,
					// 	})
					// );
					getAllTestPoints({
						userId: userData.id,
						userToken: userData.userToken,
					});

					//GetAll templates
					// dispatch(
					// 	getTemplates({ userId: userData.id, userToken: userData.userToken })
					// );
					getTemplates({ userId: userData.id, userToken: userData.userToken });

					setImportMappingDialog(false);
				} else {
					setIsImportError(true);
					//setAlertMessage(sampleplanData.status.statusDisplay);
				}
				setIsSuccessAlert(sampleplanData.status.statusValue);
				setShowAlert(true);
				setIsImportPlanSaved(true);
			}

			if (sampleplanData && sampleplanData?.samplePlans?.length > 0) {
				setSamplePlans(sampleplanData.samplePlans);
				sampleplanData.isSamplePlansLoaded = false; //
				setIsSamplePlansLoaded(true);
				if (sampleplanData.samplePlans.length > 0) {
					prepareCSV(sampleplanData.samplePlans);
					sampleplanData.selectedSamplePlan
						? setSelectedPlan(sampleplanData.selectedSamplePlan)
						: setSelectedPlan(sampleplanData.samplePlans[0]);
					if (sampleplanData.samplePlans[0].frequency === "4") {
						setIsDayOrWeekDropDown(false);
						sampleplanData.selectedSamplePlan
							? setSelectedDay({
									label: sampleplanData.selectedSamplePlan.dayOfMonth,
									value: sampleplanData.selectedSamplePlan.dayOfMonth,
								})
							: setSelectedDay({
									label: sampleplanData.samplePlans[0].dayOfMonth,
									value: sampleplanData.samplePlans[0].dayOfMonth,
								});
					} else {
						setIsDayOrWeekDropDown(true);
						var opt = [];
						var values = [];
						if (sampleplanData.selectedSamplePlan) {
							sampleplanData.selectedSamplePlan.dayOfWeek
								.split(",")
								.forEach((item) => {
									var result = weekDays.find((o) => o.value === parseInt(item));
									if (result) {
										opt.push({ label: result.label, value: result.value });
										values.push(result.value);
									}
								});
						} else {
							sampleplanData.samplePlans[0].dayOfWeek
								.split(",")
								.forEach((item) => {
									var result = weekDays.find((o) => o.value === parseInt(item));
									if (result) {
										opt.push({ label: result.label, value: result.value });
										values.push(result.value);
									}
								});
						}
						setSelectedWeekDays(values);
						setSelectedWeekDaysOption(opt);
					}
					//set the selected Test points
					var selectedPoints = [];
					if (sampleplanData.selectedSamplePlan) {
						sampleplanData.selectedSamplePlan.testPoints &&
							sampleplanData.selectedSamplePlan.testPoints.forEach((item) => {
								selectedPoints.push(item.id);
							});
					} else {
						sampleplanData.samplePlans[0].testPoints &&
							sampleplanData.samplePlans[0].testPoints.forEach((item) => {
								selectedPoints.push(item.id);
							});
					}
					setAddedTestPoints(selectedPoints);
					//set the selected sites
					var selectedSites = [];
					if (sampleplanData.selectedSamplePlan) {
						sampleplanData.selectedSamplePlan.sites &&
							sampleplanData.selectedSamplePlan.sites.forEach((item) => {
								selectedSites.push(item.id);
							});
					} else {
						sampleplanData.samplePlans[0].sites &&
							sampleplanData.samplePlans[0].sites.forEach((item) => {
								selectedSites.push(item.id);
							});
					}
					setAddedSites(selectedSites);
				}
			}
		}
	};

	const getSiteDropdownOptions = (siteData) => {
		if (siteData) {
			// if (siteData.isSitesLoaded && !isSitesLoaded) {
			var options = [];
			siteData.sites.forEach((item) => {
				options.push({ label: item.name, value: item.id });
			});
			setSiteOptions(options);
			if (selectedSiteId === 0) {
				setSelectedSiteId(siteData.sites[0].id);
			}
			if (options.length > 0) {
				setSelectedSite(options[0]);
			}
			setIsSitesLoaded(true);
			siteData.isSitesLoaded = false;
			// }

			if (siteData.isAllSitesLoaded && !isAllSitesLoaded) {
				setIsAllSitesLoaded(true);
				setAllSites(siteData.sites);
			}
		}
	};

	//Template List for drop down
	// if (sampleplanQuoteData && sampleplanQuoteData.status) {
	// 	if (sampleplanQuoteData.isTemplatesLoaded && !templatesAdded) {
	// 		let items = [{ label: "", value: 0 }];
	// 		items.splice(0, 1);
	// 		sampleplanQuoteData.templatesResult &&
	// 			sampleplanQuoteData.templatesResult.forEach(function (item) {
	// 				var obj = { label: item.name, value: item.id };
	// 				items.push(obj);
	// 			});
	// 		setTemplateList([]);
	// 		setTemplateList(items);
	// 		sampleplanQuoteData.templatesResult &&
	// 			setSelectedTemplateId(items[0].value);
	// 		setTemplatesAdded(true);
	// 	}
	// }
	React.useEffect(() => {
		if (
			sampleplanQuoteData &&
			sampleplanQuoteData.status &&
			sampleplanQuoteData.isTemplatesLoaded &&
			!templatesAdded
		) {
			let items = [];
			if (sampleplanQuoteData.templatesResult) {
				items = sampleplanQuoteData.templatesResult.map((item) => ({
					label: item.name,
					value: item.id,
				}));
			}

			setTemplateList(items);

			if (items.length > 0) {
				setSelectedTemplateId(items[0].value);
			}

			setTemplatesAdded(true);
		}
	}, [sampleplanQuoteData, templatesAdded]);

	const closeDialog = () => {
		setShowAlert(false);
	};

	function freq(freq) {
		switch (freq) {
			case "1":
				return "Weekly";
			case "2":
				return "Fortnightly";
			case "3":
				return "Every 4 Weeks";
			case "4":
				return "Monthly";
			default:
				return "N/A";
		}
	}

	function week(week) {
		switch (week) {
			case "1":
				return "Sun";
			case "2":
				return "Mon";
			case "3":
				return "Tue";
			case "4":
				return "Wed";
			case "5":
				return "Thu";
			case "6":
				return "Fri";
			case "7":
				return "Sat";
			default:
				return "N/A";
		}
	}

	function prepareCSV(list) {
		var data;
		var finalObj = [];
		list.map((item) => {
			item.testPoints.map((test) => {
				item.sites.map((site) => {
					data = {
						siteId: site.id,
						siteName: site.name,
						id: item.id,
						name: item.name,
						testId: test.id,
						testName: test.name,
						location: test.location,
						where: test.where,
						frequency: freq(item.frequency),
						dayOfWeek: week(item.dayOfWeek),
						dayOfMonth: item.dayOfMonth,
					};
					finalObj.push(data);
				});
			});
		});
		setExportCSVData(finalObj);
	}

	const handlePopup = (event, action) => {
		event.preventDefault();

		if (action === "new") {
			setTemplateDialog(true);
		}
		if (action === "imp") {
			setCSVMappingColumns([]);
			setImportMappingDialog(true);
		}
		if (action === "exp") {
		}
		setPopVisibility(!popVisible);
	};

	const hanldCancelTemplate = () => {
		setTemplateDialog(false);
	};

	const hanldCancelImportDialog = () => {
		setImportMappingDialog(false);
	};

	const gotoCreatePlanPage = (event) => {
		//Dispatch to get the templates
		event.preventDefault();
		changepage(true, selectedTemplateId, allSites);
	};

	const handleTemplateChange = (event) => {
		if (event === null) {
			setSelectedTemplateId(-1);
			return;
		}
		setSelectedTemplateId(event.value);
	};

	let headers = [
		{ label: "site_id", key: "siteId" },
		{ label: "site_name", key: "siteName" },
		{ label: "sample_plan_id", key: "id" },
		{ label: "sample_plan_name", key: "name" },
		{ label: "test_point_id", key: "testId" },
		{ label: "test_point_name", key: "testName" },
		{ label: "test_point_location", key: "location" },
		{ label: "test_point_where", key: "where" },
		{ label: "frequency", key: "frequency" },
		{ label: "day_of_the_week", key: "dayOfWeek" },
		{ label: "day_of_the_month", key: "dayOfMonth" },
	];

	const databaseColumns = [
		{
			value: "1",
			label: "Test Point Name",
			isDisabled: false,
			csvColumnIndex: -1,
		},
		{
			value: "2",
			label: "Test Point Location",
			isDisabled: false,
			csvColumnIndex: -1,
		},
		{
			value: "3",
			label: "Test Point Where",
			isDisabled: false,
			csvColumnIndex: -1,
		},
		{
			value: "4",
			label: "Sample Plan Name",
			isDisabled: false,
			csvColumnIndex: -1,
		},
	];

	const handleDBColumnChange = (event, field, csvColumnindex) => {
		setColumnExist(false);
		setIsImportColumnsSelected(true);
		/*var dbCols = databaseColumns.find((obj) => obj.csvColumnIndex === csvColumnindex);
		if (event == null) {
			if (dbCols) {
				dbCols.csvColumnIndex = -1;
			}
		}
		else {
			var dbCols = databaseColumns.find((obj) => obj.value === event.value);
			if (dbCols) {
				//setColumnExist(true); 
				return;
			}

			dbCols.csvColumnIndex = csvColumnindex;
		}*/

		var selectedValue = "";
		if (event != null) {
			selectedValue = event.value;
		}
		let mapping = csvMappingcolumns.map((el) =>
			el.name === field
				? {
						...el,
						selector: selectedValue,
						selectedIndex: csvColumnindex,
					}
				: el,
		);
		setCSVMappingColumns(mapping);
		setIsImportFileSelected(true);
	};

	const processData = (dataString) => {
		const dataStringLines = dataString.split(/\r\n|\n/);
		const headers = dataStringLines[0].split(
			/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
		);

		const list = [];
		for (let i = 1; i < dataStringLines.length; i++) {
			const row = dataStringLines[i].split(
				/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
			);
			if (headers && row.length == headers.length) {
				const obj = {};
				for (let j = 0; j < headers.length; j++) {
					let d = row[j];
					if (d.length > 0) {
						if (d[0] == '"') d = d.substring(1, d.length - 1);
						if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
					}
					if (headers[j]) {
						obj[headers[j]] = d;
					}
				}

				// remove the blank rows
				if (Object.values(obj).filter((x) => x).length > 0) {
					list.push(obj);
				}
			}
		}

		// prepare columns list from headers
		const columns = headers.map((c) => ({
			name: c,
			selector: c,
			selectedIndex: -1,
		}));

		setImportedData(list);
		setCSVMappingColumns(columns);
	};

	const handleFileUpload = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.onload = (evt) => {
			/* Parse data */
			const base = evt.target.result;
			const wb = XLSX.read(base, { type: "binary" });
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			/* Convert array of arrays */
			const data = XLSX.utils.sheet_to_csv(ws);
			processData(data);
		};
		setSelectedImportFile(file);
		setIsImportFileSelected(true);
		reader.readAsBinaryString(file);

		getBase64(file, (result) => {
			var base64 = result.replace("data:application/vnd.ms-excel;base64,", "");
			setImportedCSVBase64(base64);
		});
	};

	const handleImportSave = (event) => {
		setIsImportPlanSaved(false);
		setIsImportError(false);

		if (selectedImportFile == null) {
			setIsImportFileSelected(false);
			return;
		}

		var testPointName = "",
			samplePlanName = "",
			location = "",
			where = "";
		var mappedData;
		var mappingColumn = [];
		importedData.map((data) => {
			csvMappingcolumns.map((column) => {
				if (column.selector == 1 && testPointName === "") {
					mappingColumn.push({
						name: "Test Point Name",
						value: column.selectedIndex,
					});
					testPointName = "Test Point Name";
					//mappingColumn = {
					//	...mappingColumn,
					//		"Test Point Name": column.selectedIndex
					//	}
				}
				if (column.selector == 2 && location === "") {
					mappingColumn.push({
						name: "Test Point Location",
						value: column.selectedIndex,
					});
					location = "Test Point Location";
					//mappingColumn = {
					//	...mappingColumn,
					//	"Test Point Location": column.selectedIndex
					//}
				}
				if (column.selector == 3 && where === "") {
					mappingColumn.push({
						name: "Test Point Where",
						value: column.selectedIndex,
					});
					where = "Test Point Where";
					//mappingColumn = {
					//	...mappingColumn,
					//	"Test Point Where": column.selectedIndex
					//}
				}
				if (column.selector == 4 && samplePlanName === "") {
					mappingColumn.push({
						name: "Sample Plan Name",
						value: column.selectedIndex,
					});
					samplePlanName = "Sample Plan Name";
					//mappingColumn = {
					//	...mappingColumn,
					//	"Sample Plan Name": column.selectedIndex
					//}
				}
			});
		});

		if (!samplePlanName || !testPointName) {
			setIsImportColumnsSelected(false);
			return;
		}

		mappedData = {
			userId: userData.id,
			userToken: userData.userToken,
			frequency: "1",
			dayOfWeek: "1,2,3,4,5,6,7",
			dayOfMonth: 0,
			siteIds: [0],
			mappings: mappingColumn,
			file: importedCSVBase64,
		};

		// dispatch(importSamplePlan(mappedData));
		samplePlanApi.importSamplePlan(mappedData);
	};

	return (
		<div className="py-1">
			<FCConfirmAlert
				message="Are you sure you want to delete this sample plan?"
				isOpen={isShowConfirmAlert}
				title="Delete Sample Plan"
				onOkClick={(event) => handleDeleteSamplePlan(event)}
				onCancelClick={(event) => handleConfirmCancel(event)}
			/>
			{showAlert && (
				<FCMessageDialog
					isSuccess={isSuccessAlert}
					modalCallBack={() => closeDialog()}
					message={alertMessage}
				/>
			)}
			<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
				<div>
					<h1 className="text-1xl text-gray-500 text-center">Sample Plans</h1>
				</div>
			</div>
			<div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-2 lg:gap-8 w-full">
				<div className="grid grid-cols-1 gap-4 w-1/2 w-full">
					<section aria-labelledby="section-1-title">
						<div className="p-2 w-full">
							<div className="mx-auto w-full flex">
								<div className="text-gray-500 pt-6">
									<button ref={setReferenceRef} onClick={handlePopupClick}>
										<DotVerticalSvgSvgIcon />
									</button>
								</div>
								<div
									ref={setPopperRef}
									style={styles.popper}
									{...attributes.popper}
									className={
										!popVisible
											? "hidden"
											: "w-26 mt-8 px-2 rounded-2xl border-2 border-gray -m-8"
									}
								>
									<div className="px-2 mt-1 py-1">
										<a
											href="#"
											className="block flex flex-inline w-full py-1"
											onClick={(event) => handlePopup(event, "new")}
										>
											<PlusCircleSvgIconTransparent /> Create
										</a>
										<a
											href="#"
											className="block flex flex-inline w-full py-1"
											onClick={(event) => handlePopup(event, "imp")}
										>
											<PlusCircleSvgIconTransparent /> Import
										</a>
										{samplePlans.length === 0 && (
											<a
												href="#"
												className="block flex flex-inline w-full py-1 disabled"
											>
												<PlusCircleSvgIconTransparent /> Export
											</a>
										)}
										{samplePlans.length > 0 && (
											<CSVLink
												className="block flex flex-inline w-full py-1"
												data={exportCSVData}
												headers={headers}
												filename={exportCSVFileName}
											>
												<PlusCircleSvgIconTransparent /> Export
											</CSVLink>
										)}
									</div>
								</div>
								<div className="pl-9 pr-9 w-full">
									<div className="text-fclabel">Select Site</div>
									<FCSelectSiteFieldWithNoValue
										id="siteName"
										onChange={(event) => onSiteChange(event)}
										options={siteOptions}
										className=" w-full "
									/>
								</div>
								<div className="w-1/6">&nbsp;</div>
							</div>
						</div>
						<div>
							<div className="w-full mb-2 mx-auto flex item-center">
								<div className="w-1/6">&nbsp;</div>
								<div className="w-4/6">
									<h2 className="text-1xl font-semibold text-gray-500 text-center py-2">
										Select A Sample Plan
									</h2>
								</div>
								<div className="w-1/6">&nbsp;</div>
							</div>
							<div className="h-sitebox-large overflow-y-auto item-center px-11 mx-auto flex">
								<div className="w-1/6">&nbsp;</div>
								<div className="w-4/6 w-full">
									{samplePlans &&
										samplePlans.length > 0 &&
										samplePlans?.length === 0 && (
											<h2 className=" text-center align-middle">
												No sample plans found
											</h2>
										)}
									{samplePlans &&
										samplePlans.length > 0 &&
										samplePlans?.map((splan) => (
											<FCSiteBox
												key={"key" + splan.id}
												id={splan.id}
												text={splan.name}
												selected={splan?.id === selectedPlan?.id ? true : false}
												onClick={(event) => onSamplePlanChange(event, splan)}
											/>
										))}
								</div>
								<div className="w-1/6">&nbsp;</div>
							</div>
						</div>
					</section>
				</div>
				<div className="grid grid-cols-1 gap-4 w-1/2 w-full">
					{(samplePlans.length > 0 || addMode) && (
						<section aria-labelledby="section-2-title">
							<div className="overflow-hidden">
								<div className="p-2 mx-auto">
									<div className="w-full overflow-hidden h-1/2 mx-auto">
										<div className="w-3/4 mb-2  mx-auto">
											<h2 className="text-1xl font-semibold text-gray-500 text-center mb-1">
												{addMode && <> Add Sample Plan</>}
												{editMode && <> Edit Sample Plan </>}
												{viewMode && <> Sample Plan Details </>}
											</h2>
										</div>
										<div className="w-3/4 overflow-hidden h-1/2 mx-auto">
											<div
												className={
													"pt-1 " +
													(addMode || editMode
														? " border-2 border-primary rounded-2xl "
														: "")
												}
											>
												<div className="mb-2 w-3/4 mx-auto pt-1">
													<FCSiteInputField
														id="name"
														onChange={(event) => onInputChange(event.target)}
														labelText="Sample Plan Name"
														value={selectedPlan?.name}
														required={true}
														isError={isNameEntered}
														readOnly={viewMode}
													/>
												</div>
												<div className="mb-3 w-3/4 flex flex-row mx-auto">
													<div className="w-full flex-row">
														<label
															htmlFor="email"
															className="flex flex-row text-gray-500"
														>
															Frequency
														</label>
														<Select
															value={frequency}
															onChange={(event) => handleFrequencyChange(event)}
															name="frequency"
															isDisabled={viewMode}
															options={frequencyOptions}
															styles={Styles}
															className="basic-multi-select border-2 rounded-2xl border-gray-300 text-fcinputtext"
															classNamePrefix="select"
														/>
													</div>
												</div>
												<div
													className={
														isDayOrWeekDropDown
															? " mb-3 w-3/4 flex flex-row mx-auto "
															: "hidden"
													}
												>
													<div className="w-full flex-row">
														<label
															htmlFor="email"
															className="flex flex-row text-gray-500"
														>
															Day of the Week
														</label>
														<Select
															ref={(ref) => {
																selectRefWeeks = ref;
															}}
															value={selectedWeekDaysOption}
															onChange={(event) => handleWeekChange(event)}
															maxMenuHeight={250}
															isMulti
															isDisabled={viewMode}
															name="Week"
															options={weekDays}
															styles={Styles}
															className="basic-multi-select border-2 rounded-2xl border-gray-300 text-fcinputtext"
															classNamePrefix="select"
															escapeClearsValue={true}
														/>
													</div>
												</div>
												<div
													className={
														!isDayOrWeekDropDown
															? " mb-3 w-3/4 flex flex-row mx-auto "
															: "hidden"
													}
												>
													<div className="w-full flex-row">
														<label
															htmlFor="email"
															className="flex flex-row text-gray-500"
														>
															Day of Month
														</label>
														<Select
															value={selectedDay}
															ref={(ref) => {
																selectRefDay = ref;
															}}
															isDisabled={viewMode}
															onChange={(event) => handleDayChange(event)}
															maxMenuHeight={250}
															name="day"
															options={days}
															styles={Styles}
															className="basic-multi-select border-2 rounded-2xl border-gray-300 text-fcinputtext"
															classNamePrefix="select"
														/>
													</div>
												</div>
												<div className="mb-3 w-3/4 flex flex-row mx-auto">
													<div className="w-full flex-row">
														<label
															htmlFor="isLive"
															className="h-4 pr-2 text-gray-500"
														>
															Is Live
														</label>
														<input
															key="1"
															id="1"
															name="isLive"
															type="checkbox"
															disabled={viewMode}
															checked={isLive}
															onChange={() => handleIsLiveChange()}
															className={
																"h-4 w-4 border-2 border-gray-300 rounded-2xl "
															}
														/>
													</div>
												</div>
												<div className="w-100 flex justify-center">
													<div className="w-3/4 flex flex-col justify-center py-3">
														<label className="flex flex-row text-gray-500">
															Test Points
														</label>
														<div>
															<FCInputSearchField
																className="w-full"
																placeholder="Search Test Points"
																id="search"
																value={testPointSearchTerm}
																onChange={(event) =>
																	handleSearch(event, "testPoint")
																}
															/>
														</div>
													</div>
												</div>
												<FCCheckBox
													options={testPoints}
													onChange={(event) => onTestPointChange(event)}
													selectedValues={addedTestPoints}
													isError={isRequiredError}
													disabled={viewMode}
												/>
												<div className="w-100 flex justify-center">
													<div className="w-3/4 flex flex-col justify-center py-3">
														<label className="flex flex-row text-gray-500">
															Sites
														</label>
														<div>
															<FCInputSearchField
																className="w-full"
																placeholder="Search Sites"
																id="search"
																value={siteSearchTerm}
																onChange={(event) =>
																	handleSearch(event, "site")
																}
															/>
														</div>
													</div>
												</div>
												<FCCheckBox
													options={allSites}
													onChange={(event) => onAllSiteChange(event)}
													selectedValues={addedSites}
													isError={isRequiredError}
													disabled={viewMode}
												/>
											</div>
											<div className="mt-5 mb-2 w-3/4 flex mx-auto">
												{(addMode || editMode) && (
													<div className="mt-1 w-3/4 flex mx-auto">
														<FCButtonWithClick
															onHandleClick={(event) => handleCancel(event)}
															bgColor="bg-fcbutton mr-3"
															type="button"
															icon=""
															value="Cancel"
														/>
														<FCButtonWithClick
															onHandleClick={addorUpdateSamplePlan}
															bgColor="bg-primary"
															type="button"
															icon=""
															value="Save"
														/>
													</div>
												)}
												{viewMode && (
													<div className="mt-1 w-4/5">
														<button
															type="button"
															onClick={(event) =>
																handleAddSamplePlan(event, false)
															}
															className={
																"float-right inline-flex items-center py-3 border-transparent text-base " +
																"  rounded-full text-white  focus:outline-none "
															}
														>
															<EditSvgIcon />
														</button>
													</div>
												)}
												{!addMode && (
													<div className="mt-1 w-1/5">
														<button
															type="button"
															onClick={(event) => showDeleteConfirmAlert(event)}
															className={
																" float-right inline-flex items-center py-3 border-transparent text-base " +
																"  rounded-full text-white  focus:outline-none "
															}
														>
															<TrashSvgIcon />
														</button>
													</div>
												)}
											</div>
											<div className="text-red-500  text-center">
												{isRequiredError && (
													<span>Please enter the required fields</span>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					)}
				</div>
			</div>

			<Transition.Root show={templateDialog} as={Fragment}>
				<Dialog
					as="div"
					className="fixed z-10 inset-0 overflow-y-auto"
					initialFocus={cancelButtonRef}
					onClose={setTemplateDialog}
				>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="hidden sm:inline-block sm:align-middle sm:h-screen"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="inline-block w-1/2 align-bottom bg-white font-large rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:align-middle sm:px-10">
								<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
									<button
										type="button"
										className="bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
										onClick={() => setTemplateDialog(false)}
									>
										<span className="sr-only">Close</span>
										<CloseIcon />
									</button>
								</div>
								<div className="sm:flex sm:items-start">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center w-full mb-5">
										<Dialog.Title
											as="h3"
											className="text-lg leading-6  text-gray-500"
										>
											Create Sample Plan
										</Dialog.Title>
									</div>
								</div>
								<form>
									<div className="text-center">
										<label
											htmlFor="textpointname"
											className="block pb-10  text-gray-700"
										>
											Do you want to create a sample plan using a template?
										</label>
										<div className="w-1/2 w-full flex flex-col justify-center items-center">
											<label
												htmlFor="templates"
												className="block  text-gray-700"
											>
												Select A Template
											</label>
											<div className="mt-1 w-1/2 mb-20">
												<Select
													// defaultValue={templateList[0]}
													placeholder="Select a template"
													isClearable={true}
													options={templateList}
													onChange={(event) => handleTemplateChange(event)}
													name="templates"
													styles={Styles}
													className="basic-multi-select border-2 z-50 rounded-2xl border-gray-300 text-fcinputtext"
													classNamePrefix="select"
												/>
											</div>
										</div>
									</div>
									<div className="pb-5 sm:items-center">
										<div className="mt-3 flex sm:mt-0">
											<FCButtonWithClick
												onHandleClick={hanldCancelTemplate}
												bgColor="bg-fcbutton mr-3"
												type="button"
												icon=""
												value="Cancel"
											/>
											<FCButtonWithClick
												onHandleClick={(event) => gotoCreatePlanPage(event)}
												bgColor="bg-primary mr-3"
												type="button"
												icon=""
												value="Create Using Template"
											/>
											<FCButtonWithClick
												onHandleClick={(event) =>
													handleAddSamplePlan(event, true)
												}
												bgColor="bg-primary"
												type="button"
												icon=""
												value="Create Blank Plan"
											/>
										</div>
									</div>
								</form>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>

			{/*Import Mapping Dialog*/}
			<Transition.Root show={importMappingDialog} as={Fragment}>
				<Dialog
					as="div"
					className="fixed z-10 inset-0 overflow-y-auto"
					initialFocus={cancelButtonRef}
					onClose={setImportMappingDialog}
				>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="hidden sm:inline-block sm:align-middle sm:h-screen"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="inline-block w-1/2 align-bottom bg-white font-large rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:align-middle sm:px-10">
								<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
									<button
										type="button"
										className="bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
										onClick={() => setImportMappingDialog(false)}
									>
										<span className="sr-only">Close</span>
										<CloseIcon />
									</button>
								</div>
								<div className="sm:flex sm:items-start">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center w-full mb-5">
										<Dialog.Title
											as="h3"
											className="text-lg leading-6  text-gray-500"
										>
											Sample Plan Mapping
										</Dialog.Title>
									</div>
								</div>
								<form>
									<div className="flex">
										<div className="mb-3 w-96">
											<input
												className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700
														bg-white bg-clip-padding border border-solid border-gray-300 rounded
														transition ease-in-out m-0"
												type="file"
												accept=".csv"
												id="formFile"
												onChange={handleFileUpload}
											/>
										</div>
									</div>
									<div className="">
										<div className="mt-1 w-3/4">
											<div className="w-full flex inline-flex text-center">
												<div className="w-1/2">
													<label
														htmlFor="templates"
														className="block  text-gray-700"
													>
														CSV Columns
													</label>
												</div>
												<div className="w-1/2">
													<label
														htmlFor="templates"
														className="block  text-gray-700"
													>
														Database Columns
													</label>
												</div>
											</div>
											<div className="h-96 w-full overflow-y-scroll">
												{csvMappingcolumns &&
													csvMappingcolumns.map((items, index) => (
														<div
															className="mt-5 mb-2 w-full flex inline-flex"
															key={index}
														>
															<div className="w-1/2">
																<input
																	name="name"
																	id="name"
																	readOnly={true}
																	className="flex-auto appearance-none border-2 rounded-2xl border-gray-300 bg-transparent px-4 py-2 blueText text-base placeholder-gray-500 focus:outline-none samplePlan w-full "
																	placeholder=""
																	value={items.name}
																/>
															</div>
															<div className="w-1/2">
																<Select
																	isClearable
																	placeholder={"Select..."}
																	onChange={(event) =>
																		handleDBColumnChange(
																			event,
																			items.name,
																			index,
																		)
																	}
																	maxMenuHeight={250}
																	options={databaseColumns}
																	styles={Styles}
																	className="basic-multi-select border-2 rounded-2xl border-gray-300 text-fcinputtext ml-2 mr-2"
																	classNamePrefix="select"
																/>
															</div>
														</div>
													))}
											</div>
										</div>
										<div className="w-full flex flex-inline">
											<div className="w-3/4 flex">
												<div className="text-red-500  text-center">
													{!isImportFileSelected && (
														<span>Please select a file to import.</span>
													)}
													{!isImportColumnsSelected && (
														<span>
															The "Test Point Name" and "Sample PlanName"
															database columns has to be mapped to import the
															data.
														</span>
													)}
													{columnExist && (
														<span>
															The "The selected column has been mapped. Please
															select different column.
														</span>
													)}
													{isImportError && (
														<span>{sampleplanData.status.statusDisplay}</span>
													)}
												</div>
											</div>
											<div className="w-1/4 flex float-right">
												<FCButtonWithClick
													onHandleClick={hanldCancelImportDialog}
													bgColor="bg-fcbutton mr-3"
													type="button"
													icon=""
													value="Cancel"
												/>
												<FCButtonWithClick
													onHandleClick={(event) => handleImportSave(event)}
													bgColor="bg-primary mr-3"
													type="button"
													icon=""
													value="Save"
												/>
											</div>
										</div>
									</div>
								</form>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</div>
	);
};

// const mapStateToProps = (state) => {
// 	const { siteData, sampleplanData, sampleplanQuoteData } = state;
// 	return {
// 		siteData,
// 		sampleplanData,
// 		sampleplanQuoteData,
// 	};
// };

// export default connect(mapStateToProps)(SamplePlan);

export default SamplePlan;
