import * as React from "react";
import { useState } from "react";
import FCSiteInputField from "../../utils/library/sitetextbox";
import FCInputSearchField from "../../utils/library/textboxsearch";
import FCSiteBox from "../../utils/library/sitebox";
import { Validators, validateInput } from "../../utils/library/Validator";
import {
	PlusCircleSvgIcon,
	EditSvgIcon,
	TrashSvgIcon,
} from "../../utils/common/icon-hooks";
import { getUserData } from "../../utils/common/functions";
import FCButtonWithClick from "../../utils/library/buttonwithclick";
import FCMessageDialog from "../../utils/library/dialog";
import FCConfirmAlert from "../../utils/library/confirmalert";
import testPointApi from "../../utils/api/testpointApi";
import { showSweetAlert } from "../Shared/CustomAlert";
import samplePlanApi from "../../utils/api/sampleplanApi";

const TestPoint = () => {
	const testpoint = {
		id: 0,
		name: "",
		location: "",
		where: "",
		userId: 0,
		userToken: "",
		deleted: false,
		selected: false,
		images: [],
	};

	const initialState = {
		isLoading: false,
		isError: false,
		testpoint: testpoint,
		isTestPointLoaded: false,
		isTestPointDeleted: false,
		deleteResult: false,
		testPointInput: {
			userId: -1,
			userToken: "",
			id: -1,
		},
		testPointResult: [
			{
				id: -1,
				name: "",
			},
		],
		isTestPointAdded: false,
		testPointAddResult: false,
		isUniqueChecked: false,
		isUniqueTestPoint: false,
		images: [],
	};

	const validators = [
		{
			check: Validators.required,
			message: "All fields are required.",
		},
	];

	const [userData, setUserData] = useState(null);
	const [searchText, setSearchText] = useState(String);
	const [isTestPointsLoaded, setIsTestPointsLoaded] = useState(false);
	const [addMode, setAddMode] = useState(false);
	const [viewMode, setViewMode] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [selectedTestPoint, setSelectedTestPoint] = useState(testpoint);

	const [isRequiredError, setIsRequiredError] = useState(false);
	const [isNameEntered, setIsNameEntered] = useState(false);
	const [isLocationEntered, setIsLocationEntered] = useState(false);
	const [isWhereEntered, setIsWhereEntered] = useState(false);

	const [isShowConfirmAlert, setIsShowConfirmAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	const [showAlert, setShowAlert] = useState(false);
	const [isTestPointDeleted, setIsTestPointDeleted] = useState(false);
	const [isTestPointSaved, setIsTestPointSaved] = useState(false);
	const [isSuccessAlert, setIsSuccessAlert] = useState(false);
	const [previousTestPoint, setPreviousTestPoint] = useState(testpoint);

	const [testPointData, setTestPointData] = useState(initialState);

	React.useEffect(() => {
		var usrData = getUserData();
		setUserData(usrData);
		setSearchText("");
		// dispatch(
		// 	getAllTestPoint({ userId: usrData?.id, userToken: usrData?.userToken })
		// );

		getAllTestPoint(usrData);

		setViewMode(true);
		setAddMode(false);
		setEditMode(false);
		setShowAlert(false);
	}, []);

	const getAllTestPoint = async (usrData) => {
		const response = await testPointApi.getAllTestPoint({
			userId: usrData?.id,
			userToken: usrData?.userToken,
		});

		if (response.status === 200) {
			setTestPointData({
				...testPointData,
				testPointResult: response.data,
			});
		}
		return response;
	};

	React.useEffect(() => {
		if (
			testPointData &&
			testPointData.isTestPointLoaded &&
			!isTestPointsLoaded
		) {
			setIsTestPointsLoaded(true);
			if (
				testPointData.testPointResult &&
				testPointData.testPointResult.length > 0
			) {
				setSelectedTestPoint(testPointData.testPointResult[0]);
			}
		}
	}, [testPointData, isTestPointsLoaded]);

	React.useEffect(() => {
		if (
			testPointData &&
			testPointData.isTestPointDeleted &&
			!isTestPointDeleted
		) {
			if (testPointData.status.statusValue) {
				setAlertMessage("Deleted successfully.");
				setIsTestPointsLoaded(false);
				getAllTestPoint({
					userId: userData?.id,
					userToken: userData?.userToken,
				});
				setViewMode(true);
				setAddMode(false);
				setEditMode(false);
			}
			setIsTestPointDeleted(true);
			setShowAlert(true);
			setIsSuccessAlert(testPointData.status.statusValue);
		}
	}, [testPointData, isTestPointDeleted, userData]);

	React.useEffect(() => {
		if (testPointData && testPointData.isTestPointAdded && !isTestPointSaved) {
			if (testPointData.status.statusValue) {
				setEditMode(false);
				setAddMode(false);
				setViewMode(true);
				setAlertMessage("Saved successfully.");
				setIsTestPointsLoaded(false);
				getAllTestPoint({
					userId: userData.id,
					userToken: userData.userToken,
				});
			} else {
				setAlertMessage(testPointData.status.statusDisplay);
			}
			setShowAlert(true);
			setIsTestPointSaved(true);
			setIsSuccessAlert(testPointData.status.statusValue);
		}
	}, [testPointData, isTestPointSaved, userData]);

	// if (testPointData) {
	// 	if (testPointData.isTestPointLoaded && !isTestPointsLoaded) {
	// 		// setTestPoints(testPointData.testPointResult ? testPointData.testPointResult : []);
	// 		setIsTestPointsLoaded(true);
	// 		if (
	// 			testPointData.testPointResult &&
	// 			testPointData.testPointResult.length > 0
	// 		) {
	// 			setSelectedTestPoint(testPointData.testPointResult[0]);
	// 		}
	// 	}

	// 	if (testPointData.isTestPointDeleted && !isTestPointDeleted) {
	// 		if (testPointData.status.statusValue) {
	// 			setAlertMessage("Deleted successfully.");
	// 			setIsTestPointsLoaded(false);
	// 			// dispatch(
	// 			// 	getAllTestPoint({
	// 			// 		userId: userData?.id,
	// 			// 		userToken: userData?.userToken,
	// 			// 	})
	// 			// );

	// 			getAllTestPoint({
	// 				userId: userData?.id,
	// 				userToken: userData?.userToken,
	// 			});

	// 			setViewMode(true);
	// 			setAddMode(false);
	// 			setEditMode(false);
	// 		}
	// 		setIsTestPointDeleted(true);
	// 		setShowAlert(true);
	// 		setIsSuccessAlert(testPointData.status.statusValue);
	// 	}

	// 	if (testPointData.isTestPointAdded && !isTestPointSaved) {
	// 		if (testPointData.status.statusValue) {
	// 			setEditMode(false);
	// 			setAddMode(false);
	// 			setViewMode(true);
	// 			setAlertMessage("Saved successfully.");
	// 			setIsTestPointsLoaded(false);
	// 			// dispatch(
	// 			// 	getAllTestPoint({
	// 			// 		userId: userData.id,
	// 			// 		userToken: userData.userToken,
	// 			// 	})
	// 			// );
	// 			getAllTestPoint({
	// 				userId: userData.id,
	// 				userToken: userData.userToken,
	// 			});
	// 		} else {
	// 			setAlertMessage(testPointData.status.statusDisplay);
	// 		}
	// 		setShowAlert(true);
	// 		setIsTestPointSaved(true);
	// 		setIsSuccessAlert(testPointData.status.statusValue);
	// 	}
	// }

	const onTestPointChange = (event, plan) => {
		event.preventDefault();
		//setSelectedPlanId(plan.id);
		setSelectedTestPoint(plan);
		setViewMode(true);
		setAddMode(false);
		setEditMode(false);
		getSingleTestPoint(plan);
	};

	// get single test point -
	const getSingleTestPoint = async (plan) => {
		const input = {
			userId: userData.id,
			userToken: userData.userToken,
			id: plan.id,
		};
		const response = await testPointApi.getSingleTestPoint(input);
		if (response.status === 200) {
			setSelectedTestPoint(response.data);
		}
	};

	const onInputChange = (event) => {
		if (event.name === "name") {
			setIsNameEntered(validateInput(validators, event.value));
		}
		if (event.name === "location") {
			setIsLocationEntered(validateInput(validators, event.value));
		}
		if (event.name === "where") {
			setIsWhereEntered(validateInput(validators, event.value));
		}

		setSelectedTestPoint((prevState) => ({
			...prevState,
			[event.name]: event.value,
		}));
	};

	// TODO: confirm this
	const [samplePlanData, setSamplePlanData] = useState({
		samplePlanResult: [],
	});
	const getAllSamplePlans = async (input) => {
		const response = await samplePlanApi.getSamplePlans({
			userId: input.userId,
			userToken: input.userToken,
		});
		if (response.status === 200) {
			setSamplePlanData({ samplePlanResult: response.data });
		}
	};

	const handleDeleteTestPoint = async (event) => {
		event.preventDefault();
		setShowAlert(false);
		if (userData) {
			const response = await testPointApi.deleteTestpoint({
				userId: userData.id,
				userToken: userData.userToken,
				id: selectedTestPoint.id,
			});

			if (response.status === 200) {
				getAllTestPoint(userData);
				getAllSamplePlans(userData);
				setSelectedTestPoint(testpoint);
				showSweetAlert("success", "Test Point deleted successfully");
			} else {
				setShowAlert(true);
				setIsSuccessAlert(false);
				setAlertMessage("Error occurred...");
			}
		}

		setIsTestPointDeleted(false);
		setIsShowConfirmAlert(false);
	};

	const showDeleteConfirmAlert = (event) => {
		event.preventDefault();
		setIsShowConfirmAlert(true);
	};

	const handleConfirmCancel = (event) => {
		setIsShowConfirmAlert(false);
	};

	const closeDialog = () => {
		setShowAlert(false);
	};

	const handleCancel = () => {
		setEditMode(false);
		setAddMode(false);
		setViewMode(true);
		setIsRequiredError(false);
		setIsNameEntered(false);
		setIsLocationEntered(false);
		setIsWhereEntered(false);
		setSelectedTestPoint(previousTestPoint);
		setPreviousTestPoint(testpoint);
	};

	const validateNullOrUndefined = () => {
		if (
			typeof selectedTestPoint.name !== "undefined" &&
			selectedTestPoint.name &&
			typeof selectedTestPoint.location !== "undefined" &&
			selectedTestPoint.location &&
			typeof selectedTestPoint.where !== "undefined" &&
			selectedTestPoint.where
		) {
			return true;
		} else {
			return false;
		}
	};

	const addorUpdateTest = async () => {
		setIsNameEntered(validateInput(validators, selectedTestPoint.name));
		setIsLocationEntered(validateInput(validators, selectedTestPoint.location));
		setIsWhereEntered(validateInput(validators, selectedTestPoint.where));

		if (validateNullOrUndefined()) {
			setIsRequiredError(false);
			setIsTestPointSaved(false);
			// dispatch(addOrUpdateTestPoint(selectedTestPoint));
			// await testPointApi.addOrUpdateTestPoint(selectedTestPoint);
			let response;

			// remove the image base64 prefixes
			const removePrefix = (str) => {
				if (!str) return null;
				return str.replace(/^data:image\/[a-z]+;base64,/, "");
			};

			const data = {
				...selectedTestPoint,
				images: selectedTestPoint.images.map((image) => {
					image.base64 = removePrefix(image.base64);
					return image;
				}),
			};

			if (selectedTestPoint.id > 0) {
				response = await testPointApi.updateTestPoint(data);
			} else {
				response = await testPointApi.addTestPoint(data);
			}

			if (response.status === 200) {
				showSweetAlert("success", "Saved Successfully");
				setIsTestPointSaved(true);
				setEditMode(false);
				setAddMode(false);
				setViewMode(true);
				setAlertMessage("Saved successfully");
				getAllTestPoint(userData);
				getAllSamplePlans(userData);
				setSelectedTestPoint(testpoint);
			} else {
				setIsRequiredError(true);
			}
		} else {
			setIsRequiredError(true);
		}
	};

	const handleAddTestPoint = (event, isNew) => {
		event.preventDefault();

		// don't continue if there's no selected test point and is not adding new from the button
		if (selectedTestPoint.id === 0 && addMode === false) {
			return;
		}

		if (isNew) {
			setSelectedTestPoint(testpoint);
			setAddMode(true);
			setEditMode(false);
		} else {
			setPreviousTestPoint(selectedTestPoint);
			setAddMode(false);
			setEditMode(true);
		}
		setSelectedTestPoint((prevState) => ({
			...prevState,
			userId: userData.id,
			userToken: userData.userToken,
		}));
		setViewMode(false);
		setShowAlert(false);
	};

	const handleTestSearch = (txtValue) => {
		setSearchText(txtValue);
		if (
			testPointData.testPointResult &&
			testPointData.testPointResult.length > 0
		) {
			if (txtValue === "") {
				setSelectedTestPoint(testPointData.testPointResult[0]);
			} else {
				var filterResult = testPointData.testPointResult.filter(
					function (item) {
						return item.name.toLowerCase().indexOf(txtValue.toLowerCase()) >= 0;
					},
				);
				if (filterResult.length > 0) {
					setSelectedTestPoint(filterResult[0]);
				} else {
					setSelectedTestPoint(testpoint);
				}
			}
		}
	};

	const handleUpload = async () => {
		// Use FileReader to read the selected file
		const fileInput = document.createElement("input");
		fileInput.type = "file";
		fileInput.accept = "image/*";

		if (!selectedTestPoint?.images) {
			setSelectedTestPoint((prevState) => ({
				...prevState,
				images: [],
			}));
		}

		// only allow 3 images
		if (selectedTestPoint?.images) {
			if (selectedTestPoint.images.length >= 3) {
				return;
			}
		}

		fileInput.onchange = async (e) => {
			const file = e.target.files[0];
			if (file) {
				const reader = new FileReader();
				reader.onload = (event) => {
					let imageUrl = event.target.result;
					setSelectedTestPoint((prevState) => ({
						...prevState,
						images: [
							...prevState.images,
							{
								id: 0,
								testPointId: 0,
								order: prevState.images.length + 1,
								base64: imageUrl,
							},
						],
					}));
				};
				reader.readAsDataURL(file);
			}
		};

		fileInput.click();
	};

	const handleDeleteImage = (event, index) => {
		event.preventDefault();
		// setting to 0 will remove the image when the test point is saved
		setSelectedTestPoint((prevState) => ({
			...prevState,
			images: prevState.images.map((image, i) =>
				i === index ? { ...image, order: 0 } : image,
			),
		}));
	};

	const returnImageSource = (image) => {
		// if the image contains an image url, then use that - else us the base64 data
		if (image.imageUrl && image.id > 0) {
			return image.imageUrl;
		}
		return image.base64;
	};

	return (
		<React.Fragment>
			<div className="py-1">
				<FCConfirmAlert
					message="Are you sure you want to delete this test point?"
					isOpen={isShowConfirmAlert}
					title="Delete Test Point"
					onOkClick={(event) => handleDeleteTestPoint(event)}
					onCancelClick={(event) => handleConfirmCancel(event)}
				/>
				{showAlert && (
					<FCMessageDialog
						isSuccess={isSuccessAlert}
						modalCallBack={() => closeDialog()}
						message={alertMessage}
					/>
				)}
				<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
					<h1 className="text-1xl  text-gray-500 text-center">Test Points</h1>
				</div>
				<div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-2 lg:gap-8 w-full">
					<div className="grid grid-cols-1 gap-4 w-1/2 w-full">
						<section aria-labelledby="section-1-title">
							<div className="overflow-hidden w-full items-center">
								<div className="p-2 w-full">
									<div className="w-full mb-2 mx-auto flex">
										<div className="w-2/6 text-gray-500">
											Add Test Point new
										</div>
										<div className="w-4/6">&nbsp;</div>
									</div>
									<div className="overflow-hidden h-1/2 mx-auto w-full flex">
										<div className="text-gray-500 w-1/6">
											<button
												style={{ float: "right" }}
												onClick={(event) => handleAddTestPoint(event, true)}
												type="button"
											>
												<PlusCircleSvgIcon />
											</button>
										</div>
										<div className="pl-8 pr-9 w-4/6 w-full">
											<FCInputSearchField
												className=""
												placeholder="Search Test Point"
												id="search"
												value={searchText}
												onChange={(event) => handleTestSearch(event)}
											/>
										</div>
										<div className="w-1/6">&nbsp;</div>
									</div>
								</div>
								<div>
									<div className="w-full mb-2 mx-auto item-center flex">
										<div className="w-1/6">&nbsp;</div>
										<div className="w-4/6">
											<h2 className="text-1xl font-semibold text-gray-500 text-center py-2">
												Select A Test Point
											</h2>
										</div>
										<div className="w-1/6">&nbsp;</div>
									</div>
									<div className="h-sitebox-large overflow-y-auto item-center px-11 mx-auto flex">
										<div className="w-1/6">&nbsp;</div>
										<div className="w-4/6 w-full h-full">
											{testPointData.testPointResult.length === 0 && (
												<h2 className=" text-center align-middle">
													No test point found
												</h2>
											)}
											{testPointData.testPointResult &&
												testPointData.testPointResult.map((test) => (
													<FCSiteBox
														key={"key" + test.id}
														id={test.id}
														text={test.name}
														selected={
															test.id === selectedTestPoint.id ? true : false
														}
														onClick={(event) => onTestPointChange(event, test)}
													/>
												))}
										</div>
										<div className="w-1/6">&nbsp;</div>
									</div>
								</div>
							</div>
						</section>
					</div>
					<div className={"grid grid-cols-1 gap-4"}>
						{(testPointData.testPointResult.length > 0 || addMode) && (
							<section aria-labelledby="section-2-title">
								<div className="overflow-hidden">
									<div className="p-2 mx-auto">
										<div className="w-full overflow-hidden h-1/2 mx-auto">
											<div className="w-3/4 mb-2  mx-auto">
												<h2 className="text-1xl font-semibold text-gray-500 text-center mb-1">
													{addMode && <> Add Test Point </>}
													{editMode && <> Edit Test Point </>}
													{viewMode && <> Test Point Details </>}
												</h2>
											</div>
											<div className="w-3/4 overflow-hidden h-1/2 mx-auto">
												<div
													className={
														"pt-1 " +
														(addMode || editMode
															? " border-2 border-primary rounded-2xl "
															: "")
													}
												>
													<div className="mb-2 w-3/4 mx-auto pt-1">
														<FCSiteInputField
															id="name"
															onChange={(event) => onInputChange(event.target)}
															labelText="Test Point Name"
															value={selectedTestPoint.name}
															required={true}
															isError={isNameEntered}
															readOnly={viewMode}
														/>
													</div>
													<div className="mb-3 w-3/4 flex flex-row mx-auto">
														<div className="w-full flex-row">
															<FCSiteInputField
																id="location"
																onChange={(event) =>
																	onInputChange(event.target)
																}
																labelText="Location"
																value={selectedTestPoint.location}
																required={true}
																isError={isLocationEntered}
																readOnly={viewMode}
															/>
														</div>
													</div>
													<div className=" mb-3 w-3/4 flex flex-row mx-auto ">
														<div className="w-full flex-row">
															<FCSiteInputField
																id="where"
																onChange={(event) =>
																	onInputChange(event.target)
																}
																labelText="Where"
																value={selectedTestPoint.where}
																required={true}
																isError={isWhereEntered}
																readOnly={viewMode}
															/>
														</div>
													</div>
													<div className=" mb-3 w-3/4 flex flex-row mx-auto">
														<div className="w-full flex flex-row items-center space-x-2 py-3 justify-center">
															{selectedTestPoint &&
																selectedTestPoint?.images?.length === 0 &&
																selectedTestPoint.id !== 0 && (
																	<h2 className=" text-center align-middle">
																		No images found
																	</h2>
																)}
															{selectedTestPoint &&
																selectedTestPoint.images?.length > 0 &&
																selectedTestPoint.images
																	.filter((image) => image.order > 0) // Filter images where id > 0
																	.map((image, index) => (
																		<div
																			key={index}
																			className="relative w-1/2 h-full py-3 justify-center items-center"
																		>
																			<img
																				src={returnImageSource(image)}
																				alt={`test point image ${index + 1}`}
																				className="w-[10px] h-[10px] object-cover rounded"
																			/>
																			<button
																				type="button"
																				onClick={(event) =>
																					handleDeleteImage(event, index)
																				}
																				className="absolute -top-5 -right-2 rounded-full p-1 focus:outline-none"
																			>
																				{(editMode || addMode) && (
																					<TrashSvgIcon className="pl-3 w-3 h-3 text-white" />
																				)}
																			</button>
																		</div>
																	))}
														</div>
													</div>
													{(addMode || editMode) && (
														<div className=" mb-3 w-3/4 flex flex-row mx-auto ">
															<div className="w-full flex-row">
																<FCButtonWithClick
																	onHandleClick={() => handleUpload()}
																	bgColor="bg-fcbutton mr-3"
																	type="button"
																	icon=""
																	value="Upload Image"
																/>
															</div>
														</div>
													)}
												</div>
												<div className="mt-5 mb-2 w-3/4 flex mx-auto">
													{(addMode || editMode) && (
														<div className="mt-1 w-3/4 flex mx-auto">
															<FCButtonWithClick
																onHandleClick={handleCancel}
																bgColor="bg-fcbutton mr-3"
																type="button"
																icon=""
																value="Cancel"
															/>
															<FCButtonWithClick
																onHandleClick={addorUpdateTest}
																bgColor="bg-primary"
																type="button"
																icon=""
																value="Save"
															/>
														</div>
													)}
													{viewMode && (
														<div className="mt-1 w-4/5">
															<button
																type="button"
																onClick={(event) =>
																	handleAddTestPoint(event, false)
																}
																className={
																	"float-right inline-flex items-center py-3 border-transparent text-base " +
																	"  rounded-full text-white  focus:outline-none "
																}
															>
																<EditSvgIcon />
															</button>
														</div>
													)}
													{!addMode && (
														<div className="mt-1 w-1/5">
															<button
																type="button"
																onClick={(event) =>
																	showDeleteConfirmAlert(event)
																}
																className={
																	" float-right inline-flex items-center py-3 border-transparent text-base " +
																	"  rounded-full text-white  focus:outline-none "
																}
															>
																<TrashSvgIcon />
															</button>
														</div>
													)}
												</div>
												<div className="text-red-500  text-center">
													{isRequiredError && (
														<span>Please enter the required fields</span>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default TestPoint;
