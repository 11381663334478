import { Http } from "../Http";
import { AxiosPromise } from "axios";
import apiCall from "../api/apiCall";

const correctiveActionApi = {
	getCorrectiveActions: async (input) => {
		const url =
			"CorrectiveAction/GetAll?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken;
		const response = await apiCall(url, "get");
		return response;
	},

	addCorrectiveAction: async (input) => {
		const obj = JSON.stringify(input);
		const url = "CorrectiveAction/Add";
		const response = await apiCall(url, "post", obj);
		return response;
	},

	deleteCorrectiveAction: async (input) => {
		const url =
			"CorrectiveAction/Delete?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken +
			"&deleteId=" +
			input.id;
		const response = await apiCall(url, "delete");
		return response;
	},
};

export default correctiveActionApi;

// export class CorrectiveActionApi {

//   public static getCorrectiveActions(input: any) {
//     return Http.axios().get('/CorrectiveAction/GetCorrectiveActions?userId=' + input.userId + "&userToken=" + input.userToken)
//       .catch((e) => {
//         return e.response;
//       }) as AxiosPromise<any>;
//   }

//   public static addCorrectiveAction(input: any) {
//     var obj = JSON.stringify(input);
//     var url = '/CorrectiveAction/add';
//     return Http.axios().post(url, obj, { headers: { 'Content-Type': 'application/json' } })
//       .then(response => {
//         return response;
//       }).catch((e) => {
//         return e.response;
//       }) as AxiosPromise<any>;
//   }

//   public static deleteCorrectiveAction(input: any) {
//     var obj = JSON.stringify(input);
//     var url = '/CorrectiveAction/delete';
//     return Http.axios().post(url, obj, { headers: { 'Content-Type': 'application/json' } })
//       .then(response => {
//         return response;
//       }).catch((e) => {
//         return e.response;
//       }) as AxiosPromise<any>;
//   }
// }
