import * as React from "react";
import Clients from "../../clients/clients";
import ClientDetails from "../../clients/clientdetails";
import { RouteComponentProps, useParams } from "react-router";
import FCMessageDialog from "../../../utils/library/dialog";
import { getUserData } from "../../../utils/common/functions";

const ClientsPage = (props) => {
	const [state, setState] = React.useState({
		id: 0,
		showAlert: false,
		isSuccessAlert: false,
		alertMessage: "",
		showConfirm: false,
		title: "",
		user: null,
	});

	// get the params from the url
	const { id } = useParams();

	// const showAlert = (isShow, isSuccess, message) => {
	// 	setState({
	// 		showAlert: isShow,
	// 		isSuccessAlert: isSuccess,
	// 		alertMessage: message,
	// 	});
	// };

	const closeDialog = () => {
		setState({ showAlert: false });
	};

	React.useEffect(() => {
		let usrData = getUserData();

		if (usrData) {
			setState({
				...state,
				user: usrData,
			});
		}

		var alertMessage = window.localStorage.getItem("alertMessage");
		if (alertMessage && alertMessage != "") {
			setState({
				...state,
				showAlert: true,
				alertMessage: alertMessage,
				isSuccessAlert: true,
			});
		} else {
			window.localStorage.setItem("alertMessage", "");
		}
	}, []);

	return (
		<div className="px-20">
			{state.user?.roleId === 1 && state.user?.clientId === 1 ? (
				<>
					{state.showAlert && (
						<FCMessageDialog
							isSuccess={state.isSuccessAlert}
							modalCallBack={() => closeDialog()}
							message={state.alertMessage}
						/>
					)}
					{id >= 0 && (
						<ClientDetails clientId={state.id} seeAlert={state.showAlert} />
					)}
					{(id < 0 || id === undefined) && (
						<Clients seeAlert={state.showAlert} />
					)}
				</>
			) : (
				<h2 className="text-center mt-5">
					You do not have access to view this page
				</h2>
			)}
		</div>
	);
};

export default ClientsPage;

// class ClientsComponent extends React.Component {
// 	state = {
// 		id: 0,
// 		showAlert: false,
// 		isSuccessAlert: false,
// 		alertMessage: "",
// 		showConfirm: false,
// 		title: "",
// 		user: user,
// 	};

// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			id: this.props.match.params.id,
// 			showAlert: false,
// 			isSuccessAlert: false,
// 			alertMessage: "",
// 			showConfirm: false,
// 			title: "",
// 			user: user,
// 		};
// 		this.showAlert = this.showAlert.bind(this);
// 	}

// 	showAlert(isShow, isSuccess, message) {
// 		this.setState({
// 			showAlert: isShow,
// 			isSuccessAlert: isSuccess,
// 			alertMessage: message,
// 		});
// 	}

// 	closeDialog = () => {
// 		this.setState({ showAlert: false });
// 	};

// 	componentWillMount() {
// 		//if (this.props.match.params.id !== undefined) {
// 		//  this.setState({ id: this.props.match.params ? match.params.id: -1 });
// 		//}
// 	}

// 	componentDidUpdate(previousProps, previousState) {
// 		if (previousProps.match.params !== this.props.match.params) {
// 			this.setState({
// 				id: this.props.match.params ? this.props.match.params.id : -1,
// 			});
// 		}

// 		let usrData = getUserData();

// 		if (
// 			previousProps.usersData?.usersResult !== this.props.usersData?.usersResult
// 		) {
// 			if (this.props.usersData?.usersResult.length > 0) {
// 				let usr = this.props.usersData?.usersResult.find(
// 					(item) => item.id === usrData.id
// 				);
// 				if (usr) this.setState({ user: usr });
// 			}
// 		}
// 	}

// 	componentDidMount() {
// 		var alertMessage = window.localStorage.getItem("alertMessage");
// 		if (alertMessage && alertMessage != "") {
// 			this.setState({
// 				showAlert: true,
// 				alertMessage: alertMessage,
// 				isSuccessAlert: true,
// 			});
// 		} else {
// 		}
// 		window.localStorage.setItem("alertMessage", "");

// 		let usrData = getUserData();

// 		if (this.props.usersData?.usersResult.length > 0) {
// 			let usr = this.props.usersData?.usersResult.find(
// 				(item) => item.id === usrData.id
// 			);
// 			if (usr) this.setState({ user: usr });
// 		}
// 	}

// 	render() {
// 		const { id, showAlert, isSuccessAlert, alertMessage } = this.state;
// 		return (
// 			<div className="px-20">
// 				{this.state.user?.roleId === 1 && this.state.user?.clientId === 1 ? (
// 					<>
// 						{showAlert && (
// 							<FCMessageDialog
// 								isSuccess={isSuccessAlert}
// 								modalCallBack={() => this.closeDialog()}
// 								message={alertMessage}
// 							/>
// 						)}
// 						{id >= 0 && (
// 							<ClientDetails
// 								clientId={this.state.id}
// 								seeAlert={this.showAlert}
// 							/>
// 						)}
// 						{(id < 0 || id === undefined) && (
// 							<Clients seeAlert={this.showAlert} />
// 						)}
// 					</>
// 				) : (
// 					<h2 className="text-center mt-5">
// 						You do not have access to view this page
// 					</h2>
// 				)}
// 			</div>
// 		);
// 	}
// }

// const mapStateToProps = (state) => {
// 	const { usersData } = state;
// 	return {
// 		usersData,
// 	};
// };

// export default connect(mapStateToProps)(ClientsComponent);
