export const login_button = "rounded-full mb-3 bg-yellow-600 px-3 py-2";
export const login_label = "rounded-full mb-3 bg-blue-50 py-2 px-5";
export const login_module_body =
	"bg-primary min-h-screen pt-12 md:pt-20 pb-6 px-2 md:px-0 text-center";
export const login_text = "font-bold mb-2 text-gray-500 text-center text-lg";
export const login_link =
	"font-bold mb-3 text-gray-500 text-center text-lg underline";
export const login_input = "outline-none bg-blue-50 text-lg";

export const registration_button = "rounded-full bg-yellow-600 px-3 py-2 w-4/4";
export const registration_label =
	"rounded-full inline-block ml-4 mb-3 bg-blue-50 py-2 px-5 space-x-4 w-4/4";
export const registration_input =
	"outline-none inline-block bg-blue-50 text-lg";
