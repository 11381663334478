import { Http } from "../Http";
import { AxiosPromise } from "axios";
import apiCall from "../api/apiCall";

const testPointApi = {
	getAllTestPoint: async (input) => {
		const url =
			"TestPoint/GetAll?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken;
		const response = await apiCall(url, "get");
		return response;
	},

	getSingleTestPoint: async (input) => {
		const url =
			"TestPoint/GetSingle?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken +
			"&testPointId=" +
			input.id;
		const response = await apiCall(url, "get");
		return response;
	},

	addTestPoint: async (input) => {
		const obj = JSON.stringify(input);
		const url =
			"TestPoint/Add" +
			"?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken;
		const response = await apiCall(url, "post", obj);
		return response;
	},

	updateTestPoint: async (input) => {
		const obj = JSON.stringify(input);
		const url =
			"TestPoint/Update" +
			"?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken +
			"&id=" +
			input.id;

		const data = {
			id: input.id,
			name: input.name,
			location: input.location,
			where: input.where,
		};

		const response = await apiCall(url, "put", obj);
		return response;
	},

	deleteTestpoint: async (input) => {
		const obj = JSON.stringify(input);
		const url =
			"TestPoint/Delete" +
			"?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken +
			"&id=" +
			input.id;
		const response = await apiCall(url, "delete");
		return response;
	},

	checkUniqueTestPointName: async (input) => {
		const url =
			"TestPoint/CheckUniqueName?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken +
			"&name=" +
			input.name;
		const response = await apiCall(url, "get");
		return response;
	},
};

export default testPointApi;

// export class TestPointApi {

// 	public static getAllTestPoint(input: any) {
// 		return Http.axios().get('/TestPoint/GetAllTestPoint?userId=' + input.userId
// 			+ "&userToken=" + input.userToken)
// 			.catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}

// 	public static addOrUpdateTestPoint(input: any) {
// 		var obj = JSON.stringify(input);
// 		return Http.axios().post('/TestPoint/addOrUpdateTestPoint', obj, { headers: { 'Content-Type': 'application/json' } })
// 			.then(response => {
// 				return response;
// 			}).catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}

// 	public static deleteTestpoint(input: any) {
// 		var obj = JSON.stringify(input);
// 		return Http.axios().post('/TestPoint/DeleteTestpoint', obj, { headers: { 'Content-Type': 'application/json' } })
// 			.catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}

// 	public static checkUniqueTestPointName(input: any) {
// 		return Http.axios().get('/TestPoint/ValidateTestPointExist?userId=' + input.userId
// 			+ "&userToken=" + input.userToken + "&name=" + input.name)
// 			.catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}
// }
