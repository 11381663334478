import { Http } from "../Http";
import { AxiosPromise } from "axios";
import apiCall from "./apiCall";

const clientsApi = {
	getClients: async (input) => {
		// const url =
		// 	"Client/GetAll?userId=" + input.id + "&userToken=" + input.userToken;
		const url = "Client/GetAll";
		const response = await apiCall(url, "get");
		return response;
	},

	getClientsWithImage: async () => {
		const url = "Client/GetAllWithImage";
		const response = await apiCall(url, "get");
		return response;
	},

	getSingleClient: async (input) => {
		const url = "Client/GetSingle?id=" + input.id;
		const response = await apiCall(url, "get");
		return response;
	},

	getSingleClientWithImage: async (input) => {
		const url = "Client/GetSingleWithImage?id=" + input.id;
		const response = await apiCall(url, "get");
		return response;
	},

	deleteClient: async (input) => {
		const url =
			"Client/DeleteClient?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken +
			"&deleteId=" +
			input.id;
		const response = await apiCall(url, "get");
		return response;
	},

	addOrUpdateClient: async (input) => {
		// let url = input.userId > 0 ? "Client/Update" : "Clients/Add";
		let url = "Client/Add";

		let data = {
			deleted: true,
			id: 0,
			name: input.name,
			addressLine1: input.addressLine1,
			addressLine2: input.addressLine2,
			town: input.town,
			city: input.city,
			postcode: input.postcode,
			country: input.country,
		};

		data = JSON.stringify(data);

		url =
			url +
			"?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken +
			"&id=" +
			input.id;

		// const method = input.userId > 0 ? "put" : "post";
		const method = "post";
		const response = await apiCall(url, method, data);
		return response;
	},

	// addClient: async (input) => {
	// 	const data = JSON.stringify(input);
	// 	const url =
	// 		"Client/Add?userId=" +
	// 		input.userId +
	// 		"&userToken=" +
	// 		input.userToken +
	// 		"&id=" +
	// 		input.id;
	// 	const response = await apiCall(url, "post", data);
	// 	return response;
	// },
};

export default clientsApi;

// export class ClientsApi {

//   public static getClients(input: any) {
//     return Http.axios().get('/Clients/GetClients?userId=' + input.userId + "&userToken=" + input.userToken)
//       .catch((e) => {
//         return e.response;
//       }) as AxiosPromise<any>;
//   }

//   public static deleteClient(input: any) {
//     return Http.axios().get('/Clients/DeleteClient?userId=' + input.userId + "&userToken=" + input.userToken + "&deleteId=" + input.id)
//       .catch((e) => {
//         return e.response;
//       }) as AxiosPromise<any>;
//   }

//   public static getSingleClientWithImg(input: any) {
//     return Http.axios().get('/Clients/GetClientWithImage?id=' + input.id)
//       .catch((e) => {
//         return e.response;
//       }) as AxiosPromise<any>;
//   }

//   public static addOrUpdateClient(input: any) {
//     var obj = JSON.stringify(input);

//     if (input.id > 0) {
//       url = '/Clients/UpdateClient';
//       return Http.axios().put(url, obj, { headers: { 'Content-Type': 'application/json' } })
//         .then(response => {
//           return response;
//         }).catch((e) => {
//           return e.response;
//         }) as AxiosPromise<any>;
//     }
//     else {
//       var url = '/Clients/AddClient';
//       return Http.axios().post(url, obj, { headers: { 'Content-Type': 'application/json' } })
//         .then(response => {
//           return response;
//         }).catch((e) => {
//           return e.response;
//         }) as AxiosPromise<any>;
//     }
//   }

//   public static updateAvatar(input: any) {
//     var obj = JSON.stringify(input);
//     return Http.axios().post('/Clients/UpdateAvatar', obj, { headers: { 'Content-Type': 'application/json' } })
//       .then(response => {
//         return response;
//       }).catch((e) => {
//         return e.response;
//       }) as AxiosPromise<any>;
//   }
// }
