import * as React from "react";
import Register from "../../account/register";
import "./login.css";

const RegisterComponent = () => {
	return <Register />;
};

export default RegisterComponent;

// class RegisterComponent extends React.Component {
// 	constructor(props) {
// 		super(props);
// 	}

// 	render() {
// 		return <Register />;
// 	}
// }

// export default connect()(RegisterComponent);
