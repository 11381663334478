import { Http } from "../Http";
import { AxiosPromise } from "axios";
import apiCall from "../api/apiCall";

const settingsApi = {
	getSettings: async (input) => {
		const url =
			"UserPreferences/GetUserPreferences?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken;
		const response = await apiCall(url, "get");
		return response;
	},

	saveSettings: async (input) => {
		const obj = JSON.stringify(input);
		const url =
			"UserPreferences/UpdateUserPreference" +
			"?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken;
		const response = await apiCall(url, "put", obj);
		return response;
	},
};

export default settingsApi;

// export class SettingsApi {

// 	public static getSettings(input: any) {
// 		return Http.axios().get('/Settings/GetSettings?userId=' + input.userId + "&userToken=" + input.userToken)
// 			.catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}

// 	public static saveSettings(input: any) {
// 		var obj = JSON.stringify(input);
// 		return Http.axios().post('/Settings/UpdateSettings', obj, { headers: { 'Content-Type': 'application/json' } })
// 			.then(response => {
// 				return response;
// 			}).catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}
// }
