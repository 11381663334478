import * as React from "react";
import { Route, Switch } from "react-router";
import AppFrame from "./components/layout/layout";
import Home from "./components/Home";
import Login from "./components/pages/account/login";
import Landing from "./components/pages/landing/landing";
import ForgotPwd from "./components/pages/account/forgotpwd";
import ResetPwd from "./components/pages/account/resetpwd";
import Register from "./components/pages/account/register";
import SamplePlanQuoteTool from "./components/pages/sampleplanquote/sampleplanquote";
import Site from "./components/pages/site/site";
import Test from "./components/pages/test";
import { clearCache } from "./utils/common/functions";
import packageJson from "../package.json";
//import AuthVerify from "./utils/common/authverify";
import CorrectiveAction from "./components/pages/correctiveaction/correctiveaction";
import Clients from "./components/pages/clients/clients";
import Users from "./components/pages/users/users";
import UserProfile from "./components/pages/userprofile/userProfile";
import Settings from "./components/pages/settings/settings";
import SamplePlan from "./components/pages/sampleplan/sampleplan";
import Archive from "./components/pages/archive/archive";
import TestPoint from "./components/pages/testpoint/testpoint";
import Cookies from "js-cookie";
//import TestHistory from "./components/landing/testhistory";
//import GlobalReport from "./components/landing/globalreport";
//import usersData from "./store/reducers/user";

const App = () => {
	const [state, setState] = React.useState({
		isLoggedIn: true,
		noLogInPage: true,
		samplePlanPage: false,
		showSideBar: true,
		userData: null,
	});

	const logout = () => {
		// window.localStorage.removeItem("AUTHDATA");
		Cookies.remove("userAuthData");
		window.location.href = "/login";
	};

	const parseJwt = (token) => {
		try {
			return JSON.parse(atob(token.split(".")[1]));
		} catch (e) {
			return null;
		}
	};

	React.useEffect(() => {
		const authData = Cookies.get("userAuthData");
		if (authData) {
			setState((prevState) => ({
				...prevState,
				userData: authData,
			}));

			try {
				const decodedJwt = parseJwt(authData.userToken);
				if (decodedJwt.exp * 1000 < Date.now()) {
					logout();
				} else {
					const quoteData = localStorage.getItem("QUOTEDATA");
					if (quoteData != null) {
						setState((prevState) => ({
							...prevState,
							isLoggedIn: true,
							samplePlanPage: true,
							noLogInPage: true,
						}));
					} else {
						const isSamplePlanPage = window.location.href
							.toLowerCase()
							.includes("sampleplanquotetool");
						setState((prevState) => ({
							...prevState,
							isLoggedIn: true,
							samplePlanPage: isSamplePlanPage,
							noLogInPage: !isSamplePlanPage,
						}));
					}
				}
			} catch (e) {
				console.error("Failed to parse JWT:", e);
				// logout();
			}
		} else {
			const isSamplePlanPage = window.location.href
				.toLowerCase()
				.includes("sampleplanquotetool");

			if (isSamplePlanPage) {
				setState((prevState) => ({
					...prevState,
					isLoggedIn: false,
					samplePlanPage: isSamplePlanPage,
					noLogInPage: !isSamplePlanPage,
				}));
			} else {
				// go to login page
				if (window.location.pathname === "/") {
					window.location.href = "./login";
				}
			}
		}
		clearCache(packageJson.version);
	}, []);

	const returnComponent = () => {
		if (state.isLoggedIn) {
			return state.samplePlanPage ? SamplePlanQuoteTool : Landing;
		} else {
			return state.samplePlanPage ? SamplePlanQuoteTool : Login;
		}
	};

	const renderRoutes = () => {
		let user = Cookies.get("userAuthData");
		if (!user) {
			// not logged in
			return (
				<React.Fragment>
					<Route exact path="/login" component={Login} />
					<Route exact path="/register" component={Register} />
					<Route exact path="/forgotpassword" component={ForgotPwd} />
					<Route exact path="/resetpwd/:email/:token" component={ResetPwd} />
					<Route
						exact
						path="/sampleplanquotetool"
						component={SamplePlanQuoteTool}
					/>
				</React.Fragment>
			);
		}

		user = JSON.parse(user);
		// check if the user is an auditor - if so then only render the dashboard
		if (user.roleId === 3) {
			return (
				<React.Fragment>
					<Route exact path={["/", "/landing"]} component={returnComponent()} />
					<Route
						exact
						path={["/userprofile", "/userprofile/:pg"]}
						component={UserProfile}
					/>
					<Route path="/404" component={Home} />
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					<Route exact path={["/", "/landing"]} component={returnComponent()} />
					<Route
						exact
						path={"/correctiveaction"}
						component={CorrectiveAction}
					/>
					<Route exact path="/login" component={Login} />
					<Route exact path="/register" component={Register} />
					<Route exact path="/forgotpassword" component={ForgotPwd} />
					<Route exact path="/resetpwd/:email/:token" component={ResetPwd} />
					<Route
						exact
						path="/sampleplanquotetool"
						component={SamplePlanQuoteTool}
					/>
					<Route exact path="/sites" component={Site} />
					<Route exact path="/settings" component={Settings} />
					<Route exact path="/test" component={Test} />
					<Route exact path="/archive" component={Archive} />
					<Route
						exact
						path={["/clients", "/clients/:id"]}
						component={Clients}
					/>
					<Route
						exact
						path={["/userprofile", "/userprofile/:pg"]}
						component={UserProfile}
					/>
					<Route exact path={["/users", "/users/:id"]} component={Users} />
					<Route exact path="/sampleplans" component={SamplePlan} />
					<Route exact path="/testpoints" component={TestPoint} />
					<Route path="/404" component={Home} />
				</React.Fragment>
			);
		}
	};

	return (
		<React.Fragment>
			<Switch>
				<AppFrame
					isLoggedIn={state.isLoggedIn}
					noLogInPage={state.noLogInPage}
					samplePlanPage={state.samplePlanPage}
				>
					{renderRoutes()}
				</AppFrame>
			</Switch>
		</React.Fragment>
	);
};

// class App extends React.Component {
// 	state = {
// 		isLoggedIn: true,
// 		noLogInPage: true,
// 		samplePlanPage: true,
// 		showSideBar: true,
// 	};

// 	constructor(props) {
// 		super(props);
// 	}

// 	logout = () => {
// 		window.localStorage.removeItem("AUTHDATA");
// 		Cookies.remove("userAuthData");
// 		window.location.href = "/login";
// 	};

// 	parseJwt = (token) => {
// 		try {
// 			return JSON.parse(atob(token.split(".")[1]));
// 		} catch (e) {
// 			return null;
// 		}
// 	};

// 	componentWillMount() {
// 		this.setState({ isLoggedIn: true, samplePlanPage: false });
// 		this.setState({ noLogInPage: true });
// 	}

// 	// commented for now

// 	// componentWillMount() {
// 	// 	const authData = JSON.parse(localStorage.getItem("AUTHDATA"));

// 	// 	if (authData) {
// 	// 		const decodedJwt = JSON.parse(atob(authData.token.split(".")[1]));

// 	// 		if (decodedJwt.exp * 1000 < Date.now()) {
// 	// 			this.logout();
// 	// 		}

// 	// 		const quoteData = localStorage.getItem("QUOTEDATA");
// 	// 		if (quoteData != null) {
// 	// 			this.setState({ isLoggedIn: true, samplePlanPage: true });
// 	// 			this.setState({ noLogInPage: true });
// 	// 		} else {
// 	// 			if (
// 	// 				window.location.href.toLowerCase().indexOf("sampleplanquotetool") > 0
// 	// 			) {
// 	// 				this.setState({ isLoggedIn: true, samplePlanPage: true });
// 	// 			} else {
// 	// 				this.setState({ isLoggedIn: true, samplePlanPage: false });
// 	// 			}
// 	// 		}
// 	// 	} else {
// 	// 		if (
// 	// 			window.location.href.toLowerCase().indexOf("sampleplanquotetool") > 0
// 	// 		) {
// 	// 			this.setState({ isLoggedIn: false, samplePlanPage: true });
// 	// 		} else {
// 	// 			this.setState({ isLoggedIn: false, samplePlanPage: false });
// 	// 		}
// 	// 	}

// 	// 	//if the new version deployed in the server,
// 	// 	//Clear the cleints cache for this application
// 	// 	clearCache(packageJson.version);
// 	// }

// 	//componentDidMount () {
// 	//	const authData = JSON.parse(localStorage.getItem("AUTHDATA"));

// 	//	if (authData) {
// 	//		const decodedJwt = JSON.parse(atob(authData.token.split('.')[1]));

// 	//		if (decodedJwt.exp * 1000 < Date.now()) {
// 	//			this.logout();
// 	//		}
// 	//	}
// 	//}

// 	// moved into method - nested turnary.... 🤢
// 	selectLandingPage = () => {
// 		let component = null;
// 		if (this.state.isLoggedIn) {
// 			if (this.state.samplePlanPage) {
// 				component = SamplePlanQuoteTool;
// 			} else {
// 				component = Landing;
// 			}
// 		} else {
// 			if (this.state.samplePlanPage) {
// 				component = SamplePlanQuoteTool;
// 			} else {
// 				component = Login;
// 			}
// 		}
// 		return component;
// 	};

// 	render() {
// 		return (
// 			<React.Fragment>
// 				<Switch>
// 					<AppFrame
// 						isLoggedIn={this.state.isLoggedIn}
// 						noLogInPage={this.state.noLogInPage}
// 						samplePlanPage={this.state.samplePlanPage}
// 					>
// 						<Route
// 							exact
// 							path={["/", "/landing"]}
// 							// component={
// 							// 	this.state.isLoggedIn
// 							// 		? this.state.samplePlanPage
// 							// 			? SamplePlanQuoteTool
// 							// 			: Landing
// 							// 		: this.state.samplePlanPage
// 							// 		? SamplePlanQuoteTool
// 							// 		: Login
// 							// }
// 							component={this.selectLandingPage()}
// 						/>
// 						<Route
// 							exact
// 							path={"/correctiveaction"}
// 							component={CorrectiveAction}
// 						/>
// 						<Route exact path="/login" component={Login} />
// 						<Route exact path="/register" component={Register} />
// 						<Route exact path="/forgotpassword" component={ForgotPwd} />
// 						<Route exact path="/resetpwd/:email/:token" component={ResetPwd} />
// 						<Route
// 							exact
// 							path="/sampleplanquotetool"
// 							component={SamplePlanQuoteTool}
// 						/>
// 						<Route exact path="/sites" component={Site} />
// 						<Route exact path="/settings" component={Settings} />
// 						<Route exact path="/test" component={Test} />
// 						<Route
// 							exact
// 							path={["/clients", "/clients/:id"]}
// 							component={Clients}
// 						/>
// 						<Route
// 							exact
// 							path={["/userprofile", "/userprofile/:pg"]}
// 							component={UserProfile}
// 						/>
// 						<Route exact path={["/users", "/users/:id"]} component={Users} />
// 						<Route exact path="/sampleplans" component={SamplePlan} />
// 						<Route exact path="/testpoints" component={TestPoint} />
// 						<Route path="/404" component={Home} />
// 					</AppFrame>
// 				</Switch>
// 			</React.Fragment>
// 		);
// 	}
// }
//
// React.useEffect(() => {
// 	setState({ isLoggedIn: true, samplePlanPage: false });
// 	setState({ noLogInPage: true });
// }, []);
//
// React.useEffect(() => {
// 	// const authData = JSON.parse(localStorage.getItem("AUTHDATA"));
// 	const authData = Cookies.get("userAuthData");
// 	console.log("App auth data ", authData);
//
// 	if (authData) {
// 		// const decodedJwt = JSON.parse(atob(authData.token.split(".")[1]));
// 		console.log("token ", authData.userToken);
// 		const decodedJwt = JSON.parse(atob(authData.userToken.split(".")[1]));
// 		console.log("auth data exists ", authData);
//
// 		if (decodedJwt.exp * 1000 < Date.now()) {
// 			logout();
// 		}
//
// 		const quoteData = localStorage.getItem("QUOTEDATA");
// 		if (quoteData != null) {
// 			setState({ isLoggedIn: true, samplePlanPage: true });
// 			setState({ noLogInPage: true });
// 		} else {
// 			if (
// 				window.location.href.toLowerCase().indexOf("sampleplanquotetool") > 0
// 			) {
// 				setState({ isLoggedIn: true, samplePlanPage: true });
// 			} else {
// 				setState({ isLoggedIn: true, samplePlanPage: false });
// 			}
// 		}
// 	} else {
// 		if (
// 			window.location.href.toLowerCase().indexOf("sampleplanquotetool") > 0
// 		) {
// 			setState({ isLoggedIn: false, samplePlanPage: true });
// 		} else {
// 			setState({ isLoggedIn: false, samplePlanPage: false });
// 		}
// 	}
//
// 	clearCache(packageJson.version);
// }, []);

// const returnComponent = () => {
// 	if (state.isLoggedIn) {
// 		if (state.samplePlanPage) {
// 			return SamplePlanQuoteTool;
// 		} else {
// 			return Landing;
// 		}
// 	} else {
// 		if (state.samplePlanPage) {
// 			return SamplePlanQuoteTool;
// 		} else {
// 			return Login;
// 		}
// 	}
// };

export default App;
