import React, { useState } from "react";
import Settings from "../../settings/settings";
import FCMessageDialog from "../../../utils/library/dialog";

const SettingsPage = (props) => {
	const [state, setState] = useState({
		showAlert: props.showAlert,
		isSuccessAlert: props.isSuccessAlert,
		alertMessage: "",
	});

	const closeDialog = () => {
		setState({ ...state, showAlert: false });
	};

	return (
		<>
			{state.showAlert && (
				<FCMessageDialog
					isSuccess={state.isSuccessAlert}
					modalCallBack={closeDialog}
					message={state.alertMessage}
				/>
			)}
			<div className="px-20">
				<Settings seeAlert={state.seeAlert} />
			</div>
		</>
	);
};

export default SettingsPage;

// class SettingsComponent extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			showAlert: false,
// 			isSuccessAlert: false,
// 			alertMessage: "",
// 		};
// 		this.seeAlert = this.seeAlert.bind(this);
// 	}

// 	seeAlert(isShow, isSuccess, message) {
// 		this.setState({
// 			showAlert: isShow,
// 			isSuccessAlert: isSuccess,
// 			alertMessage: message,
// 		});
// 	}

// 	closeDialog = () => {
// 		this.setState({ showAlert: false });
// 	};

// 	componentDidMount() {}

// 	render() {
// 		const { showAlert, isSuccessAlert, alertMessage } = this.state;
// 		return (
// 			<>
// 				{showAlert && (
// 					<FCMessageDialog
// 						isSuccess={isSuccessAlert}
// 						modalCallBack={() => this.closeDialog()}
// 						message={alertMessage}
// 					/>
// 				)}
// 				<div className="px-20">
// 					<Settings seeAlert={this.seeAlert} />
// 				</div>
// 			</>
// 		);
// 	}
// }

// export default connect()(SettingsComponent);
