import * as React from "react";
import UserProfile from "../../userprofile/userprofile";
import ChangePwd from "../../userprofile/changepassword";
import FCMessageDialog from "../../../utils/library/dialog";

const UserProfilePage = (props) => {
	const [state, setState] = React.useState({
		showAlert: props.showAlert,
		isSuccessAlert: props.isSuccessAlert,
		alertMessage: "",
		isChangePwd: props.isChangePwd,
	});

	const initialState = {
		isError: false,
		isUserLoaded: false,
		isUserSaved: false,
		isPasswordChanged: false,
		isAvatarUpdated: false,
		userInput: {
			userId: -1,
			userToken: "",
		},
		// added these two fields
		userResult: null,
	};

	const initialSelectedUserState = {
		newPassword: "",
		confirmPassword: "",
	};

	const [showChangePwd, setShowChangePwd] = React.useState(false);
	const [showChangeProfile, setShowChangeProfile] = React.useState(false);

	const [userProfileData, setUserProfileData] = React.useState(initialState);
	const [selectedUser, setSelectedUser] = React.useState(
		initialSelectedUserState,
	);

	const closeDialog = () => {
		setState({ ...state, showAlert: false });
	};

	// todo - remove using window.location.href.toLowerCase().indexOf(props.match.params.pg)
	// React.useEffect(() => {
	// 	if (
	// 		// TODO - check this
	// 		// props.match.params.pg &&
	// 		window.location.href.toLowerCase().indexOf(props.match.params.pg)
	// 	) {
	// 		setState({ ...state, isChangePwd: true });
	// 	} else {
	// 		setState({ ...state, isChangePwd: false });
	// 	}
	// }, []);

	return (
		<>
			{state.showAlert && (
				<FCMessageDialog
					isSuccess={state.isSuccessAlert}
					modalCallBack={() => closeDialog()}
					message={state.alertMessage}
				/>
			)}
			<div className="px-20">
				{/* {!state.isChangePwd && <UserProfile seeAlert={state.seeAlert} />}
				{state.isChangePwd && <ChangePwd seeAlert={state.seeAlert} />} */}
				{!showChangePwd && (
					<UserProfile
						seeAlert={state.seeAlert}
						setShowChangePwd={setShowChangePwd}
						userProfileData={userProfileData}
						setUserProfileData={setUserProfileData}
						selectedUser={selectedUser}
						setSelectedUser={setSelectedUser}
					/>
				)}
				{showChangePwd && (
					<ChangePwd
						seeAlert={state.seeAlert}
						setShowChangePwd={setShowChangePwd}
						userProfileData={userProfileData}
						setUserProfileData={setUserProfileData}
						selectedUser={selectedUser}
						setSelectedUser={setSelectedUser}
					/>
				)}
			</div>
		</>
	);
};

export default UserProfilePage;

// class UserProfileComponent extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			showAlert: false,
// 			isSuccessAlert: false,
// 			alertMessage: "",
// 			isChangePwd: false,
// 		};
// 		this.seeAlert = this.seeAlert.bind(this);
// 	}

// 	seeAlert(isShow, isSuccess, message) {
// 		this.setState({
// 			showAlert: isShow,
// 			isSuccessAlert: isSuccess,
// 			alertMessage: message,
// 		});
// 	}

// 	closeDialog = () => {
// 		this.setState({ showAlert: false });
// 	};

// 	componentDidMount() {
// 		if (
// 			this.props.match.params.pg &&
// 			window.location.href.toLowerCase().indexOf(this.props.match.params.pg)
// 		) {
// 			this.setState({ isChangePwd: true });
// 		} else {
// 			this.setState({ isChangePwd: false });
// 		}
// 	}

// 	render() {
// 		const { showAlert, isSuccessAlert, alertMessage } = this.state;
// 		return (
// 			<>
// 				{showAlert && (
// 					<FCMessageDialog
// 						isSuccess={isSuccessAlert}
// 						modalCallBack={() => this.closeDialog()}
// 						message={alertMessage}
// 					/>
// 				)}
// 				<div className="px-20">
// 					{!this.state.isChangePwd && <UserProfile seeAlert={this.seeAlert} />}
// 					{this.state.isChangePwd && <ChangePwd seeAlert={this.seeAlert} />}
// 				</div>
// 			</>
// 		);
// 	}
// }

// export default connect()(UserProfileComponent);
