import * as React from "react";
import { useState, useRef } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../custom.css";
import { getUserData } from "../../utils/common/functions";

import dashboardApi from "../../utils/api/dashboardApi";
import siteApi from "../../utils/api/siteApi";
// import { getUserRoles } from "../../store/sagas/user";
import samplePlanApi from "../../utils/api/sampleplanApi";
import testPointApi from "../../utils/api/testpointApi";
import userApi from "../../utils/api/userApi";

const GlobalReport = () => {
	const initialState = {
		isLoading: false,
		isError: false,
		isAllReportLoaded: false,
		isTestHistoryLoaded: false,
		allReportData: [],
		dashboardInput: {},
		isGlobalComparisonLoaded: false,
		globalComparisonData: [],
		isDashboardDataLoaded: false,
		graphData: {},
		isDownloadReportLoaded: false,
		downloadReportData: [],
		isDownloadDataLoaded: false,
		downloadData: [],
		testHistoryData: [],
	};

	const initialSiteDataState = {
		userId: 0,
		usertoken: "1234",
		isError: false,
		isSaved: false,
		isSiteLoaded: false,
		isSitesLoaded: false,
		isAllSitesLoaded: false,
		isSiteSaved: false,
		isSiteDeleted: false,
		sites: null,
		site: null,
	};

	const initialUsersDataState = {
		isLoading: false,
		isError: false,
		isUsersLoaded: false,
		isUserDeleted: false,
		deleteResult: false,
		userInput: {
			userId: -1,
			userToken: "",
			clientId: -1,
		},
		usersResult: [
			{
				id: -1,
				name: "",
			},
		],
		userRolesResult: [
			{
				id: -1,
				name: "",
			},
		],
		user: null,
		isUserLoaded: false,
		isUserAdded: false,
		userAddResult: false,
		isAvatarUpdated: false,
		isUserRolesLoaded: false,
	};

	const initialSamplePlanState = {
		userId: 0,
		usertoken: "",
		isSaved: false,
		isLive: false,
		isError: false,
		isSamplePlansLoaded: false,
		isTestPointsLoaded: false,
		isPlanSaved: false,
		isPlanDeleted: false,
		samplePlanInput: null,
		samplePlans: null,
		testPoints: null,
		selectedSamplePlanId: 0,
		selectedSamplePlan: null,
	};

	const [dateRange1, setDateRange1] = useState([null, null]);
	const [startDate1, endDate1] = dateRange1;
	const [dateRange2, setDateRange2] = useState([null, null]);
	const [startDate2, endDate2] = dateRange2;
	const [userData, setUserData] = useState(null);
	const [isGlobalComparisonLoaded, setIsGlobalComparisonLoaded] =
		useState(false);
	const [pieOptions1, setPieOptions1] = useState(null);
	const [pieOptions2, setPieOptions2] = useState(null);
	const [graphNo, setGraphNo] = useState(0);

	const [dashboardData, setDashboardData] = useState(initialState);
	const [error, setErrror] = useState(false);

	const [siteData, setSiteData] = useState(initialSiteDataState);
	const [usersData, setUsersData] = useState(initialUsersDataState);
	const [sampleplanData, setSamplePlanData] = useState(initialSamplePlanState);

	//const [graphData1, setGraphData1] = useState(null);
	//const [graphData2, setGraphData2] = useState(null);
	// const pieChartComponentRef1 = useRef < HighchartsReact.RefObject > null;
	// const pieChartComponentRef2 = useRef < HighchartsReact.RefObject > null;

	const pieChartComponentRef1 = React.useRef(null);
	const pieChartComponentRef2 = React.useRef(null);

	// convert refs to use state with callbacks - cant use refs in functional components
	const [chart1, setChart1] = useState(null);
	const [chart2, setChart2] = useState(null);

	const handleChartCallback1 = React.useCallback((chart) => {
		pieChartComponentRef1.current = chart;
		setChart1(chart);
	}, []);

	const handleChartCallback2 = React.useCallback((chart) => {
		pieChartComponentRef2.current = chart;
		setChart2(chart);
	}, []);

	// api calls
	// get site data
	const getSiteData = async () => {
		const response = await siteApi.getAllSites({
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
		});
		if (response.status === 200) {
			setSiteData({
				...siteData,
				isSitesLoaded: true,
				sites: response.data,
			});
		} else {
			setErrror(response.statusText);
		}
	};

	// get users data
	const getUsersData = async () => {
		const response = await userApi.getAllUsers({
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
		});
		if (response.status === 200) {
			setUsersData({
				...usersData,
				isUsersLoaded: true,
				usersResult: response.data,
			});
		} else {
			setErrror(response.statusText);
		}
	};

	const getSingleUserWithImg = async (userId) => {
		const response = await userApi.getSingleUserWithImg({
			userId: userData.id,
			userToken: userData.userToken,
			id: userId,
		});
		if (response.status === 200) {
			setUsersData({
				...usersData,
				isUserLoaded: true,
				user: response.data,
			});
		} else {
			setErrror(response.statusText);
		}
	};

	const getUserRoles = async () => {
		const response = await userApi.getUserRoles({
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
		});
		if (response.status === 200) {
			setUsersData({
				...usersData,
				isUserRolesLoaded: true,
				userRolesResult: response.data,
			});
		} else {
			setErrror(response.statusText);
		}
	};

	// get sample plan data
	const getSamplePlanData = async () => {
		const response = await samplePlanApi.getSamplePlans({
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
		});
		if (response.status === 200) {
			setSamplePlanData({
				...sampleplanData,
				isSamplePlansLoaded: true,
				samplePlans: response.data,
			});
		} else {
			setErrror(response.statusText);
		}
	};

	const getTestPoints = async (samplePlanId) => {
		const response = await testPointApi.getAllTestPoint({
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
			samplePlanId: samplePlanId,
		});
		if (response.status === 200) {
			setSamplePlanData({
				...sampleplanData,
				isTestPointsLoaded: true,
				testPoints: response.data,
			});
		} else {
			setErrror(response.statusText);
		}
	};

	const refreshGraph1 = (data) => {
		//Pie Chart
		setPieOptions1({
			title: {
				text: "",
			},
			dataLabels: {
				enabled: true,
			},
			colors: ["#26a4ca", "#ffcc00", "#f70000"],
			series: [
				{
					type: "pie",
					dataLabels: {
						enabled: false,
					},
					showInLegend: true,
					data: [
						{ name: "Pass", y: data?.pass },
						{ name: "Warning", y: data?.warning },
						{ name: "Fail", y: data?.fail },
					],
				},
			],
		});
	};

	const refreshGraph2 = (data) => {
		//Pie Chart
		setPieOptions2({
			title: {
				text: "",
			},
			dataLabels: {
				enabled: true,
			},
			colors: ["#26a4ca", "#ffcc00", "#f70000"],
			series: [
				{
					type: "pie",
					dataLabels: {
						enabled: false,
					},
					showInLegend: true,
					data: [
						{ name: "Pass", y: data?.pass },
						{ name: "Warning", y: data?.warning },
						{ name: "Fail", y: data?.fail },
					],
				},
			],
		});
	};

	React.useEffect(() => {
		var usrData = getUserData();
		setUserData(usrData);

		setDateRange1([
			new Date(`${moment().format("LL")} 00:00:00`),
			new Date(`${moment().add(7, "days").format("LL")} 23:59:59`),
		]);
		setDateRange2([
			new Date(`${moment().format("LL")} 00:00:00`),
			new Date(`${moment().add(7, "days").format("LL")} 23:59:59`),
		]);

		if (usrData.clientId !== 1 && usrData.roleId !== 1) {
			//GetSitesByClient
			//dispatch(getGlobalComparisonData({ userId: usrData.id, userToken: usrData.userToken, clientId: usrData.clientId }));
		}

		refreshGraph1({ pass: 0, warning: 0, fail: 0 });
		refreshGraph2({ pass: 0, warning: 0, fail: 0 });

		if (userData) {
			getSiteData();
			getUsersData();
			getSingleUserWithImg();
			getUserRoles();
			getSamplePlanData();
			getTestPoints();
		}
	}, []);

	React.useEffect(() => {
		if (dashboardData.isGlobalComparisonLoaded) {
			setIsGlobalComparisonLoaded(true);
			if (graphNo === 1) {
				refreshGraph1(dashboardData.globalComparisonData);
			} else {
				refreshGraph2(dashboardData.globalComparisonData);
			}
		}
	}, [dashboardData, graphNo]);

	const getGraphData = async (graphNo) => {
		setGraphNo(graphNo);
		setIsGlobalComparisonLoaded(false);
		const response = await dashboardApi.getGlobalComparisonData({
			userId: userData.id,
			userToken: userData.userToken,
			dateFrom:
				graphNo === 1
					? moment(startDate1).format("YYYY-MM-DD")
					: moment(startDate2).format("YYYY-MM-DD"),
			dateTo:
				graphNo === 1
					? moment(endDate1).format("YYYY-MM-DD")
					: moment(endDate2).format("YYYY-MM-DD"),
		});

		if (response.status === 200) {
			setDashboardData({
				...dashboardData,
				isGlobalComparisonLoaded: true,
				globalComparisonData: response.data,
			});

			// Immediately update the graph
			if (graphNo === 1) {
				refreshGraph1(response.data);
			} else {
				refreshGraph2(response.data);
			}
		} else {
			setErrror(response.statusText);
		}
	};

	const graphDataChange = () => {
		if (dashboardData) {
			// if (dashboardData.isGlobalComparisonLoaded && !isGlobalComparisonLoaded) {
			dashboardData.isDashboardDataLoaded = false;
			setIsGlobalComparisonLoaded(true);
			//graphNo === 1 ? setGraphData1(dashboardData.graphData) : setGraphData2(dashboardData.graphData);
			graphNo === 1
				? refreshGraph1(dashboardData.globalComparisonData)
				: refreshGraph2(dashboardData.globalComparisonData);
			//
		}
	};

	React.useEffect(() => {
		graphDataChange();
	}, []);

	const onDateChange1 = (dates) => {
		setDateRange1([
			dates[0]
				? new Date(`${moment(dates[0]).format("LL")} 00:00:00`)
				: dates[0],
			dates[1]
				? new Date(`${moment(dates[1]).format("LL")} 23:59:59`)
				: dates[1],
		]);
	};

	const onDateChange2 = (dates) => {
		setDateRange2([
			dates[0]
				? new Date(`${moment(dates[0]).format("LL")} 00:00:00`)
				: dates[0],
			dates[1]
				? new Date(`${moment(dates[1]).format("LL")} 23:59:59`)
				: dates[1],
		]);
	};

	const handleCalendarClose = (value) => {
		getGraphData(value);
	};
	const handlePopup = (event) => {
		event.preventDefault();
		alert("popup");
	};

	return (
		<>
			<div className="max-w-7xl mt-10">
				<div className="grid mt-10">
					<div className="grid grid-cols-2 h-80 mt-10">
						<div className="flex flex-inline justify-center">
							<div className="w-3/4">
								<div className="w-full">
									<div className="pl-1 text-fclabel">Select Date Range</div>
									<DatePicker
										dateFormat={"dd/MM/yyyy"}
										selectsRange={true}
										startDate={startDate1}
										endDate={endDate1}
										onChange={(value) => onDateChange1(value)}
										onCalendarClose={() => handleCalendarClose(1)}
										className="border-2 border-gray-300 p-2 rounded-2xl ml-1 blueText w-full"
									/>
								</div>
								<div className="w-full">
									<HighchartsReact
										containerProps={{ style: { height: "100%" } }}
										highcharts={Highcharts}
										options={pieOptions1}
										callback={handleChartCallback1}
									/>
								</div>
							</div>
						</div>
						<div className="flex flex-inline justify-center">
							<div className="w-3/4">
								<div className="w-full">
									<div className="pl-1 text-fclabel">Select Date Range</div>
									<DatePicker
										dateFormat={"dd/MM/yyyy"}
										selectsRange={true}
										startDate={startDate2}
										endDate={endDate2}
										onCalendarClose={() => handleCalendarClose(2)}
										onChange={(value) => onDateChange2(value)}
										className="border-2 border-gray-300 p-2 rounded-2xl ml-1 blueText w-full"
									/>
								</div>
								<div className="w-full">
									<HighchartsReact
										containerProps={{ style: { height: "100%" } }}
										highcharts={Highcharts}
										options={pieOptions2}
										callback={handleChartCallback2}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default GlobalReport;
