import * as React from "react";
import { useState } from "react";
import FCProfileInputField from "../../utils/library/profiletextbox";
import FCButtonWithClick from "../../utils/library/buttonwithclick";
import { Validators, validateInput } from "../../utils/library/Validator";
import "./userprofile.css";
import { getUserData, addDefaultSrc } from "../../utils/common/functions";

import userApi from "../../utils/api/userApi";
import { showSweetAlert } from "../Shared/CustomAlert";

const ChangePwd = ({
	seeAlert,
	setShowChangePwd,
	userProfileData,
	setUserProfileData,
	selectedUser,
	setSelectedUser,
}) => {
	const validators = [
		{
			check: Validators.required,
			message: "All fields are required.",
		},
	];

	const [isPwdChanged, setIsPwdChanged] = useState(false);
	const [isCurrentPwdEntered, setIsCurrentPwdEntered] = useState(false);
	const [isNewPwdEntered, setIsNewPwdEntered] = useState(false);
	const [isCnfmPwdEntered, setIsCnfmPwdEntered] = useState(false);

	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [userData, setUserData] = useState(null);

	const savePassword = async () => {
		// get user data
		const usrData = getUserData();
		setErrorMessage("");
		// seeAlert(false, false, "");
		setIsCurrentPwdEntered(
			validateInput(validators, selectedUser.currentPassword),
		);
		setIsNewPwdEntered(validateInput(validators, selectedUser.newPassword));
		setIsCnfmPwdEntered(
			validateInput(validators, selectedUser.confirmPassword),
		);

		if (validateNullOrUndefined()) {
			setIsError(false);
			selectedUser.userId = userData.id;
			const response = await userApi.changeUserPassword({
				userId: usrData.id,
				userToken: usrData.userToken,
				changeUserId: usrData.id,
				newPassword: selectedUser.newPassword,
			});
			if (response.status === 200) {
				showSweetAlert(
					"saveSuccessCustomMessage",
					"Password changed successfully",
				);
			}
			if (response.status !== 200) {
				setErrorMessage(response.statusText);
				setIsError(true);
			}
		} else {
			setIsError(true);
		}
		setShowChangePwd(false);
		setIsPwdChanged(false);
	};

	const onCancel = () => {
		setShowChangePwd(false);
	};

	const onInputChange = (event) => {
		if (event.name === "currentPassword") {
			setIsCurrentPwdEntered(validateInput(validators, event.value));
		}
		if (event.name === "newPassword") {
			setIsNewPwdEntered(validateInput(validators, event.value));
		}
		if (event.name === "cnfPassword") {
			setIsCnfmPwdEntered(validateInput(validators, event.value));
		}
		setSelectedUser((prevState) => ({
			...prevState,
			[event.name]: event.value,
		}));
	};

	const validateNullOrUndefined = () => {
		if (
			typeof selectedUser.currentPassword !== "undefined" &&
			selectedUser.currentPassword &&
			typeof selectedUser.newPassword !== "undefined" &&
			selectedUser.newPassword &&
			typeof selectedUser.confirmPassword !== "undefined" &&
			selectedUser.confirmPassword
		) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<React.Fragment>
			<div className="py-3">
				<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
					<h1 className="text-1xl  text-gray-500 text-center">My Account</h1>
				</div>
				<div className="mt-12 w-1/2 text-lg  text-gray-600 text-center mx-auto">
					<img
						className="inline-block object-cover h-16 w-16 rounded-full"
						src={userData != null ? selectedUser?.imageUrl : ""}
						alt=""
						onError={addDefaultSrc}
					/>
				</div>
				<div className="mt-16 w-full  mb-4 text-gray-500">
					<div className="py-4 w-1/3 overflow-hidden h-1/2 mx-auto">
						<div className="mb-3 mx-auto">
							<FCProfileInputField
								required={true}
								id="currentPassword"
								type="password"
								onChange={(event) => onInputChange(event.target)}
								placeholder="Current Password"
								value={selectedUser?.currentPassword}
								isError={isCurrentPwdEntered}
								icon="pwd"
							/>
						</div>
						<div className="mb-3  mx-auto">
							<FCProfileInputField
								required={true}
								id="newPassword"
								type="password"
								onChange={(event) => onInputChange(event.target)}
								placeholder="New Password"
								value={selectedUser?.newPassword}
								isError={isNewPwdEntered}
								icon="pwd"
							/>
						</div>
						<div className="mb-3  mx-auto">
							<FCProfileInputField
								required={true}
								id="confirmPassword"
								type="password"
								onChange={(event) => onInputChange(event.target)}
								placeholder="Confirm Password"
								value={selectedUser?.confirmPassword}
								isError={isCnfmPwdEntered}
								icon="pwd"
							/>
						</div>
						<div className="mt-16 mb-3 flex mx-auto">
							<FCButtonWithClick
								onHandleClick={onCancel}
								bgColor="bg-fcbutton mr-3"
								type="button"
								icon=""
								value="Cancel"
							/>
							<FCButtonWithClick
								onHandleClick={savePassword}
								bgColor="bg-primary"
								type="button"
								icon=""
								value="Save"
							/>
						</div>
						<div className="text-red-500  text-center">
							{isError && !errorMessage && (
								<span>Please enter the required fields</span>
							)}
							{isError && errorMessage && <span>{errorMessage}</span>}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ChangePwd;

// const mapStateToProps = (state) => {
// 	const { userProfileData } = state;
// 	return {
// 		userProfileData,
// 	};
// };

// export default connect(mapStateToProps)(ChangePwd);
