import * as React from "react";
import SamplePlanQuoteTool from "../../sampleplanquote/sampleplanquote";
import "./sampleplanquote.css";

const SamplePlanQuoteToolComponent = () => {
	return (
		<div className="px-20">
			<SamplePlanQuoteTool />
		</div>
	);
};

export default SamplePlanQuoteToolComponent;

// class SamplePlanQuoteToolComponent extends React.Component {
// 	state = {};
// 	constructor(props) {
// 		super(props);

// 		this.state = {};
// 	}

// 	componentDidMount() {}

// 	render() {
// 		const {} = this.state;
// 		return (
// 			<div className="px-20">
// 				<SampePlanQuoteTool />
// 			</div>
// 		);
// 	}
// }

// export default connect()(SamplePlanQuoteToolComponent);
