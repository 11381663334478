import React, { useState, useEffect } from "react";
import SideBar from "./sidebar";
import "../../custom.css";
import Cookies from "js-cookie";

const AppFrame = (props) => {
	const [userAuthData, setUserAuthData] = useState(null);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isSideBarOpen, setIsSideBarOpen] = useState(false);
	const [noLogInPage, setNoLogInPage] = useState(false);
	const [samplePlanPage, setSamplePlanPage] = useState(false);

	// Replace componentWillMount with useEffect to initialize state
	useEffect(() => {
		setIsLoggedIn(props.isLoggedIn);
		setNoLogInPage(props.noLogInPage);
		setSamplePlanPage(props.samplePlanPage);
	}, [props.isLoggedIn, props.noLogInPage, props.samplePlanPage]);

	// Replace componentDidMount with the necessary logic
	useEffect(() => {
		// const authData = JSON.parse(window.localStorage.getItem("AUTHDATA"));
		let authData = Cookies.get("userAuthData");

		if (authData) {
			try {
				const parsedAuthData = JSON.parse(authData);

				const decodedJwt = JSON.parse(
					atob(parsedAuthData.userToken.split(".")[1]),
				);
				if (decodedJwt.exp * 1000 < Date.now()) {
					Cookies.remove("userAuthData");
					setIsLoggedIn(false);
					window.location.href = "/login";
					// callback(false);
				} else {
					setIsLoggedIn(true);
				}
			} catch (e) {
				console.error("Failed to decode JWT:", e);
				// callback(false);
			}
		} else {
			setIsLoggedIn(false);
		}
	}, []);

	// todo - this callback is causing the app to logout
	const callback = (value) => {
		if (!value) {
			// window.localStorage.removeItem("AUTHDATA");
			window.localStorage.removeItem("QUOTEDATA");
			Cookies.remove("userAuthData");
			setIsLoggedIn(false);
			// TODO : logout
			window.location.href = "/login";
		}
	};

	return (
		<React.Fragment>
			{!isLoggedIn && (
				<div className="bg-white">
					<main>{props.children}</main>
				</div>
			)}
			{isLoggedIn && samplePlanPage && (
				<SideBar
					isLogged={isLoggedIn}
					callback={callback}
					isOpenSideBar={isSideBarOpen}
				>
					{props.children}
				</SideBar>
			)}
			{isLoggedIn && !samplePlanPage && (
				<SideBar
					isLogged={isLoggedIn}
					callback={callback}
					isOpenSideBar={isSideBarOpen}
				>
					{props.children}
				</SideBar>
			)}
		</React.Fragment>
	);
};

// todo - old class based - to be removed
// class AppFrame extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			userName: "",
// 			isLoggedIn: false,
// 			isSideBarOpen: false,
// 			noLogInPage: false,
// 			samplePlanPage: false,
// 		};
// 		this.callback = this.callback.bind(this);
// 	}
//
// 	componentDidMount() {
// 		//alert(this.props.isLoggedIn + "==" + this.props.noLogInPage);
// 	}
//
// 	componentWillMount() {
// 		this.setState({
// 			isLoggedIn: this.props.isLoggedIn,
// 			noLogInPage: this.props.noLogInPage,
// 			samplePlanPage: this.props.samplePlanPage,
// 		});
// 	}
//
// 	callback(value) {
// 		if (!value) {
// 			// window.localStorage.removeItem("AUTHDATA");
// 			window.localStorage.removeItem("QUOTEDATA");
// 			Cookies.remove("userAuthData");
//
// 			this.setState({
// 				isLoggedIn: false,
// 			});
// 			//TODO : logout
// 			window.location.href = "/login";
// 		}
// 	}
//
// 	render() {
// 		const { children, isLoggedIn } = this.props;
//
// 		return (
// 			<React.Fragment>
// 				{!this.state.isLoggedIn && (
// 					<div className="bg-white">
// 						<main>{children}</main>
// 					</div>
// 				)}
// 				{this.state.isLoggedIn && this.state.samplePlanPage && (
// 					<SideBar
// 						isLogged={this.state.isLoggedIn}
// 						callback={this.callback}
// 						isOpenSideBar={this.state.isSideBarOpen}
// 						children={children}
// 					/>
// 				)}
// 				{this.state.isLoggedIn && !this.state.samplePlanPage && (
// 					<SideBar
// 						isLogged={this.state.isLoggedIn}
// 						callback={this.callback}
// 						isOpenSideBar={this.state.isSideBarOpen}
// 						children={children}
// 					/>
// 				)}
// 			</React.Fragment>
// 		);
// 	}
// }

export default AppFrame;
