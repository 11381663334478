import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
import CorrectiveAction from "../../correctiveaction/correctiveaction";
import { getUserData } from "../../../utils/common/functions";

const CorrectiveActionPage = () => {
	// const [state, setState] = useState({
	// 	isLogout: false,
	// });
	const [userAuthData, setUserAuthData] = useState();

	useEffect(() => {
		let usrData = getUserData();
		if (usrData) setUserAuthData(usrData);
	}, []);

	// need to check if userAuthData is null and rerun a useEffect to update if so

	return (
		<div className="px-20">
			{userAuthData?.roleId === 1 && userAuthData?.clientId === 1 ? (
				<CorrectiveAction />
			) : (
				<h2 className="text-center mt-5">
					You do not have access to view this page
				</h2>
			)}
		</div>
	);
};

export default CorrectiveActionPage;

// class CorrectiveActionComponent extends React.Component {
// 	state = { isLogout: false, user: user };
// 	constructor(props) {
// 		super(props);
// 	}

// 	componentDidMount() {
// 		let usrData = getUserData();

// 		if (this.props.usersData?.usersResult.length > 0) {
// 			let usr = this.props.usersData?.usersResult.find(
// 				(item) => item.id === usrData.id
// 			);
// 			if (usr) this.setState({ user: usr });
// 		}
// 	}

// 	componentDidUpdate(prevProps, prevState) {
// 		let usrData = getUserData();

// 		if (
// 			prevProps.usersData?.usersResult !== this.props.usersData?.usersResult
// 		) {
// 			if (this.props.usersData?.usersResult.length > 0) {
// 				let usr = this.props.usersData?.usersResult.find(
// 					(item) => item.id === usrData.id
// 				);
// 				if (usr) this.setState({ user: usr });
// 			}
// 		}
// 	}

// 	render() {
// 		return (
// 			<div className="px-20">
// 				{this.state.user?.roleId === 1 && this.state.user?.clientId === 1 ? (
// 					<CorrectiveAction />
// 				) : (
// 					<h2 className="text-center mt-5">
// 						You do not have access to view this page
// 					</h2>
// 				)}
// 			</div>
// 		);
// 	}
// }

// const mapStateToProps = (state) => {
// 	const { usersData } = state;
// 	return {
// 		usersData,
// 	};
// };

// export default connect(mapStateToProps)(CorrectiveActionComponent);
