import React, { useState, useEffect } from "react";
import SamplePlan from "../../sampleplan/sampleplan";
import CreatePlan from "../../sampleplan/createplan";
import "./sampleplan.css";
import FCMessageDialog from "../../../utils/library/dialog";
import { getUserData } from "../../../utils/common/functions";
import siteApi from "../../../utils/api/siteApi";

const SamplePlanPage = () => {
	const initialState = {
		showAlert: false,
		isSuccessAlert: false,
		alertMessage: "",
		showConfirm: false,
		title: "",
		currentPage: "SamplePlan",
		templateId: -1,
		sites: [],
		// need to set user
		user: null,
	};

	const [showAlert, setShowAlert] = useState(false);
	const [isSuccessAlert, setIsSuccessAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	// const [currentPage, setCurrentPage] = useState();
	const [user, setUser] = useState(null);

	const [state, setState] = useState(initialState);

	useEffect(() => {
		let usrData = getUserData();
		if (usrData) {
			// setState({ ...state, user: usrData });
			setUser(usrData);
			getSites(usrData);
		}
	}, []);

	const getSites = async (input) => {
		const response = await siteApi.getAllSites(input);
		setState({ ...state, sites: response.data });
		return response;
	};

	const changePage = (value, templateId, sites) => {
		if (value) {
			// this.setState({
			// 	currentPage: "CreatePlan",
			// 	templateId: templateId,
			// 	sites: sites,
			// });
			setState({ ...state, currentPage: "CreatePlan" });
		} else {
			setState({ ...state, currentPage: "SamplePlan" });
		}
	};

	const initialSamplePlanState = {
		userId: 0,
		usertoken: "",
		isSaved: false,
		isLive: false,
		isError: false,
		isSamplePlansLoaded: false,
		isTestPointsLoaded: false,
		isPlanSaved: false,
		isPlanDeleted: false,
		// will need to set this
		// samplePlanInput: samplePlanModel,
		samplePlanInput: null,
		// will need to set this
		// samplePlans: [initialSamplePlanModel],
		samplePlans: [],
		// will need to set this
		// testPoints: [testpoint],
		testPoints: [],
		selectedSamplePlanId: 0,
		selectedSamplePlan: null,
	};

	const [samplePlanQuoteData, setSamplePlanQuoteData] = useState(
		initialSamplePlanState,
	);

	const [selectedTemplateId, setSelectedTemplateId] = useState(-1);

	return (
		<div className="px-20">
			{user?.roleId === 1 ? (
				<>
					{showAlert && (
						<FCMessageDialog
							isSuccess={state.isSuccessAlert}
							// modalCallBack={() => this.closeDialog()}
							// message={alertMessage}
						/>
					)}
					{state.currentPage === "SamplePlan" ? (
						<SamplePlan
							changepage={changePage}
							selectedTemplateId={selectedTemplateId}
							setSelectedTemplateId={setSelectedTemplateId}
						/>
					) : (
						""
					)}
					{state.currentPage === "CreatePlan" ? (
						<CreatePlan
							changepage={changePage}
							// templateId={state.templateId}
							templateId={selectedTemplateId}
							sites={state.sites}
							sampleplanQuoteData={samplePlanQuoteData}
							setSampleplanQuoteData={setSamplePlanQuoteData}
						/>
					) : (
						""
					)}
				</>
			) : (
				<h2 className="text-center mt-5">
					You do not have access to view this page
				</h2>
			)}
		</div>
	);
};

export default SamplePlanPage;

// class SamplePlanComponent extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			showAlert: false,
// 			isSuccessAlert: false,
// 			alertMessage: "",
// 			showConfirm: false,
// 			title: "",
// 			currentPage: "SamplePlan",
// 			templateId: -1,
// 			sites: [],
// 			user: user,
// 		};
// 		this.showAlert = this.showAlert.bind(this);
// 	}

// 	showAlert(isShow, isSuccess, message) {
// 		this.setState({
// 			showAlert: isShow,
// 			isSuccessAlert: isSuccess,
// 			alertMessage: message,
// 		});
// 	}

// 	closeDialog = () => {
// 		this.setState({ showAlert: false });
// 	};

// 	componentDidMount() {
// 		if (window.location.href.toLowerCase().indexOf("createplan") > -1) {
// 			this.setState({ currentPage: "CreatePlan" });
// 		}
// 		var alertMessage = window.localStorage.getItem("alertMessage");
// 		if (alertMessage && alertMessage != "") {
// 			this.setState({
// 				showAlert: true,
// 				alertMessage: alertMessage,
// 				isSuccessAlert: true,
// 			});
// 		} else {
// 		}
// 		window.localStorage.setItem("alertMessage", "");

// 		let usrData = getUserData();

// 		if (this.props.usersData?.usersResult.length > 0) {
// 			let usr = this.props.usersData?.usersResult.find(
// 				(item) => item.id === usrData.id
// 			);
// 			if (usr) this.setState({ user: usr });
// 		}
// 	}

// 	componentDidUpdate(prevProps, prevState) {
// 		let usrData = getUserData();

// 		if (
// 			prevProps.usersData?.usersResult !== this.props.usersData?.usersResult
// 		) {
// 			if (this.props.usersData?.usersResult.length > 0) {
// 				let usr = this.props.usersData?.usersResult.find(
// 					(item) => item.id === usrData.id
// 				);
// 				if (usr) this.setState({ user: usr });
// 			}
// 		}
// 	}

// 	changePage = (value, templateId, sites) => {
// 		if (value) {
// 			this.setState({
// 				currentPage: "CreatePlan",
// 				templateId: templateId,
// 				sites: sites,
// 			});
// 		} else {
// 			this.setState({ currentPage: "SamplePlan" });
// 		}
// 	};

// 	render() {
// 		console.log("this is sample props", this.props);
// 		const { currentPage, showAlert, isSuccessAlert, alertMessage } = this.state;
// 		return (
// 			<div className="px-20">
// 				{this.state.user?.roleId === 1 ? (
// 					<>
// 						{showAlert && (
// 							<FCMessageDialog
// 								isSuccess={isSuccessAlert}
// 								modalCallBack={() => this.closeDialog()}
// 								message={alertMessage}
// 							/>
// 						)}
// {currentPage === "SamplePlan" ? (
// 	<SamplePlan changepage={this.changePage} />
// ) : (
// 	""
// )}
// {currentPage === "CreatePlan" ? (
// 	<CreatePlan
// 		changepage={this.changePage}
// 		templateId={this.state.templateId}
// 		sites={this.state.sites}
// 	/>
// ) : (
// 	""
// )}
// 					</>
// 				) : (
// 					<h2 className="text-center mt-5">
// 						You do not have access to view this page
// 					</h2>
// 				)}
// 			</div>
// 		);
// 	}
// }

// const mapStateToProps = (state) => {
// 	const { usersData } = state;
// 	return {
// 		usersData,
// 	};
// };

// export default connect(mapStateToProps)(SamplePlanComponent);
