import * as React from "react";
import { useState } from "react";
import FCInputField from "../../utils/library/textbox";
import FCButton from "../../utils/library/button";
import { Validators, validateInput } from "../../utils/library/Validator";
import { login_text, login_link } from "../../utils/common/css_hooks";
import accountApi from "../../utils/api/accountApi";

// cookies
import Cookies from "js-cookie";

const Login = () => {
	const validators = [
		{
			check: Validators.required,
			message: "Fill the requried fields to continue.",
		},
	];

	const [isCompanyEntered, setIsCompanyEntered] = useState(false);
	const [isUserNameEntered, setIsUserNameEntered] = useState(false);
	const [isPwdEntered, setIsPwdEntered] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [company, setCompany] = useState("");
	const [pwd, setPwd] = useState("");
	const [username, setUsername] = useState("");
	const [userAuthData, setUserAuthData] = useState({});
	const [error, setError] = useState(false);

	const handleChange = (event) => {
		if (event.target.name === "password") {
			setPwd(event.target.value);
			setIsPwdEntered(validateInput(validators, event.target.value));
		}
		if (event.target.name === "company") {
			setCompany(event.target.value);
			setIsCompanyEntered(validateInput(validators, event.target.value));
		}
		if (event.target.name === "username") {
			setUsername(event.target.value);
			setIsUserNameEntered(validateInput(validators, event.target.value));
		}
	};

	const handleLogin = async (event) => {
		event.preventDefault();
		setIsCompanyEntered(validateInput(validators, company));
		setIsUserNameEntered(validateInput(validators, username));
		setIsPwdEntered(validateInput(validators, pwd));
		if (username !== "" && pwd !== "" && company !== "") {
			// dispatch(
			// 	login({
			// 		username: username,
			// 		password: pwd,
			// 		companyName: company,
			// 		pushNotificationToken: "",
			// 		deviceId: "",
			// 		isAndroid: false,
			// 	})
			// );
			const response = await accountApi.login({
				username: username,
				password: pwd,
				companyName: company,
				pushNotificationToken: "",
				deviceId: "",
				isAndroid: false,
			});

			if (response.status === 200) {
				setIsLoggedIn(true);

				// set cookie
				let cookieObj = {
					id: response.data.id,
					clientId: response.data.clientId,
					siteId: response.data.siteId,
					roleId: response.data.roleId,
					userToken: response.data.userToken,
					// userToken:
					// 	"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiZXhwIjoxNzAwNjk4MDAwLCJpYXQiOjE2MDEwMjY4MDB9.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c",
				};

				cookieObj = JSON.stringify(cookieObj);

				Cookies.set("userAuthData", cookieObj, { expires: 7 });

				setUserAuthData(JSON.parse(Cookies.get("userAuthData")));

				window.location.href = "./";
			} else {
				setError(response.statusText);
			}
		}
	};

	// if (authData.loginResult && authData.status === 200 && !isLoggedIn) {
	// 	console.log("continue");
	// 	if (authData.loginResult.token !== "") {
	// 		authData.loginResult.clientName = authData.loginInput.companyName;
	// 		window.localStorage.setItem(
	// 			"AUTHDATA",
	// 			JSON.stringify(authData.loginResult)
	// 		);
	// 		console.log("Set local storage");
	// 		setIsLoggedIn(true);
	// 	}
	// }

	//if (isLoggedIn) {
	//	window.location.href = "../landing";
	//}

	return (
		<React.Fragment>
			{!isLoggedIn && (
				<div className="min-h-screen flex items-center justify-center bg-primary p-12 px-4 sm:px-6 lg:px-8">
					<div className="max-w-md w-full space-y-8 p-8 bg-white rounded-2xl border-4">
						<div className="mt-8">
							<img
								className="mx-auto h-12 w-auto"
								src="../../../../assets/logo/logo.png"
								alt="FreshCheck"
							/>
						</div>
						<form className="p-6 space-y-3" onSubmit={handleLogin}>
							<div className="rounded-2xl">
								<FCInputField
									isBorder={false}
									className="bg-fcinput"
									type="text"
									id="company"
									placeholder="Company Name"
									icon="lock"
									isError={isCompanyEntered}
									onChange={handleChange}
									value={company}
								/>
								<FCInputField
									isBorder={false}
									className="bg-fcinput"
									type="text"
									id="username"
									placeholder="Username"
									icon="user"
									isError={isUserNameEntered}
									onChange={handleChange}
									value={username}
								/>
								<FCInputField
									isBorder={false}
									className="bg-fcinput"
									type="password"
									id="password"
									placeholder="Password"
									icon="pwd"
									isError={isPwdEntered}
									onChange={handleChange}
									value={pwd}
								/>
							</div>
							<div>
								<FCButton
									bgColor="bg-fcbutton"
									type="submit"
									icon="rightarrow"
									value="Login"
								/>
							</div>
							<p className="mt-1 text-center text-lg text-gray-600">
								<span className={login_text}>
									Don't have an account?{" "}
									<a href="./register" className="underline">
										Click here
									</a>
								</span>
							</p>
							<p className="mt-1 text-center text-lg text-gray-600">
								<a href="./forgotpassword" className={login_link}>
									Forgot Password
								</a>
							</p>
							{
								<p className="text-lg h-2 text-red-500  text-black  text-center">
									{(isCompanyEntered || isPwdEntered || isUserNameEntered) &&
										"Please enter the required fields."}
									{/* {authData.isSuccess &&
										!authData.status.statusValue &&
										!isCompanyEntered &&
										!isPwdEntered &&
										!isUserNameEntered &&
										"Invalid username or password."} */}
									{!isCompanyEntered &&
										!isPwdEntered &&
										!isUserNameEntered &&
										error &&
										error}
								</p>
							}
						</form>
					</div>
				</div>
			)}
		</React.Fragment>
	);
};

export default Login;

// const mapStateToProps = (state) => {
// 	const { authData } = state;
// 	return {
// 		authData,
// 	};
// };

// export default connect(mapStateToProps)(Login);
