import * as React from "react";
import { useState } from "react";
import accountApi from "../../utils/api/accountApi";
import FCInputField from "../../utils/library/textbox";
import FCButton from "../../utils/library/button";
import { Validators, validateInput } from "../../utils/library/Validator";

import { CheveronCircleRightSvgIcon } from "../../utils/common/icon-hooks.js";

const ResetPwd = () => {
	const [pwd, setPwd] = useState("");
	const [cnfPwd, setCnfPwd] = useState("");
	const [isValidPassword, setIsValidPassword] = useState(true);
	const [isEqualPassword, setIsEqualPassword] = useState(true);
	const [isResend, setIsResend] = useState(false);
	const [isPwdEntered, setIsPwdEntered] = useState(false);
	const [isCnfPwdEntered, setIsCnfPwdEntered] = useState(false);
	const [isPageValid, setIsPageValid] = useState(false);
	const [userAuthData, setUserAuthData] = useState({});
	const [error, setError] = useState(false);
	const [email, setEmail] = useState("");
	const [token, setToken] = useState("");

	const validators = [
		{
			check: Validators.required,
			message: "Enter the email id.",
		},
	];

	const handleChange = (event) => {
		if (event.target.name === "password") {
			setPwd(event.target.value);
			setIsPwdEntered(validateInput(validators, event.target.value));
		} else {
			setCnfPwd(event.target.value);
			setIsCnfPwdEntered(validateInput(validators, event.target.value));
		}
	};

	React.useEffect(() => {
		// dispatch(forgetPasswordValidateToken({ email: email, resetToken: token }));
		const forgotPasswordValidateToken = async () => {
			const response = await accountApi.forgetPasswordValidateToken({
				email: email,
				resetToken: token,
			});
			if (response.status === 200) {
				setUserAuthData(response);
			} else {
				setError(response.statusText);
			}
			setUserAuthData(response);
		};
		forgotPasswordValidateToken();
	}, []);

	const handleResetPwd = async (event) => {
		event.preventDefault();
		setIsPwdEntered(validateInput(validators, pwd));
		setIsCnfPwdEntered(validateInput(validators, cnfPwd));

		if (pwd !== "" && cnfPwd !== "" && pwd === cnfPwd) {
			//Password RegEx validation
			if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}/.test(pwd)) {
				//API Call for reset password
				setIsValidPassword(true);
				setIsPageValid(true);
			} else {
				setIsValidPassword(false);
			}
			setIsEqualPassword(true);
		} else {
			setIsEqualPassword(false);
		}
	};

	const forgotPasswordChange = async () => {
		const response = await accountApi.forgotPasswordChange({
			email: email,
			resetToken: token,
			newPassword: pwd,
		});
		return response;
	};

	if (isPageValid) {
		// dispatch(
		// 	changeForgetPassword({
		// 		email: email,
		// 		resetToken: token,
		// 		newPassword: pwd,
		// 	})
		// );
		if (forgotPasswordChange().status === 200) {
			setIsPageValid(false);
		}
	}

	const goToLoginPage = async (event) => {
		window.location.href = "./";
	};

	// commented as not doing anything
	// if (authData.isTokenValidated && authData.status.statusValue) {
	// 	//alert('hi');
	// }

	// This replaces the below code using authdata
	if (userAuthData.data.isFormSubmit && !userAuthData.data.isResend) {
		if (userAuthData.data.status !== 200) {
			setIsResend(true);
		}
	}

	// if (!authData.isFormSubmit && !isResend) {
	// 	if (authData.status.statusValue) {
	// 		setIsResend(true);
	// 	}
	// }

	return (
		<React.Fragment>
			<div className="min-h-screen flex items-center justify-center bg-primary p-12 px-4 sm:px-6 lg:px-8">
				<div className="max-w-md w-full space-y-8 py-6 pl-6 pr-12 bg-white rounded-2xl border-4">
					<div className="mt-8">
						<img
							className="mx-auto h-12 w-auto"
							src="../../../../assets/logo/logo.png"
							alt="FreshCheck"
						/>
					</div>
					<div className="pb-40">
						{/* {authData.isTokenValidated &&
							authData.status.statusValue &&
							!authData.isError &&
							!authData.isPasswordChanged && ( */}
						{userAuthData.data.isTokenValidated &&
							userAuthData.status !== 200 &&
							!userAuthData.data.isError &&
							!userAuthData.data.isPasswordChanged && (
								<>
									<p className="text-lg  text-black  text-center">
										To reset password enter email below.
									</p>
									<form className="p-6 space-y-8" onSubmit={handleResetPwd}>
										<input type="hidden" name="remember" defaultValue="true" />
										<div className="rounded-2xl -space-y-px">
											<FCInputField
												isBorder={false}
												className="bg-fcinput"
												type="password"
												id="password"
												placeholder="New Password"
												icon="pwd"
												isError={isPwdEntered}
												onChange={handleChange}
												value={pwd}
											/>

											<FCInputField
												isBorder={false}
												className="bg-fcinput"
												type="password"
												id="cnfPassword"
												placeholder="Confirm Password"
												icon="pwd"
												isError={isCnfPwdEntered}
												onChange={handleChange}
												value={cnfPwd}
											/>
										</div>
										<div>
											<FCButton
												bgColor="bg-fcbutton"
												type="submit"
												icon="rightarrow"
												value="Reset Password"
											/>
										</div>
									</form>
								</>
							)}

						{/* {authData.isTokenValidated &&
							!authData.status.statusValue &&
							!authData.isPasswordChanged && ( */}
						{userAuthData.data.isTokenValidated &&
							userAuthData.status !== 200 &&
							!userAuthData.data.isPasswordChanged && (
								<>
									<p className="text-lg h-2  text-black  text-center pb-20">
										The reset link is no longer valid.
									</p>
									<div>
										<button
											id="btnSend"
											type="button"
											onClick={goToLoginPage}
											className="inline-flex items-center px-6 py-3 border-transparent text-base bg-fcbutton
                             rounded-full text-white focus:outline-none w-4/5 ml-10 justify-between"
										>
											<span className="text-white w-full ">Login</span>
											<CheveronCircleRightSvgIcon />
										</button>
									</div>
								</>
							)}

						{/* {authData.isPasswordChanged &&
							authData.status.statusValue &&
							authData.isTokenValidated && ( */}
						{userAuthData.data.isPasswordChanged &&
							userAuthData.status === 200 &&
							userAuthData.data.isTokenValidated && (
								<div className="pb-20">
									<p className="text-lg h-2  text-black  text-center pb-20">
										Password changed successfully.
									</p>
									<div>
										<button
											id="btnSend"
											type="button"
											onClick={goToLoginPage}
											className="inline-flex items-center px-6 py-3 border-transparent text-base bg-fcbutton
                             rounded-full text-white focus:outline-none w-4/5 ml-10 justify-between"
										>
											<span className="text-white w-full ">Login</span>
											<CheveronCircleRightSvgIcon />
										</button>
									</div>
								</div>
							)}
						{
							<>
								<p className="text-lg h-2 text-red-500 mt-5  text-black  text-center">
									{/* This is the response message returned from the api */}
									{/* {authData.status.statusDisplay} */}
									{userAuthData.statusText}
									{!isEqualPassword && (
										<span>Password and confirm password are not same.</span>
									)}
									{!isValidPassword && (
										<span>
											Password should contain 8 characters with, 1 upper, 1
											lower and 1 number
										</span>
									)}
									<p className="h-2 text-red-500 mt-5  text-black  text-center">
										{(isPwdEntered || isCnfPwdEntered) &&
											"Please enter the required fields."}
									</p>
								</p>
							</>
						}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ResetPwd;

// const mapStateToProps = (state) => {
// 	const { authData } = state;
// 	return {
// 		authData,
// 	};
// };

// export default connect(mapStateToProps)(ResetPwd);
