import * as React from "react";
import ForgotPwd from "../../account/forgotpwd";

const ForgotPwdComponent = () => {
	return (
		<div>
			<ForgotPwd />
		</div>
	);
};

export default ForgotPwdComponent;

// class ForgotPwdComponent extends React.Component {
// 	state = {
// 		isForgetPasswordPage: false,
// 		isForgetPasswordResendPage: false,
// 	};
// 	constructor(props) {
// 		super(props);

// 		this.state = {
// 			isForgetPasswordPage: false,
// 			isForgetPasswordResendPage: false,
// 		};
// 	}

// 	componentDidMount() {}

// 	render() {
// 		const {} = this.state;
// 		return (
// 			<div className="">
// 				<ForgotPwd />
// 			</div>
// 		);
// 	}
// }

// function mapStateToProps(state) {
// 	const { login } = state;
// 	console.log("forgot password ", login);
// 	return {
// 		login,
// 	};
// }

// export default connect(mapStateToProps)(ForgotPwdComponent);
