import { Http } from "../Http";
import { AxiosPromise } from "axios";
import apiCall from "../api/apiCall";

const userApi = {
	getAllUsers: async (input) => {
		const url =
			"User/GetAllWithImage?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken;
		const response = await apiCall(url, "get");
		return response;
	},

	getSingleUser: async (input) => {
		const url =
			"User/GetSingle?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken +
			"&id=" +
			input.id;
		const response = await apiCall(url, "get");
		return response;
	},

	getSingleUserWithImg: async (input) => {
		const url =
			"User/GetSingleWithImage?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken +
			"&id=" +
			input.id;
		const response = await apiCall(url, "get");
		return response;
	},

	deleteUser: async (input) => {
		const url =
			"User/DeleteUser?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken +
			"&id=" +
			input.id;
		const response = await apiCall(url, "get");
		return response;
	},

	addOrUpdateUser: async (input) => {
		const obj = JSON.stringify(input);
		let url;
		if (input.id > 0) {
			url =
				"User/Update" +
				"?userId=" +
				input.userId +
				"&userToken=" +
				input.userToken +
				"&id=" +
				input.id;
			const response = await apiCall(url, "put", obj);
			return response;
		} else {
			url =
				"User/Add" +
				"?userId=" +
				input.userId +
				"&userToken=" +
				input.userToken;
			const response = await apiCall(url, "post", obj);
			return response;
		}
	},

	changeUserPassword: async (input) => {
		let url =
			"User/ChangeUserPassword" +
			"?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken +
			"&changeUserId=" +
			input.userId +
			"&newPassword=" +
			input.newPassword;
		const response = await apiCall(url, "post");
		return response;
	},

	updateUserAvatar: async (input) => {
		const url =
			"User/UpdateAvatar" +
			"?id=" +
			input.userId +
			"&userToken=" +
			input.userToken;
		const response = await apiCall(url, "put", input.imageUrl);
		return response;
	},

	getUserRoles: async (input) => {
		const url =
			"User/GetUserRoles?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken;
		const response = await apiCall(url, "get");
		return response;
	},
};

export default userApi;

// export class UsersApi {

//   public static getAllUsers(input: any) {
// 		return Http.axios().get('/UserManagement/GetAllUsersWithImg?userId=' + input.userId + "&userToken=" + input.userToken)
//       .catch((e) => {
//         return e.response;
//       }) as AxiosPromise<any>;
//   }

//   public static getSingleUser(input: any) {
//     return Http.axios().get('/UserManagement/GetSingleUser?userId=' + input.userId + "&userToken=" + input.userToken + "&id=" + input.id)
//       .catch((e) => {
//         return e.response;
//       }) as AxiosPromise<any>;
//   }

//   public static getSingleUserWithImg(input: any) {
//     return Http.axios().get('/UserManagement/GetSingleUserWithImg?userId=' + input.userId + "&userToken=" + input.userToken + "&id=" + input.id)
//       .catch((e) => {
//         return e.response;
//       }) as AxiosPromise<any>;
//   }

//   public static deleteUser(input: any) {
//     return Http.axios().get('/UserManagement/DeleteUser?userId=' + input.userId + "&userToken=" + input.userToken + "&id=" + input.id)
//       .catch((e) => {
//         return e.response;
//       }) as AxiosPromise<any>;
//   }

//   public static addOrUpdateUser(input: any) {
//     var obj = JSON.stringify(input);

//     if (input.id > 0) {
//       url = '/UserManagement/UpdateUser';
//       return Http.axios().put(url, obj, { headers: { 'Content-Type': 'application/json' } })
//         .then(response => {
//           return response;
//         }).catch((e) => {
//           return e.response;
//         }) as AxiosPromise<any>;
//     }
//     else {
//       var url = '/UserManagement/AddUser';
//       return Http.axios().post(url, obj, { headers: { 'Content-Type': 'application/json' } })
//         .then(response => {
//           return response;
//         }).catch((e) => {
//           return e.response;
//         }) as AxiosPromise<any>;
//     }
//   }

//   public static updateUserAvatar(input: any) {
//     var obj = JSON.stringify(input);
//     return Http.axios().post('/UserManagement/UpdateAvatar', obj, { headers: { 'Content-Type': 'application/json' } })
//       .then(response => {
//         return response;
//       }).catch((e) => {
//         return e.response;
//       }) as AxiosPromise<any>;
//   }

//   public static getUserRoles(input: any) {
//     return Http.axios().get('/UserManagement/GetUserRoles?userId=' + input.userId + "&userToken=" + input.userToken)
//       .catch((e) => {
//         return e.response;
//       }) as AxiosPromise<any>;
//   }

// }
