import { Http } from "../Http";
import { AxiosPromise } from "axios";
import apiCall from "./apiCall.js";
import apiConfig from "../../config/apiConfig";

const samplePlanApi = {
	getSamplePlans: async (input) => {
		const id = input.userId ?? input.id;
		const url =
			"SamplePlan/GetAll?userId=" + id + "&userToken=" + input.userToken;
		const response = await apiCall(url, "get");
		return response;
	},

	getTestPoints: async (input) => {
		const url =
			"TestPoint/GetAllTestPoint?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken;
		const response = await apiCall(url, "get");
		return response;
	},

	saveSamplePlan: async (input) => {
		let isLive = input.isLive;
		if (!isLive) {
			isLive = false;
		}

		if (!input.id) {
			input.id = 0;
		}

		const data = {
			...input,
			id: 0,
			isLive: isLive,
		};
		const obj = JSON.stringify(data);
		const url =
			"SamplePlan/Add" +
			"?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken;
		const response = await apiCall(url, "post", obj);
		return response;
	},

	addFromTemplate: async (input) => {
		const obj = JSON.stringify(input);
		const url =
			"SamplePlan/AddFromTemplate" +
			"?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken;
		const response = await apiCall(url, "post", obj);
		return response;
	},

	updateSamplePlan: async (input) => {
		const obj = JSON.stringify(input);
		const url =
			"SamplePlan/Update" +
			"?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken +
			"&id=" +
			input.id;
		const response = await apiCall(url, "put", obj);
		return response;
	},

	deleteSamplePlan: async (input) => {
		const obj = JSON.stringify(input);
		const url =
			"SamplePlan/Delete" +
			"?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken +
			"&id=" +
			input.id;
		const response = await apiCall(url, "delete");
		return response;
	},

	importSamplePlan: async (input) => {
		const obj = JSON.stringify(input);
		const url = "SamplePlan/ImportPlan";
		const response = await apiCall(url, "post", obj);
		return response;
	},
};

export default samplePlanApi;

// export class SampleplanApi {
// 	public static getSamplePlans(input: any) {
// 		return Http.axios().get('/SamplePlan/GetAll?userId=' + input.userId
// 			+ "&userToken=" + input.userToken)
// 			.catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}

// 	public static getTestPoints(input: any) {
// 		return Http.axios().get('/TestPoint/GetAllTestPoint?userId=' + input.userId
// 			+ "&userToken=" + input.userToken)
// 			.catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}

// 	public static saveSamplePlan(input: any) {
// 		var obj = JSON.stringify(input);
// 		return Http.axios().post('/SamplePlan/SaveSamplePlan', obj, { headers: { 'Content-Type': 'application/json' } })
// 			.then(response => {
// 				return response;
// 			}).catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}

// 	public static deleteSamplePlan(input: any) {
// 		var obj = JSON.stringify(input);
// 		return Http.axios().post('/SamplePlan/DeleteSamplePlan', obj, { headers: { 'Content-Type': 'application/json' } })
// 			.then(response => {
// 				return response;
// 			}).catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}

// 	public static importSamplePlan(input: any) {
// 		var obj = JSON.stringify(input);
// 		return Http.axios().post('/SamplePlan/ImportPlan', obj, { headers: { 'Content-Type': 'application/json' } })
// 			.then(response => {
// 				return response;
// 			}).catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}
// }
