import React, { useState, useEffect, useRef, Fragment } from "react";
import Select, { StylesConfig } from "react-select";
import moment from "moment";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { usePopper } from "react-popper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../custom.css";
import ToggleSwitch from "../../utils/library/toggleSwitch";
import FCSelectSiteField from "../../utils/library/selectsitefield";
import FCButtonWithClick from "../../utils/library/buttonwithclick";
import { getUserData } from "../../utils/common/functions";
import testPointApi from "../../utils/api/testpointApi";
import {
	PlusCircleSvgIconTransparent,
	DotVerticalSvgSvgIcon,
} from "../../utils/common/icon-hooks.js";
import FCMessageDialog from "../../utils/library/dialog";
import { showSweetAlert } from "../Shared/CustomAlert";

import siteApi from "../../utils/api/siteApi";
import userApi from "../../utils/api/userApi";
import samplePlanApi from "../../utils/api/sampleplanApi";
import dashboardApi from "../../utils/api/dashboardApi";
import testApi from "../../utils/api/testApi";

import { Dialog, Transition } from "@headlessui/react";

import { CloseIcon } from "../../utils/common/icon-hooks.js";

const Landing = () => {
	const initialDashboardDataState = {
		isLoading: false,
		isError: false,
		isAllReportLoaded: false,
		isTestHistoryLoaded: false,
		allReportData: [],
		dashboardInput: {},
		isGlobalComparisonLoaded: false,
		globalComparisonData: [],
		isDashboardDataLoaded: false,
		graphData: {},
		isDownloadReportLoaded: false,
		downloadReportData: [],
		isDownloadDataLoaded: false,
		downloadData: [],
		testHistoryData: [],
	};

	const initialSiteDataState = {
		userId: 0,
		usertoken: "1234",
		isError: false,
		isSaved: false,
		isSiteLoaded: false,
		isSitesLoaded: false,
		isAllSitesLoaded: false,
		isSiteSaved: false,
		isSiteDeleted: false,
		sites: null,
		site: null,
	};

	const initialUserDataState = {
		isLoading: false,
		isError: false,
		isUsersLoaded: false,
		isUserDeleted: false,
		deleteResult: false,
		userInput: {
			userId: -1,
			userToken: "",
			clientId: -1,
		},
		usersResult: [],
		userRolesResult: [],
		user: null,
		isUserLoaded: false,
		isUserAdded: false,
		userAddResult: false,
		isAvatarUpdated: false,
		isUserRolesLoaded: false,
	};

	const initialSampleplanDataState = {
		userId: 0,
		usertoken: "",
		isSaved: false,
		isLive: false,
		isError: false,
		isSamplePlansLoaded: false,
		isTestPointsLoaded: false,
		isPlanSaved: false,
		isPlanDeleted: false,
		samplePlanInput: null,
		samplePlans: null,
		testPoints: null,
		selectedSamplePlanId: 0,
		selectedSamplePlan: null,
	};

	const [showAlert, setShowAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	const [isSuccessAlert, setIsSuccessAlert] = useState(false);
	const [popVisible, setPopVisibility] = useState(false);
	const [referenceRef, setReferenceRef] = useState(null);
	const [popperRef, setPopperRef] = useState(null);

	const { styles, attributes } = usePopper(referenceRef, popperRef, {
		placement: "bottom-end",
		modifiers: [
			{
				name: "offset",
				enabled: true,
				options: {
					offset: [0, 10],
				},
			},
		],
	});

	const frequencyOptions = [
		{ label: "Today", value: "Today" },
		{ label: "This Week", value: "This Week" },
		{ label: "This Month", value: "This Month" },
	];
	const Styles = {
		option: (provided) => ({
			...provided,
			color: "#002366",
		}),
		control: (provided, state) => ({
			...provided,
			boxShadow: "none",
			border: "none",
			backgroundColor: "none",
		}),
		singleValue: (provided) => ({
			...provided,
			color: "#002366",
		}),
	};

	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;

	const [userData, setUserData] = useState(null);
	const [isSitesLoaded, setIsSitesLoaded] = useState(false);
	const [siteOptions, setSiteOptions] = useState([]);
	const [selectedSiteId, setSelectedSiteId] = useState(0);
	const [selectedSite, setSelectedSite] = useState({ label: "", value: 0 });

	const [allUsers, setAllUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState({ label: "All", value: 0 });
	const [selectedUserId, setSelectedUserId] = useState(0);
	const [userOptions, setUserOptions] = useState([]);
	const [isAllUsersLoaded, setIsAllUsersLoaded] = useState(false);

	const [selectedSamplePlanId, setSelectedSamplePlanId] = useState(0);
	const [isSamplePlansLoaded, setIsSamplePlansLoaded] = useState(false);
	const [samplePlanOptions, setSamplePlanOptions] = useState([]);
	const [selectedSamplePlan, setSelectedSamplePlan] = useState({
		label: "",
		value: 0,
	});

	const [isDashboardDataLoaded, setIsDashboardDataLoaded] = useState(false);
	const [pieOptions, setPieOptions] = useState(null);
	const [barOptions, setBarOptions] = useState(null);
	const [barSingleOptions, setBarSingleOptions] = useState(null);
	const [graphData, setGraphData] = useState({});
	const [showedInitGraph, setShowedInitGraph] = useState(false);
	const [loadedInitGraphData, setLoadedInitGraphData] = useState(false);
	const [failedTestPoints, setFailedTestPoints] = useState([]);
	const [completedTestPoints, setCompletedTestPoints] = useState([]);
	const [failRate, setFailRate] = useState(0);
	const [successRate, setSuccessRate] = useState(0);
	const [totalFail, setTotalFail] = useState(0);
	const [totalRetests, settTotalRetests] = useState(0);
	const [frequency, setFrequency] = useState({
		label: "Today",
		value: "Today",
	});
	const [orderByTestToggle, setOrderByTestToggle] = useState(true);
	// const pieChartComponentRef = useRef < HighchartsReact.RefObject > null;
	// const barChartComponentRef = useRef < HighchartsReact.RefObject > null;
	// const barChartSingleComponentRef = useRef < HighchartsReact.RefObject > null;
	const pieChartComponentRef = useRef(null);
	const barChartComponentRef = useRef(null);
	const barChartSingleComponentRef = useRef(null);

	const [isDownloadReportLoaded, setIsDownloadReportLoaded] = useState(false);
	const [isDownloadDataLoaded, setIsDownloadDataLoaded] = useState(false);

	// new states
	const [dashboardData, setDashboardData] = useState(initialDashboardDataState);
	const [siteData, setSiteData] = useState(initialSiteDataState);
	const [usersData, setUsersData] = useState(initialUserDataState);
	const [sampleplanData, setSampleplanData] = useState(
		initialSampleplanDataState,
	);

	const [error, setError] = useState(false);

	const refreshGraph = (data, refreshPie, refreshBar, isSort) => {
		if (refreshPie) {
			//Pie Chart
			setPieOptions({
				title: {
					text: "",
				},
				dataLabels: {
					enabled: true,
				},
				plotOptions: {
					pie: {
						dataLabels: {
							distance: "-40%",
						},
					},
				},
				colors: ["#26a4ca", "#ffcc00", "#f70000"],
				series: [
					{
						type: "pie",
						style: {
							fontFamily: "DINNextLTPro-Bold",
							color: "#646464",
							fill: "none",
						},
						dataLabels: {
							enabled: true,
							align: "center",
							color: "black",
							style: {
								textOutline: false,
							},
							formatter: function () {
								return (
									this.y +
									" (" +
									Highcharts.numberFormat(this.percentage, 0) +
									"%)"
								);
							},
						},
						data: [
							{ name: "Pass", y: data?.totalPass },
							{ name: "Warning", y: data?.totalWarning },
							{ name: "Fail", y: data?.totalFail },
						],
					},
				],
			});

			if (data?.failedTestPoints) {
				setFailedTestPoints(data?.failedTestPoints);
			}

			setFailRate(data?.failRate);
			setSuccessRate(data?.retestSuccessRate);
			setTotalFail(data?.totalFail);
			settTotalRetests(data?.totalRetests);
			setCompletedTestPoints(data?.completedTests);
			refreshCompletedTestGraph(data?.completedTests, frequency.value);
		}

		if (refreshBar) {
			//Bar chart
			var nameData = [];
			var passData = [];
			var warningData = [];
			var failData = [];
			var seriousData = [];

			if (data?.testPointReport) {
				// var result = data?.testPointReport.sort((a, b) => {
				// 	if (isSort) {
				// 		return a.id > b.id ? 1 : -1;
				// 	} else {
				// 		return a.totalFail < b.totalFail ? 1 : -1;
				// 	}
				// });
				// data?.testPointReport.map((item) => {
				// 	passData.push(item.totalPass);
				// 	warningData.push(item.totalWarning);
				// 	failData.push(item.totalFail);
				// 	nameData.push(item.name);
				// });
				const sortData = () => {
					return data?.testPointReport.sort((a, b) => {
						if (isSort) {
							return a.id > b.id ? 1 : -1;
						} else {
							return a.totalFail < b.totalFail ? 1 : -1;
						}
					});
				};
				sortData();

				const returnTestpointReportData = () => {
					return data?.testPointReport.forEach((item) => {
						passData.push(item.totalPass);
						warningData.push(item.totalWarning);
						failData.push(item.totalFail);
						nameData.push(item.name);
					});
				};
				returnTestpointReportData();
			}
			seriousData.push({ name: "Fail", data: failData });
			seriousData.push({ name: "Warning", data: warningData });
			seriousData.push({ name: "Pass", data: passData });

			setBarOptions({
				chart: {
					renderTo: "container",
					style: {
						fontFamily: "DINNextLTPro-Bold",
						color: "#646464",
						fill: "none",
					},
					marginLeft: 100,
					type: "bar",
					scrollablePlotArea: {
						minHeight: nameData.length * 40,
					},
					events: {
						afterSetExtremes: function () {
							var xAxis = this,
								numberOfPoints = xAxis.series[0].points.length - 1,
								minRangeValue = xAxis.getExtremes().min,
								maxRangeValue = xAxis.getExtremes().max;

							if (minRangeValue < 0) {
								xAxis.setExtremes(null, xAxis.options.max);
							} else if (maxRangeValue > numberOfPoints) {
								xAxis.setExtremes(
									numberOfPoints - xAxis.options.max,
									numberOfPoints,
								);
							}
						},
					},
				},
				scrollbar: {
					minWidth: 6,
					height: 4,
				},
				title: {
					text: "",
				},
				colors: ["#f70000", "#ffcc00", "#26a4ca"],
				xAxis: {
					lineWidth: 0,
					minorGridLineWidth: 0,
					lineColor: "transparent",
					type: "category",
					categories: nameData,
					labels: {
						style: {
							fontWeight: "bold",
							color: "gray",
							fill: "none",
						},
					},
				},
				plotOptions: {
					series: {
						stacking: "percent",
						dataSorting: {
							enabled: true,
							sortKey: "custom.id",
						},
						pointWidth: 25,
						shadow: false,
						dataLabels: {
							enabled: true,
							align: "center",
							color: "black",
							style: {
								textOutline: false,
							},
							formatter: function () {
								return (
									this.y +
									" (" +
									Highcharts.numberFormat(this.percentage, 0) +
									"%)"
								);
							},
						},
					},
				},
				legend: {
					enabled: false,
				},
				yAxis: {
					labels: {
						enabled: false,
					},
					opposite: true,
					title: false,
					gridLineColor: "transparent",
				},
				series: seriousData,
			});
		}
	};

	const refreshCompletedTestGraph = (data, period) => {
		//Bar chart
		var nameData = [];
		var completedData = [];
		var remainingData = [];
		var seriousData = [];

		if (completedTestPoints) {
			var selectedItem = data?.find((item) => {
				return item.period === period;
			});
			if (selectedItem) {
				completedData.push(selectedItem.completed);
				remainingData.push(
					selectedItem.testToComplete - selectedItem.completed,
				);
				nameData.push("Completed Test");
			}
		}

		seriousData.push({ name: "Requires Testing", data: remainingData });
		seriousData.push({ name: "Completed", data: completedData });

		setBarSingleOptions({
			chart: {
				style: {
					fontFamily: "DINNextLTPro-Bold",
					color: "#646464",
					fill: "none",
				},
				type: "bar",
				backgroundColor: "transparent",
				borderWidth: 0,
				plotBackgroundColor: "transparent",
				plotShadow: false,
				plotBorderWidth: 0,
				margin: 0,
				padding: 0,
				spacing: [0, 0, 0, 0],
			},
			legend: {
				reversed: true,
			},
			credits: { enabled: false },
			title: {
				text: "Completed Tests",
			},
			subtitle: {
				text: "",
			},
			colors: ["#d1d5db", "#26a4ca"],
			xAxis: {
				lineWidth: 0,
				minorGridLineWidth: 0,
				lineColor: "transparent",
				type: "category",
				categories: nameData,
				labels: {
					enabled: false,
				},
				minPadding: 0,
				maxPadding: 0,
			},
			plotOptions: {
				series: {
					groupPadding: 0,
					stacking: "percent",
					pointWidth: 25,
					shadow: false,
					dataLabels: {
						enabled: true,
						align: "center",
						color: "black",
						style: {
							textOutline: false,
						},
						formatter: function () {
							return (
								this.y +
								" (" +
								Highcharts.numberFormat(this.percentage, 0) +
								"%)"
							);
						},
					},
				},
			},
			yAxis: {
				gridLineColor: "transparent",
				gridLineWidth: 0,
				lineColor: "transparent",
				lineWidth: 0,
				labels: {
					enabled: false,
				},
				title: {
					enabled: false,
				},
				tickWidth: 0,
			},
			series: seriousData,
		});
	};

	useEffect(() => {
		var usrData = getUserData();
		setUserData(usrData);

		setDateRange([
			new Date(`${moment().subtract(8, "days").format("LL")} 00:00:00`),
			new Date(`${moment().subtract(1, "days").format("LL")} 23:59:59`),
		]);
		//setFrequency({ label: 'Today', value: 'Today' });
		if (usrData && usrData.roleId === 1) {
			//Admin
			//GetSitesByClient
			// dispatch(
			// 	getSitesByClient({
			// 		userId: usrData.id,
			// 		userToken: usrData.userToken,
			// 		clientId: usrData.clientId,
			// 	})
			// );
			// get all by client doesnt exist
			getAllSites(usrData);
			//Get all users
			// dispatch(getUsers({ userId: usrData.id, userToken: usrData.userToken }));

			getAllUsers(usrData);

			// new call added for single user data
			getSingleUser(usrData);

			//Get all site points
			// dispatch(
			// 	getAllSamplePlans({ userId: usrData.id, userToken: usrData.userToken })
			// );

			getSamplePlans(usrData);
		} else {
			//Get single site : TODO..
			//dispatch(getSitesByClient({ userId: userData.id, userToken: userData.userToken, clientId: usrData.clientId }));
		}
	}, []);

	const getAllSites = async (usrData) => {
		const response = await siteApi.getAllSites({
			userId: usrData.id,
			userToken: usrData.userToken,
			clientId: usrData.clientId,
		});
		if (response.status >= 200 && response.status <= 299) {
			// dont need below - only options
			setSiteData({
				...siteData,
				sites: response.data,
				isSitesLoaded: true,
			});
			setSiteOptions(response.data);
			let options = [];
			response.data.map((item) => {
				options.push({ label: item.name, value: item.id });
			});
			setSiteOptions(options);
			setError(false);
		} else {
			setError(response.statusText);
		}
		return response;
	};

	const getAllUsers = async (usrData) => {
		const response = await userApi.getAllUsers({
			userId: usrData.id,
			userToken: usrData.userToken,
		});
		if (response.status >= 200 && response.status <= 299) {
			setUsersData({
				...usersData,
				usersResult: response.data,
				isUsersLoaded: true,
			});
			setError(false);
			setUserOptions(response.data);
		} else {
			setError(response.statusText);
		}
		return response;
	};

	const getSingleUser = async (usrData) => {
		const response = await userApi.getSingleUser({
			userId: usrData.id,
			userToken: usrData.userToken,
			id: usrData.id,
		});
		if (response.status >= 200 && response.status <= 299) {
			setUsersData({
				...usersData,
				user: response.data,
				isUserLoaded: true,
			});
			setError(false);
		} else {
			setError(response.statusText);
		}
		return response;
	};

	const getSamplePlans = async (usrData) => {
		const response = await samplePlanApi.getSamplePlans({
			userId: usrData.id,
			userToken: usrData.userToken,
		});
		if (response.status >= 200 && response.status <= 299) {
			setSampleplanData({
				...sampleplanData,
				samplePlans: response.data,
				isSamplePlansLoaded: true,
			});
			setError(false);
		} else {
			setError(response.statusText);
		}
		return response;
	};

	const getGraphData = async (data) => {
		setIsDashboardDataLoaded(false);
		// dispatch(getDashboardData(data));
		const response = await dashboardApi.getDashboardData(data);
		if (response.status >= 200 && response.status <= 299) {
			setDashboardData({
				...dashboardData,
				graphData: response.data,
				isDashboardDataLoaded: true,
			});
			setGraphData(response.data);
		} else {
			setError(response.statusText);
		}
	};

	useEffect(() => {
		getSiteOptions();
		getSamplePlanOptions();
		refreshGraphData();
		// getUserOptions();
	}, [siteData, sampleplanData, dashboardData, error, graphData, selectedUser]);

	useEffect(() => {
		getUserOptions();
	}, [usersData]);

	const getUserOptions = async () => {
		//UserSata
		if (usersData && !error) {
			// if (usersData.isUsersLoaded && !isAllUsersLoaded) {
			// if (usersData.usersResult && usersData.usersResult.length > 0) {
			if (userOptions && userOptions.length > 0) {
				// setAllUsers(usersData.usersResult);
				setIsAllUsersLoaded(true);
				var uOptions = [
					{
						label: "All",
						value: 0,
					},
				];
				userOptions.forEach((item) => {
					uOptions.push({ label: item.username, value: item.id });
				});
				setUserOptions(uOptions);
				if (uOptions.length > 1) {
					setSelectedUser(uOptions[0]);
					setSelectedUserId(uOptions[0].value);
				}
				// }
			}
		}
	};

	const getSiteOptions = () => {
		//Site Data
		if (siteData && siteData.sites) {
			if (siteData.isSitesLoaded && !isSitesLoaded) {
				var options = [];
				siteData.sites.forEach((item) => {
					options.push({ label: item.name, value: item.id });
				});
				setSiteOptions(options);
				if (options.length > 0) {
					setSelectedSite(options[0]);
					if (selectedSiteId === 0) {
						setSelectedSiteId(options[0].value);
					}
				}
				setIsSitesLoaded(true);
				siteData.isSitesLoaded = false;
			}
		}
	};

	const getSamplePlanOptions = () => {
		//Sample plan data
		if (sampleplanData) {
			if (sampleplanData.isSamplePlansLoaded && !isSamplePlansLoaded) {
				var pOptions = [
					{
						label: "All",
						value: 0,
					},
				];
				sampleplanData.samplePlans.forEach((item) => {
					pOptions.push({ label: item.name, value: item.id });
				});
				setSamplePlanOptions(pOptions);
				if (selectedSamplePlanId === 0 && pOptions.length > 0) {
					setSelectedSamplePlanId(pOptions[0].value);
				}
				if (pOptions.length > 0) {
					setSelectedSamplePlan(pOptions[0]);
				}
				setIsSamplePlansLoaded(true);
			}
		}
	};

	const refreshGraphData = () => {
		//Graph Data
		if (dashboardData) {
			refreshGraph(dashboardData.graphData, true, true, false);
			// if (dashboardData.isDashboardDataLoaded && !isDashboardDataLoaded) {
			// 	dashboardData.isDashboardDataLoaded = false;
			// 	setIsDashboardDataLoaded(true);
			// 	setGraphData(dashboardData.graphData);
			// 	refreshGraph(dashboardData.graphData, true, true, false);
			// }

			// if (dashboardData.isDownloadReportLoaded && !isDownloadReportLoaded) {
			// 	dashboardData.isDownloadReportLoaded = false;
			// 	setIsDownloadReportLoaded(true);
			// 	if (
			// 		dashboardData &&
			// 		dashboardData.downloadReportData &&
			// 		dashboardData.downloadReportData.fileContents
			// 	) {
			// 		covertBase64ToFile(
			// 			dashboardData.downloadReportData.fileContents,
			// 			dashboardData.downloadReportData.fileDownloadName,
			// 			dashboardData.downloadReportData.contentType
			// 		);
			// 	} else {
			// 		setShowAlert(true);
			// 		setAlertMessage("No Report Found");
			// 		setIsSuccessAlert(false);
			// 	}
			// }

			// if (dashboardData.isDownloadDataLoaded && !isDownloadDataLoaded) {
			// 	dashboardData.isDownloadDataLoaded = false;
			// 	setIsDownloadDataLoaded(true);
			// 	if (
			// 		dashboardData &&
			// 		dashboardData.downloadData &&
			// 		dashboardData.downloadData.fileContents
			// 	) {
			// 		covertBase64ToFile(
			// 			dashboardData.downloadData.fileContents,
			// 			dashboardData.downloadData.fileDownloadName,
			// 			dashboardData.downloadData.contentType
			// 		);
			// 	} else {
			// 		setShowAlert(true);
			// 		setAlertMessage("No Data Found");
			// 		setIsSuccessAlert(false);
			// 	}
			// }
		}

		//To show the graph on afer page load
		if (
			selectedSiteId > 0 &&
			selectedSamplePlanId > 0 &&
			selectedUserId > 0 &&
			graphData == null &&
			!loadedInitGraphData
		) {
			setLoadedInitGraphData(true);

			let reqData = {
				siteId: selectedSiteId,
				userId: userData.id,
				userToken: userData.userToken,
				clientId: userData.clientId,
				samplePlanId: selectedSamplePlanId,
				userIdFilter: selectedUserId,
				dateFrom: moment(startDate, "yyyy-mm-dd"),
				dateTo: moment(endDate, "yyyy-mm-dd"),
			};
			getGraphData(reqData);
		}

		if (!showedInitGraph && graphData != null) {
			refreshGraph(graphData, true, true, true);
			setShowedInitGraph(true);
		}
	};

	const onSiteChange = (event) => {
		if (event.value === null) {
			return;
		}
		setSelectedSiteId(event.value);
		if (siteOptions) {
			setSelectedSite(siteOptions.find((o) => o.value === event.value));
		}

		let reqData = {
			siteId: event.value,
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
			samplePlanId: selectedSamplePlanId,
			userIdFilter: selectedUserId,
			dateFrom: moment(startDate, "yyyy-mm-dd"),
			dateTo: moment(endDate, "yyyy-mm-dd"),
		};
		getGraphData(reqData);
	};

	const onUserChange = (event) => {
		if (event.value === null) {
			return;
		}

		setSelectedUserId(event.value);
		if (userOptions && users) {
			// setSelectedUser(userOptions.find((o) => o.value === event.value));
			setSelectedUser(users.find((o) => o.value === event.value));
		}

		let reqData = {
			siteId: selectedSiteId,
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
			samplePlanId: selectedSamplePlanId,
			userIdFilter: event.value,
			dateFrom: moment(startDate, "yyyy-mm-dd"),
			dateTo: moment(endDate, "yyyy-mm-dd"),
		};

		getGraphData(reqData);
	};

	const onPlanChange = (event) => {
		if (event.value === null) {
			return;
		}
		setSelectedSamplePlanId(event.value);
		if (samplePlanOptions) {
			setSelectedSamplePlan(
				samplePlanOptions.find((o) => o.value === event.value),
			);
		}

		let reqData = {
			siteId: selectedSiteId,
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
			samplePlanId: event.value,
			userIdFilter: selectedUserId,
			dateFrom: moment(startDate, "yyyy-mm-dd"),
			dateTo: moment(endDate, "yyyy-mm-dd"),
		};
		getGraphData(reqData);
	};

	// const onDateChange = (dates) => {
	// 	setDateRange([
	// 		dates[0]
	// 			? new Date(`${moment(dates[0]).format("LL")} 00:00:00`)
	// 			: dates[0],
	// 		dates[1]
	// 			? new Date(`${moment(dates[1]).format("LL")} 23:59:59`)
	// 			: dates[1],
	// 	]);

	// 	if (dates[0] && dates[1]) {
	// 		let reqData = {
	// 			siteId: selectedSiteId,
	// 			userId: userData.id,
	// 			userToken: userData.userToken,
	// 			clientId: userData.clientId,
	// 			samplePlanId: selectedSamplePlanId,
	// 			userIdFliter: selectedUserId,
	// 			dateFrom: moment(startDate, "yyyy-mm-dd"),
	// 			dateTo: moment(endDate, "yyyy-mm-dd"),
	// 		};
	// 		getGraphData(reqData);
	// 	}
	// };

	const onDateChange = (dates) => {
		const [start, end] = dates;

		// Set the date range for UI display
		setDateRange([
			start ? new Date(`${moment(start).format("LL")} 00:00:00`) : start,
			end ? new Date(`${moment(end).format("LL")} 23:59:59`) : end,
		]);

		if (start && end) {
			// Format dates for API call
			const dateFrom = moment(start).startOf("day").toISOString();
			const dateTo = moment(end).endOf("day").toISOString();

			let reqData = {
				siteId: selectedSiteId,
				userId: userData.id,
				userToken: userData.userToken,
				clientId: userData.clientId,
				samplePlanId: selectedSamplePlanId,
				userIdFilter: selectedUserId,
				dateFrom: dateFrom,
				dateTo: dateTo,
			};
			getGraphData(reqData);
		}
	};

	const handleCalendarClose = () => {
		let reqData = {
			siteId: selectedSiteId,
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
			samplePlanId: selectedSamplePlanId,
			userIdFilter: selectedUserId,
			dateFrom: moment(startDate, "yyyy-mm-dd"),
			dateTo: moment(endDate, "yyyy-mm-dd"),
		};
		getGraphData(reqData);
	};

	const handlePopup = async (event, rptType) => {
		event.preventDefault();
		setPopVisibility(!popVisible);
		var input = {
			siteId: selectedSiteId,
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
			samplePlanId: selectedSamplePlanId,
			userIdFilter: selectedUserId,
			dateFrom: moment(startDate, "yyyy-mm-dd"),
			dateTo: moment(endDate, "yyyy-mm-dd"),
		};
		let response;
		if (rptType === "Report") {
			setIsDownloadReportLoaded(false);
			response = await dashboardApi.getDownloadReport(input);
		}
		if (rptType === "Data") {
			setIsDownloadDataLoaded(false);
			response = await dashboardApi.getDownloadData(input);
		}

		if (response) {
			try {
				const barrel64 = response.data.fileContents;
				if (/^[A-Za-z0-9+/]+={0,2}$/.test(barrel64)) {
					const decodedData = atob(barrel64);
					const dataArray = new Uint8Array(decodedData.length);

					for (let i = 0; i < decodedData.length; i++) {
						dataArray[i] = decodedData.charCodeAt(i);
					}

					const blob = new Blob([dataArray.buffer], {
						type: "application/csv",
					});
					const link = document.createElement("a");

					link.href = window.URL.createObjectURL(blob);
					link.download = response.data.fileDownloadName;
					link.click();
				} else {
					console.error("Invalid base64 string");
				}
			} catch (error) {
				console.error("Error downloading barrel end:", error);
			}
		}
	};

	const [archiveFilterData, setArchiveFilterData] = useState({
		siteId: 0,
		samplePlanId: 0,
		testPointId: 0,
		userId: 0,
		dateFrom: null,
		dateTo: null,
	});

	const cancelButtonRef = useRef(null);
	const [filterDialog, setFilterDialog] = useState(false);

	const [selectedSiteFilter, setSelectedSiteFilter] = useState(null);
	const [selectedSamplePlanFilter, setSelectedSamplePlanFilter] =
		useState(null);
	const [selectedTestPointFilter, setSelectedTestPointFilter] = useState(null);
	const [selectedUserFilter, setSelectedUserFilter] = useState(null);
	const [selectedDateFilter, setSelectedDateFilter] = useState([null, null]);

	const updateSiteFilter = (event) => {
		if (event.value === null) {
			return;
		}
		setArchiveFilterData({
			...archiveFilterData,
			siteId: event.value,
		});
		if (siteOptions) {
			setSelectedSiteFilter(siteOptions.find((o) => o.value === event.value));
		}
	};

	const updateSamplePlanFilter = (event) => {
		if (event.value === null) {
			return;
		}
		setArchiveFilterData({
			...archiveFilterData,
			samplePlanId: event.value,
		});
		if (samplePlanOptions) {
			setSelectedSamplePlanFilter(
				samplePlanOptions.find((o) => o.value === event.value),
			);
		}
	};

	const updateTestPointFilter = (event) => {
		if (event.value === null) {
			return;
		}
		setArchiveFilterData({
			...archiveFilterData,
			testPointId: event.value,
		});
		if (testPointOptions) {
			setSelectedTestPointFilter(
				testPointOptions.find((o) => o.value === event.value),
			);
		}
	};

	const updateDateFilter = (dates) => {
		setArchiveFilterData({
			...archiveFilterData,
			dateFrom: dates[0],
			dateTo: dates[1],
		});
		setSelectedDateFilter(dates);
	};

	const handleClearFilters = () => {
		setArchiveFilterData({
			siteId: 0,
			samplePlanId: 0,
			testPointId: 0,
			userId: 0,
			dateFrom: null,
			dateTo: null,
		});
		setSelectedSiteFilter(null);
		setSelectedSamplePlanFilter(null);
		setSelectedTestPointFilter(null);
		setSelectedUserFilter(null);
		setSelectedDateFilter([null, null]);
	};

	const handleCancelFilters = () => {
		setFilterDialog(false);
		setPopVisibility(false);
	};

	const handleDropdownClick = (event) => {
		event.preventDefault();
		setPopVisibility(!popVisible);
	};

	const [emptyArchiveFilterDateError, setEmptyArchiveFilterDateError] =
		useState(false);

	const handleArchiveSave = async () => {
		setEmptyArchiveFilterDateError(false);
		if (!archiveFilterData.dateFrom || !archiveFilterData.dateTo) {
			setEmptyArchiveFilterDateError(true);
			return;
		}

		// get the user data
		const userData = getUserData();

		var input = {
			siteId: archiveFilterData.siteId,
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
			samplePlanId: archiveFilterData.samplePlanId,
			dateFrom: moment(archiveFilterData.dateFrom, "yyyy-mm-dd"),
			dateTo: moment(archiveFilterData.dateTo, "yyyy-mm-dd"),
			archiveId: 0,
		};

		const response = await testApi.RequestArchive(input);

		// the response will be a string of the user email
		if (response) {
			if (response.status === 200) {
				showSweetAlert("successOkOnly", response.data);
				handleCancelFilters();
				handleClearFilters();
			} else {
				let errorMessage = response?.data ?? "Something went wrong.";
				showSweetAlert("errorCustomMessage", errorMessage);
			}
		}
	};

	React.useEffect(() => {
		getTestPointData();
	}, []);

	const [testPointOptions, setTestPointOptions] = useState([]);

	const getTestPointData = async () => {
		const usrData = getUserData();

		const response = await testPointApi.getAllTestPoint({
			userId: usrData.id,
			userToken: usrData.userToken,
		});
		if (response.status === 200) {
			var tOptions = [];
			response.data.forEach((item) => {
				tOptions.push({ label: item.name, value: item.id });
			});
			setTestPointOptions(tOptions);
		}
	};

	const returnFilterModal = () => {
		return (
			<Transition.Root show={filterDialog} as={Fragment}>
				<Dialog
					as="div"
					className="fixed z-10 inset-0 overflow-y-auto"
					initialFocus={cancelButtonRef}
					onClose={setFilterDialog}
				>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="hidden sm:inline-block sm:align-middle sm:h-screen"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="inline-block w-1/2 align-bottom bg-white font-large rounded-2xl px-4 pt-5 pb-4 text-left transform transition-all sm:align-middle sm:px-10">
								<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
									<button
										type="button"
										className="bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
										onClick={() => handleCancelFilters()}
									>
										<span className="sr-only">Close</span>
										<CloseIcon />
									</button>
								</div>
								<div className="sm:flex sm:items-start">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center w-full mb-5">
										<Dialog.Title
											as="h3"
											className="text-lg leading-6  text-gray-500"
										>
											Filter
										</Dialog.Title>
									</div>
								</div>
								<form>
									<div className="flex">
										<div className="block pl-1 text-fclabel w-1/5 align-middle">
											Site
										</div>
										<div className="w-2/5 align-top">
											<FCSelectSiteField
												id="sites"
												onChange={(event) => updateSiteFilter(event)}
												options={siteOptions}
												value={selectedSiteFilter}
												className="ml-1"
											/>
										</div>
									</div>
									<div>&nbsp;</div>
									<div className="flex">
										<div className="block pl-1 text-fclabel w-1/5">
											Sample Plan
										</div>
										<div className="w-2/5">
											<FCSelectSiteField
												id="plans"
												onChange={(event) => updateSamplePlanFilter(event)}
												options={samplePlanOptions}
												value={selectedSamplePlanFilter}
												className="ml-1"
											/>
										</div>
									</div>
									{/* 
									<div>&nbsp;</div>
									<div className="flex">
										<div className="block pl-1 text-fclabel w-1/5">
											Test Point 
										</div>
										<div className="w-2/5">
											<FCSelectSiteField
												id="testpoints"
												onChange={(event) => updateTestPointFilter(event)}
												options={testPointOptions}
												value={selectedTestPointFilter}
												className="ml-1"
											/>
										</div>
									</div>
									*/}
									<div>&nbsp;</div>
									<div className="flex">
										<div className="block pl-1 text-fclabel w-1/5">Date</div>
										<div className="w-2/5">
											<DatePicker
												dateFormat="dd/MM/yyyy"
												selectsRange
												startDate={archiveFilterData.dateFrom}
												endDate={archiveFilterData.dateTo}
												onChange={(value) => updateDateFilter(value)}
												className={`border-2 p-2 rounded-2xl ml-1 blueText w-full ${
													emptyArchiveFilterDateError
														? "border-red-500"
														: "border-gray-300"
												}`}
												wrapperClassName="w-full"
											/>
											{emptyArchiveFilterDateError && (
												<p className="text-red-500 pl-3 text-sm mt-1">
													Please select a valid date range
												</p>
											)}
										</div>
									</div>
									<div>&nbsp;</div>
									<div className="pb-5 sm:items-center">
										<div className="mt-3 flex sm:mt-0">
											<div className="w-3/5 align-middle flex">
												<div className="w-1/3">&nbsp;</div>
												<div className="w-1/3 align-middle">
													<FCButtonWithClick
														onHandleClick={handleClearFilters}
														bgColor="bg-fcbutton mr-3"
														type="button"
														icon=""
														value="Clear"
													/>
												</div>
												<div className="w-1/3">&nbsp;</div>
											</div>
											<div className="w-1/5 align-top">
												<FCButtonWithClick
													onHandleClick={handleCancelFilters}
													bgColor="bg-fcbutton mr-3"
													type="button"
													icon=""
													value="Cancel"
												/>
											</div>
											<div className="w-1/5 align-top">
												<FCButtonWithClick
													bgColor="bg-primary mr-3"
													type="button"
													onHandleClick={(event) => handleArchiveSave(event)}
													icon=""
													value="Save"
												/>
											</div>
										</div>
									</div>
								</form>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		);
	};

	const handleArchivePopup = (event) => {
		event.preventDefault();
		setEmptyArchiveFilterDateError(false);
		const endOfDay = moment()
			.endOf("day")
			.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
		const adjustedEndOfDay = endOfDay.add(endOfDay.utcOffset(), "minutes");
		setArchiveFilterData((prev) => ({
			...prev,
			dateFrom: moment().subtract(30, "days").startOf("day").toDate(),
			dateTo: adjustedEndOfDay.toDate(),
		}));
		// display the filters modal
		setFilterDialog(true);
	};

	const handlePrint = (event) => {
		event.preventDefault();
		setPopVisibility(!popVisible);
		setTimeout(
			function () {
				window.print();
			}.bind(this),
			1000,
		);
	};

	const handleOrderByTestToggle = (event) => {
		setOrderByTestToggle(event);
		refreshGraph(graphData, false, true, event);
	};

	const handleFrequencyChange = (event) => {
		setFrequency({ label: event.value, value: event.value });
		refreshCompletedTestGraph(completedTestPoints, event.value);
	};

	const closeDialog = () => {
		setShowAlert(false);
	};

	const [users, setUsers] = useState([]);

	useEffect(() => {
		let uOptions = [
			{
				label: "All",
				value: 0,
			},
		];

		usersData.usersResult.forEach(function (item) {
			uOptions.push({ label: item.username, value: item.id });
		});
		setUsers(uOptions);
	}, [usersData]);

	return (
		<div className="section-to-print">
			<div className="max-w-7xl mt-3">
				{showAlert && (
					<FCMessageDialog
						isSuccess={isSuccessAlert}
						modalCallBack={() => closeDialog()}
						message={alertMessage}
					/>
				)}
				<div className="w-full flex pb-3 border-b-2 border-gray-300">
					<div className="w-3/5 flex">
						<div className="w-5/12">
							<div className="block pl-1 text-fclabel">Select Site</div>
							<FCSelectSiteField
								id="sites"
								onChange={(event) => onSiteChange(event)}
								options={siteOptions}
								value={selectedSite}
								className="w-full"
							/>
						</div>
						<div className="w-1/12"></div>
						<div className="w-1/2 px-5">
							<div className="block pl-1 text-fclabel">Select Sample Plan</div>
							<FCSelectSiteField
								id="plans"
								onChange={(event) => onPlanChange(event)}
								options={samplePlanOptions}
								value={selectedSamplePlan}
								className="w-full"
							/>
						</div>
					</div>

					{/* Filter Modal for archiving */}
					<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
						{returnFilterModal()}
					</div>

					<div className="w-2/5 flex justify-end items-end">
						<button ref={setReferenceRef} onClick={handleDropdownClick}>
							<DotVerticalSvgSvgIcon />
						</button>
						<div
							ref={setPopperRef}
							style={styles.popper}
							{...attributes.popper}
							className={
								!popVisible
									? "hidden"
									: "w-26 rounded-2xl border-2 border-gray z-10"
							}
						>
							<div
								className="rounded-2xl border-2 border-white"
								style={{ backgroundColor: "#fff" }}
							>
								<a
									href="#"
									className="block flex flex-inline w-full py-1"
									onClick={(event) => handlePrint(event)}
								>
									<PlusCircleSvgIconTransparent /> Print Report
								</a>
								<a
									href="#"
									className="block flex flex-inline w-full py-1"
									onClick={(event) => handlePopup(event, "Report")}
								>
									<PlusCircleSvgIconTransparent /> Download Report
								</a>
								<a
									href="#"
									className="block flex flex-inline w-full py-1"
									onClick={(event) => handlePopup(event, "Data")}
								>
									<PlusCircleSvgIconTransparent /> Download Data
								</a>
								<a
									href="#"
									className="block flex flex-inline w-full py-1"
									onClick={(event) => handleArchivePopup(event)}
								>
									<PlusCircleSvgIconTransparent /> Request Archive
								</a>
							</div>
						</div>
					</div>
				</div>

				<div className="w-full flex pt-1 border-b-2 border-gray-300">
					<div className="w-1/4">
						<div className="block pl-1 text-fclabel">Period</div>
						<div className="flex">
							<Select
								value={frequency}
								onChange={(event) => handleFrequencyChange(event)}
								name="frequency"
								options={frequencyOptions}
								styles={Styles}
								className="basic-multi-select border-2 rounded-2xl border-gray-300 text-fcinputtext w-full"
								classNamePrefix="select"
							/>
						</div>
					</div>
					<div className="w-3/4">
						<div className="flex w-full pl-2">
							<HighchartsReact
								containerProps={{
									style: { height: "80px", width: "90%", padding: "0" },
								}}
								highcharts={Highcharts}
								options={barSingleOptions}
								// ref={barChartSingleComponentRef}
								ref={barChartSingleComponentRef}
							/>
						</div>
					</div>
				</div>
				<div className="w-full flex pb-3 pt-1">
					<div className="w-3/5 flex">
						<div className="w-5/12">
							<div className="block pl-1 text-fclabel">Select User</div>
							<FCSelectSiteField
								id="users"
								onChange={(event) => onUserChange(event)}
								options={users}
								value={selectedUser}
								className="w-full"
							/>
						</div>
						<div className="w-1/12"></div>
						<div className="w-1/2 px-5">
							<div className="block pl-1 text-fclabel">Select Date Range</div>
							<DatePicker
								dateFormat={"dd/MM/yyyy"}
								selectsRange={true}
								startDate={startDate}
								endDate={endDate}
								onChange={(value) => onDateChange(value)}
								onCalendarClose={() => handleCalendarClose()}
								className="border-2 border-gray-300 p-2 rounded-2xl blueText w-full"
							/>
						</div>
					</div>
					<div className="w-2/5 flex justify-center">
						<ToggleSwitch
							checked={orderByTestToggle}
							name="dailyAlert"
							disableText="Order Fail Rate"
							enableText="Order by Point"
							disableColor="bg-primary"
							enableColor="bg-primary"
							onChange={(event) => handleOrderByTestToggle(event)}
						/>
					</div>
				</div>

				<div className="w-full flex">
					<div className="w-3/5 flex">
						<div className="w-2/5">
							<div className="flex flex-col items-start">
								<div className="block pl-1 text-fclabel text-center align-middle justify-center w-full">
									Total Test Results
								</div>
								<HighchartsReact
									highcharts={Highcharts}
									options={pieOptions}
									// ref={pieChartComponentRef}
									ref={pieChartComponentRef}
									containerProps={{ style: { height: "250px", width: "100%" } }}
								/>
							</div>
							<div>
								<div className="block pl-1 text-fclabel text-center">
									Retest Success Rate
								</div>
								<div className="flex justify-center">
									<span className="block w-1/2 text-center blueText p-1 border-2 border-gray-300 rounded rounded-2xl">
										{totalRetests === 0
											? "No retests completed"
											: successRate !== undefined && totalRetests !== undefined
												? successRate?.toFixed(0) +
													"% (" +
													totalRetests +
													"tests)"
												: ""}
									</span>
								</div>
							</div>
						</div>

						<div className="w-3/5 ">
							<div>
								<div className="block pl-1 text-fclabel text-center">
									Fail Rate
								</div>
								<div className="flex justify-center">
									<span className="block w-1/2 text-center blueText p-1 border-2 border-gray-300 rounded rounded-2xl">
										{failRate === 0
											? "No failed tests"
											: failRate !== undefined && totalFail !== undefined
												? failRate?.toFixed(0) + "% (" + totalFail + "tests)"
												: ""}
									</span>
								</div>
							</div>
							<div className="w-full flex justify-center">
								<div className="block w-4/5">
									<div className="block pl-1 text-fclabel text-center pt-4">
										Failed Test Points
									</div>
									<div className="border-2 border-gray-300 rounded-2xl h-60 mx-5 p-4">
										<div className="w-full flex">
											<div className="w-2/3 pl-1 text-fclabel">Name</div>
											<div className="w-1/3 pl-1 text-fclabel">Fails</div>
										</div>
										<div className="w-full h-44 overflow-y-scroll">
											{failedTestPoints &&
												failedTestPoints.map((item, index) => (
													<div className="w-full flex blueText" key={index}>
														<div className="w-2/3 pl-1">{item.name}</div>
														<div className="w-1/3 pl-1">{item.totalFails}</div>
													</div>
												))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="w-9/10 border-2 border-gray-300 rounded-2xl overflow-hidden">
						<div className="w-full barChart" id="container">
							<HighchartsReact
								containerProps={{ style: { height: "350px" } }}
								highcharts={Highcharts}
								options={barOptions}
								ref={barChartComponentRef}
								className="w-full pr-4"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Landing;
