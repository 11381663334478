import { Http } from "../Http";
import { AxiosPromise } from "axios";
import authHeader from "../common/functions";
import apiCall from "../api/apiCall";

const sampleplanQuoteApi = {
	getTemplates: async (input) => {
		const url =
			"SamplePlanQuoteTool/GetTemplates?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken;
		const response = await apiCall(url, "get");
		return response;
	},

	getTemplateList: async () => {
		const url = "SamplePlanQuoteTool/GetTemplateList";
		const response = await apiCall(url, "get");
		return response;
	},

	// doesnt exist anymore
	// getTemplateById: async (templateId) => {
	// 	const url = "SamplePlanQuoteTool/GetTemplateById?templateId=" + templateId;
	// 	const response = await apiCall(url, "get");
	// 	return response;
	// },

	getTemplate: async (input) => {
		const url =
			"SamplePlanQuoteTool/GetTemplate" + "?templateId=" + input.templateId;
		const response = await apiCall(url, "get");
		return response;
	},

	saveSamplePlanQuote: async (input) => {
		const obj = JSON.stringify(input);
		const url =
			"SamplePlanQuoteTool/SaveSamplePlan" +
			"?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken;
		const response = await apiCall(url, "post", obj);
		return response;
	},
};

export default sampleplanQuoteApi;

// export class SampleplanQuoteApi {

//   public static getTemplates(input: any) {
//     return Http.axios().get('/SamplePlan/GetTemplates?userId=' + input.userId + "&userToken=" + input.userToken)
//       .catch((e) => {
//         return e.response;
//       }) as AxiosPromise<any>;
//   }

// 	public static getTemplateList() {
// 		return Http.axios().get('/SamplePlan/GetTemplateList')
// 			.catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}

// 	public static getTemplateById(templateId: any) {
// 		return Http.axios().get('/SamplePlan/GetTemplateById?templateId=' + templateId)
// 			.catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}

//   public static getTemplate(input: any) {
//     return Http.axios().get('/SamplePlan/GetTemplate?userId='
//       + input.userId
//       + "&userToken=" + input.userToken
//       + "&templateId=" + input.templateId)
//       .catch((e) => {
//         return e.response;
//       }) as AxiosPromise<any>;
//   }

//   public static saveSamplePlanQuote(input: any) {
//     var obj = JSON.stringify(input);
//     return Http.axios().post('/SamplePlan/SaveSamplePlan', obj, { headers: { 'Content-Type': 'application/json' } })
//       .then(response => {
//         return response;
//       }).catch((e) => {
//         return e.response;
//       }) as AxiosPromise<any>;
//   }
// }
