import * as React from "react";
import { useState } from "react";
import FCSiteBox from "../../utils/library/sitebox";
import FCSiteInputField from "../../utils/library/sitetextbox";
import { Validators, validateInput } from "../../utils/library/Validator";
import FCButtonWithClick from "../../utils/library/buttonwithclick";
import FCMessageDialog from "../../utils/library/dialog";
import FCConfirmAlert from "../../utils/library/confirmalert";
import Select from "react-select";
import "react-table-6/react-table.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../custom.css";
import {
	PlusCircleSvgIcon,
	TrashSvgIcon,
	EditSvgIcon,
} from "../../utils/common/icon-hooks.js";
import FCInputSearchField from "../../utils/library/textboxsearch";
import {
	Countries,
	getUserData,
	getCountryByCode,
} from "../../utils/common/functions";
import { showSweetAlert } from "../Shared/CustomAlert";

import siteApi from "../../utils/api/siteApi";

const Site = () => {
	const site = {
		deleted: false,
		id: 0,
		clientId: 0,
		userId: 0,
		userToken: "",
		name: "",
		addressLine1: "",
		addressLine2: "",
		town: "",
		city: "",
		county: "",
		postcode: "",
		country: "GB",
		selected: false,
	};

	const initialState = {
		userId: 0,
		usertoken: "1234",
		isError: false,
		isSaved: false,
		isSiteLoaded: false,
		isSitesLoaded: false,
		isAllSitesLoaded: false,
		isSiteSaved: false,
		isSiteDeleted: false,
		sites: [site],
		site: site,
	};

	const validators = [
		{
			check: Validators.required,
			message: "All fields are required.",
		},
	];

	const [isNameEntered, setIsNameEntered] = useState(false);
	const [isAddress1Entered, setIsAddress1Entered] = useState(false);
	const [isTownEntered, setIsTownEntered] = useState(false);
	const [isCityEntered, setIsCityEntered] = useState(false);
	const [isPostcodeEntered, setIsPostcodeEntered] = useState(false);
	const [sites, setSites] = useState([site]);
	// new state below
	const [siteData, setSiteData] = useState(initialState);
	const [showAlert, setShowAlert] = useState(false);
	const [addMode, setAddMode] = useState(false);
	const [viewMode, setViewMode] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [isSuccessAlert, setIsSuccessAlert] = useState(false);
	const [selectedSiteId, setSelectedSiteId] = useState(0);
	const [selectedSite, setSelectedSite] = useState(site);
	const [isSiteSaved, setIsSiteSaved] = useState(false);
	const [isSitesLoaded, setIsSitesLoaded] = useState(false);
	const [isRequiredError, setIsRequiredError] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [isSiteDeleted, setIsSiteDeleted] = useState(false);
	const [isShowConfirmAlert, setIsShowConfirmAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	const [userData, setUserData] = useState(null);
	const [previousSite, setPreviousSite] = useState(site);
	const [selectedCountry, setSelectedCountry] = useState(Countries[0]);

	const onInputChange = (event) => {
		if (event.name === "name") {
			setIsNameEntered(validateInput(validators, event.value));
		}
		if (event.name === "addressLine1") {
			setIsAddress1Entered(validateInput(validators, event.value));
		}
		if (event.name === "town") {
			setIsTownEntered(validateInput(validators, event.value));
		}
		if (event.name === "county") {
			setIsCityEntered(validateInput(validators, event.value));
		}
		if (event.name === "postcode") {
			setIsPostcodeEntered(validateInput(validators, event.value));
		}

		setSelectedSite((prevState) => ({
			...prevState,
			[event.name]: event.value,
		}));
	};

	const onCountryChange = (event) => {
		setSelectedSite((prevState) => ({
			...prevState,
			country: event.value,
		}));
		setSelectedCountry(getCountryByCode(event.value));
	};

	const handleSearch = (txtValue) => {
		setSearchText(txtValue);
		if (txtValue === "") {
			setSites(siteData.sites);
		} else {
			if (siteData.sites) {
				var result = siteData.sites.filter((item) => {
					return item.name.toLowerCase().indexOf(txtValue.toLowerCase()) >= 0;
				});
				setSites(result);
			}
		}
	};

	const handleAddSite = (event, isNew) => {
		event.preventDefault();
		if (isNew) {
			setPreviousSite(selectedSite);
			setSelectedSite(null);
			setAddMode(true);
			setEditMode(false);
		} else {
			setAddMode(false);
			setEditMode(true);
			setPreviousSite(selectedSite);
		}
		setSelectedSite((prevState) => ({
			...prevState,
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
		}));

		setViewMode(false);
		setShowAlert(false);
	};

	const showDeleteConfirmAlert = (event) => {
		//event.preventDefault();
		setIsShowConfirmAlert(true);
	};

	const handleDeleteSite = async (event) => {
		event.preventDefault();
		setShowAlert(false);
		if (userData) {
			const response = await siteApi.deleteSite({
				userId: userData.id,
				userToken: userData.userToken,
				clientId: userData.clientId,
				id: selectedSite.id,
			});
			if (response.status === 200) {
				showSweetAlert("success", "Site Deleted");
				setIsSiteSaved(true);
				setEditMode(false);
				setIsReload(!isReload);
			}
		}
		setIsSiteDeleted(false);
		setIsShowConfirmAlert(false);
	};

	const handleConfirmCancel = (event) => {
		setIsShowConfirmAlert(false);
	};

	const handleCancel = () => {
		//if (siteData && siteData.sites) {
		//	alert('hi');
		//  setSelectedSite(siteData.sites[0]);
		//}
		setEditMode(false);
		setAddMode(false);
		setViewMode(true);
		setIsRequiredError(false);

		setIsNameEntered(false);
		setIsAddress1Entered(false);
		setIsCityEntered(false);
		setIsTownEntered(false);
		setIsPostcodeEntered(false);
		setSelectedSite(previousSite);
		//setPreviousSite(site);
	};

	const validateNullOrUndefined = () => {
		if (
			typeof selectedSite.name !== "undefined" &&
			selectedSite.name &&
			typeof selectedSite.addressLine1 !== "undefined" &&
			selectedSite.addressLine1 &&
			typeof selectedSite.county !== "undefined" &&
			selectedSite.county &&
			typeof selectedSite.postcode !== "undefined" &&
			selectedSite.postcode &&
			typeof selectedSite.town !== "undefined" &&
			selectedSite.town
		) {
			return true;
		} else {
			return false;
		}
	};

	const [isReload, setIsReload] = useState(false);
	const addorUpdateSite = async () => {
		setIsNameEntered(validateInput(validators, selectedSite.name));
		setIsAddress1Entered(validateInput(validators, selectedSite.addressLine1));
		setIsCityEntered(validateInput(validators, selectedSite.county));
		setIsTownEntered(validateInput(validators, selectedSite.town));
		setIsPostcodeEntered(validateInput(validators, selectedSite.postcode));

		if (validateNullOrUndefined()) {
			setIsRequiredError(false);
			setIsSiteSaved(false);
			// dispatch(saveSite(selectedSite));
			let response;
			if (selectedSite.id > 0) {
				response = await siteApi.updateSite(selectedSite);
			} else {
				response = await siteApi.addSite(selectedSite);
			}
			if (response.status === 200) {
				showSweetAlert("success", "Saved Successfully");
				setIsSiteSaved(true);
				setEditMode(false);
				setIsReload(!isReload);
			} else {
				showSweetAlert("error", "Error occured");
			}
		} else {
			setIsRequiredError(true);
		}
	};

	const onSiteChange = (event, siteId) => {
		event.preventDefault();
		setSelectedSiteId(siteId);

		if (siteData && siteData.sites) {
			var site = siteData.sites.find((o) => o.id === siteId);
			setSelectedSite(site);
			setSelectedCountry(getCountryByCode(site.country));
		}
		setViewMode(true);
		setAddMode(false);
		setEditMode(false);
	};

	React.useEffect(() => {
		var usrData = getUserData();
		setUserData(usrData);
		setSites([]);
		// dispatchGetSites(usrData);
		const getAllSites = async () => {
			const response = await siteApi.getAllSites(usrData);
			setSiteData({ ...siteData, sites: response.data });
		};
		getAllSites();
		setViewMode(true);
		setAddMode(false);
		setEditMode(false);
		setSelectedCountry({ label: "United Kingdom", value: "GB" });
		setSelectedSite((prevState) => ({
			...prevState,
			country: Countries[0].value,
			userId: usrData.id,
			userToken: usrData.userToken,
			clientId: usrData.clientId,
		}));
	}, [isReload]);

	const getAllSites = async (userData) => {
		const response = await siteApi.getAllSites(userData);
		return response;
	};

	if (siteData) {
		if (siteData.isSitesLoaded && !isSitesLoaded) {
			setSites(siteData.sites);
			if (siteData.sites?.length > 0 && selectedSiteId === 0) {
				setSelectedSite(siteData.sites[0]);
				setSelectedSiteId(siteData.sites[0].id);
				var cntry = getCountryByCode(siteData.sites[0].country);
				setSelectedCountry(cntry);
			} else {
				setSelectedSite(siteData.sites.find((o) => o.id === selectedSiteId));
			}
			setIsSitesLoaded(true);
			siteData.isSitesLoaded = false;
		}
		if (siteData.isSiteDeleted && !isSiteDeleted) {
			if (siteData.status.statusValue) {
				const index = sites.findIndex((obj) => obj.id === selectedSite.id);
				const newData = [...sites.slice(0, index), ...sites.slice(index + 1)];
				setSelectedSiteId(siteData.sites[0].id);
				setSelectedSite(siteData.sites[0]);
				setSites(newData);
				setAlertMessage("Deleted successfully.");
				// dispatchGetSites(userData);
				getAllSites(userData);
			}
			setIsSiteDeleted(true);
			setShowAlert(true);
			setIsSuccessAlert(siteData.status.statusValue);
		}
		if (siteData.isSiteSaved && !isSiteSaved) {
			if (siteData.status.statusValue) {
				//setSelectedSiteId(siteData.sites[0].id);
				//setSelectedSite(siteData.sites[0]);
				// dispatchGetSites(userData);
				getAllSites(userData);
				setEditMode(false);
				setAddMode(false);
				setViewMode(true);
				setAlertMessage("Saved successfully.");
			} else {
				setAlertMessage(siteData.status.statusDisplay);
			}
			setShowAlert(true);
			setIsSiteSaved(true);
			setIsSuccessAlert(siteData.status.statusValue);
		}
	}

	const closeDialog = () => {
		setShowAlert(false);
	};

	return (
		<div className="py-1">
			<FCConfirmAlert
				message="Are you sure you want to delete this site?"
				isOpen={isShowConfirmAlert}
				title="Delete Site"
				onOkClick={(event) => handleDeleteSite(event)}
				onCancelClick={(event) => handleConfirmCancel(event)}
			/>
			{showAlert && (
				<FCMessageDialog
					isSuccess={isSuccessAlert}
					modalCallBack={() => closeDialog()}
					message={alertMessage}
				/>
			)}

			<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
				<div>
					<h1 className="text-1xl text-gray-500 text-center">Sites</h1>
				</div>
			</div>
			<div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-2 lg:gap-8 w-full">
				<div className="grid grid-cols-1 gap-4 w-1/2 w-full">
					<section aria-labelledby="section-1-title">
						<div className="overflow-hidden w-full items-center">
							<div className="p-2 w-full">
								<div className="w-full mb-2 mx-auto flex item-center">
									<div className="w-1/6 text-gray-500">Add Site</div>
									<div className="w-5/6">&nbsp;</div>
								</div>
								<div className="overflow-hidden h-1/2 mx-auto w-full flex">
									<div className=" mb-1 text-gray-500 w-1/6">
										<button
											onClick={(event) => handleAddSite(event, true)}
											type="button"
											className="px-3 border border-transparent bg-transparent text-block focus:outline-none"
										>
											<PlusCircleSvgIcon />
										</button>
									</div>
									<div className="pl-7 pr-8 w-full">
										<FCInputSearchField
											placeholder="Search Site"
											id="search"
											onChange={(value) => handleSearch(value)}
											value={searchText}
										/>
									</div>
									<div className="w-1/6">&nbsp;</div>
								</div>
							</div>
							<div>
								<div className="w-full mb-2 mx-auto flex item-center">
									<div className="w-1/6">&nbsp;</div>
									<div className="w-4/6">
										<h2 className="text-1xl font-semibold text-gray-500 text-center py-2">
											Select A Site
										</h2>
									</div>
									<div className="w-1/6">&nbsp;</div>
								</div>
								<div className="h-sitebox overflow-y-auto item-center px-11 mx-auto flex">
									<div className="w-1/6">&nbsp;</div>
									<div className="w-4/6 w-full">
										{siteData?.sites.length === 0 && (
											<h2 className=" text-center align-middle">
												No sites found
											</h2>
										)}
										{siteData?.sites &&
											siteData.sites.map((site) => (
												<FCSiteBox
													key={"key" + site.id}
													id={site.id}
													text={site.name}
													selected={site.id === selectedSiteId ? true : false}
													onClick={(event) => onSiteChange(event, site.id)}
												/>
											))}
									</div>
									<div className="w-1/6">&nbsp;</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				<div className={"grid grid-cols-1 gap-4 w-1/2 w-full"}>
					{(siteData?.sites.length > 0 || addMode) && (
						<section aria-labelledby="section-2-title">
							<div className="overflow-hidden">
								<div className="p-2 mx-auto">
									<div className="w-full overflow-hidden h-1/2 mx-auto">
										<div className="w-3/4 mb-2  mx-auto">
											<h2 className="text-1xl font-semibold text-gray-500 text-center mb-1">
												{addMode && <> Add Site </>}
												{editMode && <> Edit Site </>}
												{viewMode && <> Site Details </>}
											</h2>
										</div>
										<div className="w-3/4 overflow-hidden h-1/2 mx-auto">
											<div
												className={
													"pt-1 " +
													(addMode || editMode
														? " border-2 border-primary rounded-2xl "
														: "")
												}
											>
												<div className="mb-2 w-3/4 mx-auto pt-5">
													<FCSiteInputField
														id="name"
														onChange={(event) => onInputChange(event.target)}
														labelText="Site Name"
														value={selectedSite.name}
														required={true}
														isError={isNameEntered}
														readOnly={viewMode}
													/>
												</div>
												<div className="mb-2 w-3/4 mx-auto">
													<FCSiteInputField
														id="addressLine1"
														onChange={(event) => onInputChange(event.target)}
														labelText="Address 1"
														value={selectedSite.addressLine1}
														required={true}
														isError={isAddress1Entered}
														readOnly={viewMode}
													/>
												</div>
												<div className="mb-2 w-3/4 mx-auto">
													<FCSiteInputField
														id="addressLine2"
														onChange={(event) => onInputChange(event.target)}
														labelText="Address 2"
														value={selectedSite.addressLine2}
														readOnly={viewMode}
													/>
												</div>
												<div className="mb-2 w-3/4 mx-auto">
													<FCSiteInputField
														id="town"
														onChange={(event) => onInputChange(event.target)}
														labelText="Town"
														value={selectedSite.town}
														required={true}
														isError={isTownEntered}
														readOnly={viewMode}
													/>
												</div>
												<div className="mb-2 w-3/4 mx-auto">
													<FCSiteInputField
														id="county"
														onChange={(event) => onInputChange(event.target)}
														labelText="City"
														value={selectedSite.county}
														required={true}
														isError={isCityEntered}
														readOnly={viewMode}
													/>
												</div>
												<div className="mb-3 w-3/4 flex flex-row mx-auto">
													<div className="w-1/2 flex-row">
														<FCSiteInputField
															id="postcode"
															onChange={(event) => onInputChange(event.target)}
															labelText="Postcode"
															value={selectedSite.postcode}
															required={true}
															isError={isPostcodeEntered}
															readOnly={viewMode}
															width="w-3/4"
															className="pr-1 flex-row"
														/>
													</div>
													<div className="w-1/2 flex-row">
														<label
															htmlFor="email"
															className="flex flex-row text-gray-500"
														>
															Country
														</label>
														<Select
															value={selectedCountry}
															onChange={(event) => onCountryChange(event)}
															name="country"
															options={Countries}
															isDisabled={viewMode}
															styles={{
																option: (provided) => ({
																	...provided,
																	color: "#002366",
																}),
																control: (provided, state) => ({
																	...provided,
																	boxShadow: "none",
																	border: "none",
																	backgroundColor: "none",
																}),
																singleValue: (provided) => ({
																	...provided,
																	color: "#002366",
																}),
															}}
															className="basic-multi-select border-2 rounded-2xl border-gray-300 text-fcinputtext"
															classNamePrefix="select"
														/>
													</div>
												</div>
											</div>
											<div className="mt-5 mb-2 w-3/4 flex mx-auto">
												{(addMode || editMode) && (
													<div className="mt-1 w-3/4 flex mx-auto">
														<FCButtonWithClick
															onHandleClick={handleCancel}
															bgColor="bg-fcbutton mr-3"
															type="button"
															icon=""
															value="Cancel"
														/>
														<FCButtonWithClick
															onHandleClick={addorUpdateSite}
															bgColor="bg-primary"
															type="button"
															icon=""
															value="Save"
														/>
													</div>
												)}
												{viewMode && (
													<div className="mt-1 w-4/5">
														<button
															type="button"
															onClick={(event) => handleAddSite(event, false)}
															className={
																"float-right inline-flex items-center py-3 border-transparent text-base " +
																"  rounded-full text-white  focus:outline-none "
															}
														>
															<EditSvgIcon />
														</button>
													</div>
												)}
												{!addMode && (
													<div className="mt-1 w-1/5">
														<button
															type="button"
															onClick={(event) => showDeleteConfirmAlert(event)}
															className={
																"inline-flex items-center py-3 border-transparent text-base " +
																"  rounded-full text-white  focus:outline-none "
															}
														>
															<TrashSvgIcon />
														</button>
													</div>
												)}
											</div>
											<div className="text-red-500  text-center">
												{isRequiredError && (
													<span>Please enter the required fields</span>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					)}
				</div>
			</div>
		</div>
	);
};

export default Site;
