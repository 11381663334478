import { Http } from "../Http";
import { AxiosPromise } from "axios";
import apiCall from "../api/apiCall";

const siteApi = {
	getAllSites: async (input) => {
		const id = input.userId ?? input.id;
		const url =
			"Site/GetAllByClient?" +
			"userId=" +
			id +
			"&userToken=" +
			input.userToken +
			"&clientId=" +
			input.clientId;
		const response = await apiCall(url, "get");
		return response;
	},

	// check this doesnt exist in swagger
	// getSitesByClient: async (input) => {
	// 	console.log("Get Sites By Client", input);
	// 	const id = input.userId ?? input.id;
	// 	const url =
	// 		"Site/GetSitesByClient?" +
	// 		"userId=" +
	// 		id +
	// 		"&userToken=" +
	// 		input.userToken +
	// 		"&clientId=" +
	// 		input.clientId;
	// 	const response = await apiCall(url, "get");
	// 	return response;
	// },

	getSite: async (input) => {
		const url =
			"Site/GetSite?" +
			"userId=" +
			input.userId +
			"&userToken=" +
			input.userToken +
			"&clientId=" +
			input.clientId;
		const response = await apiCall(url, "get");
		return response;
	},

	deleteSite: async (input) => {
		const url =
			"Site/Delete" +
			"?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken +
			"&id=" +
			input.id;
		const response = await apiCall(url, "delete");
		return response;
	},

	addSite: async (input) => {
		const userId = input.userId;
		const userToken = input.userToken;
		const data = {
			id: 0,
			clientId: input.clientId,
			name: input.name,
			addressLine1: input.addressLine1,
			addressLine2: input.addressLine2,
			town: input.town,
			county: input.county,
			postcode: input.postcode,
			country: input.country,
		};

		const url = "Site/Add" + "?userId=" + userId + "&userToken=" + userToken;
		const obj = JSON.stringify(data);
		const response = await apiCall(url, "post", obj);
		return response;
	},

	updateSite: async (input) => {
		const userId = input.userId;
		const userToken = input.userToken;
		const data = {
			id: input.id,
			clientId: input.clientId,
			name: input.name,
			addressLine1: input.addressLine1,
			addressLine2: input.addressLine2,
			town: input.town,
			county: input.county,
			postcode: input.postcode,
			country: input.country,
		};
		const url =
			"Site/Update" +
			"?userId=" +
			userId +
			"&userToken=" +
			userToken +
			"&id=" +
			input.id;
		const obj = JSON.stringify(data);
		const response = await apiCall(url, "put", obj);
		return response;
	},
};

export default siteApi;
