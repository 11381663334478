import React, { Fragment, useState, useRef, useEffect } from "react";
import moment from "moment";
import ReactTable from "react-table-6";
import { Dialog, Transition } from "@headlessui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../custom.css";
import ToggleSwitch from "../../utils/library/toggleSwitch";
import { usePopper } from "react-popper";
import { getUserData } from "../../utils/common/functions";
import FCInputSearchField from "../../utils/library/textboxsearch";
import FCButtonWithClick from "../../utils/library/buttonwithclick";
import FCSelectSiteField from "../../utils/library/selectsitefield";
import FCConfirmAlert from "../../utils/library/confirmalert";

import testApi from "../../utils/api/testApi";
import { showSweetAlert } from "../Shared/CustomAlert";

import {
	CameraSvgIcon,
	CloseIcon,
	PassIcon,
	FailIcon,
	CameraSolidSvgIcon,
	WarningIcon,
} from "../../utils/common/icon-hooks.js";

import {
	PlusCircleSvgIconTransparent,
	DotVerticalSvgSvgIcon,
} from "../../utils/common/icon-hooks.js";

import dashboardApi from "../../utils/api/dashboardApi";
import testPointApi from "../../utils/api/testpointApi";
import siteApi from "../../utils/api/siteApi";
import testHistoryApi from "../../utils/api/testHistoryApi";
import userApi from "../../utils/api/userApi";
import InfiniteScroll from "react-infinite-scroll-component";
import samplePlanApi from "../../utils/api/sampleplanApi.js";

const TestHistory = () => {
	const initialDashboardDataState = {
		isLoading: false,
		isError: false,
		isAllReportLoaded: false,
		isTestHistoryLoaded: false,
		allReportData: [],
		dashboardInput: {},
		isGlobalComparisonLoaded: false,
		globalComparisonData: [],
		isDashboardDataLoaded: false,
		graphData: {},
		isDownloadReportLoaded: false,
		downloadReportData: [],
		isDownloadDataLoaded: false,
		downloadData: [],
		testHistoryData: [],
	};

	const initialTestpointState = {
		isLoading: false,
		isError: false,
		// set below
		testpoint: null,
		isTestPointLoaded: false,
		isTestPointDeleted: false,
		deleteResult: false,
		testPointInput: {
			userId: -1,
			userToken: "",
			id: -1,
		},
		testPointResult: [
			{
				id: -1,
				name: "",
			},
		],
		isTestPointAdded: false,
		testPointAddResult: false,
		isUniqueChecked: false,
		isUniqueTestPoint: false,
	};

	const initialSiteDataState = {
		userId: 0,
		usertoken: "1234",
		isError: false,
		isSaved: false,
		isSiteLoaded: false,
		isSitesLoaded: false,
		isAllSitesLoaded: false,
		isSiteSaved: false,
		isSiteDeleted: false,
		sites: null,
		site: null,
	};

	const initialUserDataState = {
		isLoading: false,
		isError: false,
		isUsersLoaded: false,
		isUserDeleted: false,
		deleteResult: false,
		userInput: {
			userId: -1,
			userToken: "",
			clientId: -1,
		},
		usersResult: [
			{
				id: -1,
				name: "",
			},
		],
		userRolesResult: [
			{
				id: -1,
				name: "",
			},
		],
		user: null,
		isUserLoaded: false,
		isUserAdded: false,
		userAddResult: false,
		isAvatarUpdated: false,
		isUserRolesLoaded: false,
	};

	const initialSampleplanDataState = {
		userId: 0,
		usertoken: "",
		isSaved: false,
		isLive: false,
		isError: false,
		isSamplePlansLoaded: false,
		isTestPointsLoaded: false,
		isPlanSaved: false,
		isPlanDeleted: false,
		samplePlanInput: null,
		samplePlans: null,
		testPoints: null,
		selectedSamplePlanId: 0,
		selectedSamplePlan: null,
	};

	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;

	const [open, setOpen] = useState(false);
	const cancelButtonRef = useRef(null);

	const [userData, setUserData] = useState(null);
	const [isSitesLoaded, setIsSitesLoaded] = useState(false);
	const [siteOptions, setSiteOptions] = useState([]);
	const [selectedSiteId, setSelectedSiteId] = useState(0);
	const [selectedSite, setSelectedSite] = useState({ label: "", value: 0 });
	const [testResultImageUrl, setTestResultImageUrl] = useState(String);
	const [isTestHistoryLoaded, setIsTestHistoryLoaded] = useState(false);
	const [testHistoryData, setTestHistoryData] = useState([]);
	const [filteredTestHistory, setFilteredTestHitory] = useState([]);
	const [searchText, setSearchText] = useState(String);
	const [isClearSearch, setIsClearSearch] = useState(false);

	const [testResultToggle, setTestResultToggle] = useState(true);
	const [FilterDialog, setFilterDialog] = useState(false);
	const [userOptions, setUserOptions] = useState([]);
	const [isAllUsersLoaded, setIsAllUsersLoaded] = useState(false);
	const [allUsers, setAllUsers] = useState([]);
	const [samplePlanOptions, setSamplePlanOptions] = useState([]);
	const [isSamplePlansLoaded, setIsSamplePlansLoaded] = useState(false);
	const [isTestPointLoaded, setIsTestPointLoaded] = useState(false);
	const [testPointOptions, setTestPointOptions] = useState([]);
	const [selectedUser, setSelectedUser] = useState({ label: "", value: 0 });
	const [selectedUserId, setSelectedUserId] = useState(0);
	const [selectedSamplePlanId, setSelectedSamplePlanId] = useState(0);
	const [selectedSamplePlan, setSelectedSamplePlan] = useState({
		label: "",
		value: 0,
	});
	const [selectedTestPointId, setSelectedTestPointId] = useState(0);
	const [selectedTestPoint, setSelectedTestPoint] = useState({
		label: "",
		value: 0,
	});
	const [isShowConfirmAlertCancelFilter, setIsShowConfirmAlertCancelFilter] =
		useState(false);
	const [isShowConfirmAlertClearFilter, setIsShowConfirmAlertClearFilter] =
		useState(false);

	const [beforeCancelSamplePlanId, setBeforeCancelSamplePlanId] = useState(0);
	const [beforeCancelUserId, setBeforeCancelUserId] = useState(0);
	const [beforeCancelTestPointId, setBeforeCancelTestPointId] = useState(0);
	const [beforeCancelSiteId, setBeforeCancelSiteId] = useState(0);

	const [beforeCancelSamplePlan, setBeforeCancelSamplePlan] = useState({
		label: "",
		value: 0,
	});
	const [beforeCancelUser, setBeforeCancelUser] = useState({
		label: "",
		value: 0,
	});
	const [beforeCancelTestPoint, setBeforeCancelTestPoint] = useState({
		label: "",
		value: 0,
	});
	const [beforeCancelSite, setBeforeCancelSite] = useState({
		label: "",
		value: 0,
	});

	const [beforeCancelDateRange, setBeforeCancelDateRange] = useState([
		null,
		null,
	]);
	const [beforeCancelResultToggle, setBeforeCancelResultToggle] =
		useState(true);

	const [popVisible, setPopVisibility] = useState(false);
	const [referenceRef, setReferenceRef] = useState(null);
	const [popperRef, setPopperRef] = useState(null);

	const [isDownloadReportLoaded, setIsDownloadReportLoaded] = useState(false);
	const [isDownloadDataLoaded, setIsDownloadDataLoaded] = useState(false);

	const [error, setError] = useState(false);
	const [siteData, setSiteData] = useState(initialSiteDataState);
	const [sampleplanData, setSampleplanData] = useState(
		initialSampleplanDataState,
	);
	const [testPointData, setTestPointData] = useState(initialTestpointState);
	// const [usersData, setUsersData] = useState(initialUserDataState);
	const [dashboardData, setDashboardData] = useState(initialDashboardDataState);

	const { styles, attributes } = usePopper(referenceRef, popperRef, {
		placement: "bottom-end",
		modifiers: [
			{
				name: "offset",
				enabled: true,
				options: {
					offset: [0, 10],
				},
			},
		],
	});

	var defaultUserId = 0;
	var defaultTestPointId = 0;
	var defaultSiteId = 0;
	var defaultSamplePlanId = 0;

	var defaultSamplePlan = { label: "", value: 0 };
	var defaultTestPoint = { label: "", value: 0 };
	var defaultSite = { label: "", value: 0 };
	var defaultUser = { label: "", value: 0 };

	var defaultDateRange = [null, null];
	var defaultResultToggle = true;

	const handlePopup = async (event, action) => {
		event.preventDefault();

		if (action === "filter") {
			setFilterDialog(true);
		}

		if (action === "Data") {
			if (!startDate || !endDate) {
				showSweetAlert(
					"errorCustomMessage",
					"Please select a valid date range from the filters.",
				);
				return;
			}
		}

		const usrData = getUserData();

		var input = {
			siteId: selectedSiteId,
			userId: usrData.id,
			userToken: usrData.userToken,
			clientId: usrData.clientId,
			samplePlanId: selectedSamplePlanId,
			userIdFliter: selectedUserId,
			dateFrom: moment(startDate, "yyyy-mm-dd"),
			dateTo: moment(endDate, "yyyy-mm-dd"),
		};

		if (action === "Report") {
			setIsDownloadReportLoaded(false);
			// dispatch(getDownloadReport(input));
			const response = await dashboardApi.getDownloadReport(input);
			if (response.status === 200) {
				setIsDownloadReportLoaded(true);
				setDashboardData({
					...dashboardData,
					downloadReportData: response.data,
				});
			} else {
				setError(response.statusText);
			}
		}
		if (action === "Data") {
			setIsDownloadDataLoaded(false);
			// dispatch(getDownloadData(input));
			const response = await dashboardApi.getDownloadData(input);
			if (response.status === 200) {
				try {
					const barrel64 = response.data.fileContents;
					if (/^[A-Za-z0-9+/]+={0,2}$/.test(barrel64)) {
						const decodedData = atob(barrel64);
						const dataArray = new Uint8Array(decodedData.length);

						for (let i = 0; i < decodedData.length; i++) {
							dataArray[i] = decodedData.charCodeAt(i);
						}

						const blob = new Blob([dataArray.buffer], {
							type: "application/csv",
						});
						const link = document.createElement("a");

						link.href = window.URL.createObjectURL(blob);
						link.download = response.data.fileDownloadName;
						link.click();
						setIsDownloadDataLoaded(true);
						setDashboardData({ ...dashboardData, downloadData: response.data });
					} else {
						console.error("Invalid base64 string");
					}
				} catch (error) {
					console.error("Error downloading barrel end:", error);
				}
			} else {
				setError(response.statusText);
			}
		}
	};

	const handleResultToggle = (value) => {
		setTestResultToggle(value);
	};

	React.useEffect(() => {
		var usrData = getUserData();
		setUserData(usrData);
		//getTestHistoryData(usrData);
		getTestPointData(usrData);
		getSamplePlanData(usrData);
		getUsersData(usrData);
		getSingleUserData(usrData);
		getSiteData(usrData);
	}, []);

	//Graph Data
	React.useEffect(() => {
		if (dashboardData) {
			if (dashboardData.isTestHistoryLoaded && !isTestHistoryLoaded) {
				dashboardData.isTestHistoryLoaded = false;
				setIsTestHistoryLoaded(true);
				setTestHistoryData([]);
				setTestHistoryData(dashboardData.testHistoryData);
			}
		}
	}, [dashboardData]);

	useEffect(() => {
		if (userData && !error) {
			if (userData.users && userData.users.length > 0) {
				setAllUsers(userData.users);
				setIsAllUsersLoaded(true);
				var uOptions = [];
				userData.users.forEach((item) => {
					uOptions.push({ label: item.username, value: item.id });
				});
				setUserOptions(uOptions);
			}
		}

		if (sampleplanData && sampleplanData.samplePlans) {
			var pOptions = [];
			sampleplanData.samplePlans.forEach((item) => {
				pOptions.push({ label: item.name, value: item.id });
			});
			setSamplePlanOptions(pOptions);
			setIsSamplePlansLoaded(true);
		}

		//TestPointData
		if (testPointData && testPointData.testPointResult) {
			var tOptions = [];
			testPointData.testPointResult.forEach((item) => {
				tOptions.push({ label: item.name, value: item.id });
			});
			setTestPointOptions(tOptions);
			setIsTestPointLoaded(true);
		}

		if (siteData && siteData.sites) {
			var options = [];
			siteData.sites.forEach((item) => {
				options.push({ label: item.name, value: item.id });
			});
			setSiteOptions(options);
			setIsSitesLoaded(true);
			siteData.isSitesLoaded = false;
		}
	}, [dashboardData, userData, testPointData, sampleplanData]);

	//UsersData
	const onSiteChange = (event) => {
		if (event.value === null) {
			return;
		}
		setSelectedSiteId(event.value);
		if (siteOptions) {
			setSelectedSite(siteOptions.find((o) => o.value === event.value));
		}
	};

	const onUserChange = (event) => {
		if (event.value === null) {
			return;
		}
		setSelectedUserId(event.value);
		if (userOptions) {
			setSelectedUser(userOptions.find((o) => o.value === event.value));
		}
	};

	const onPlanChange = (event) => {
		if (event.value === null) {
			return;
		}
		setSelectedSamplePlanId(event.value);
		if (samplePlanOptions) {
			setSelectedSamplePlan(
				samplePlanOptions.find((o) => o.value === event.value),
			);
		}
		//getGraphData();
	};

	const onTestPointChange = (event) => {
		if (event.value === null) {
			return;
		}
		setSelectedTestPointId(event.value);
		if (testPointOptions) {
			setSelectedTestPoint(
				testPointOptions.find((o) => o.value === event.value),
			);
		}
	};

	const onDateChange = (dates) => {
		setDateRange(dates);
	};

	const [noTestHistoryData, setNoTestHistoryData] = useState(false);

	const getUsersData = async (usrData) => {
		const response = await userApi.getAllUsers({
			userId: usrData.id,
			userToken: usrData.userToken,
			clientId: usrData.clientId,
		});
		if (response.status === 200) {
			setUserData({ ...userData, users: response.data, isUserLoaded: true });
		} else {
			setError(response.statusText);
		}
	};

	const getSingleUserData = async (usrData) => {
		const response = await userApi.getSingleUser({
			userId: usrData.id,
			userToken: usrData.userToken,
			id: usrData.id,
		});
		if (response.status === 200) {
			setUserData({ ...userData, user: response.data, isUserLoaded: true });
		} else {
			setError(response.statusText);
		}
	};

	const getTestPointData = async (usrData) => {
		setIsTestPointLoaded(false);
		const response = await testPointApi.getAllTestPoint({
			userId: usrData.id,
			userToken: usrData.userToken,
		});
		if (response.status === 200) {
			setTestPointOptions(response.data);
			setTestPointData({ ...testPointData, testPointResult: response.data });
		} else {
			setError(response.statusText);
		}
	};

	const getSamplePlanData = async (usrData) => {
		setIsSamplePlansLoaded(false);
		// dispatch(
		// 	getSamplePlans({ userId: usrData?.id, userToken: usrData?.userToken })
		// );
		const response = await samplePlanApi.getSamplePlans({
			userId: usrData.id,
			userToken: usrData.userToken,
		});
		if (response.status === 200) {
			setSamplePlanOptions(response.data);
			setSampleplanData({ ...sampleplanData, samplePlans: response.data });
		} else {
			setError(response.statusText);
		}
	};

	const getSiteData = async (usrData) => {
		setIsSitesLoaded(false);
		const response = await siteApi.getAllSites({
			userId: usrData.id,
			userToken: usrData.userToken,
			clientId: usrData.clientId,
		});
		if (response.status === 200) {
			setSiteData({ ...siteData, sites: response.data });
		} else {
			setError(response.statusText);
		}
	};

	function convert(str) {
		let date = new Date(str),
			mnth = ("0" + (date.getMonth() + 1)).slice(-2),
			day = ("0" + date.getDate()).slice(-2),
			hours = ("0" + date.getHours()).slice(-2),
			minutes = ("0" + date.getMinutes()).slice(-2);
		return [date.getFullYear(), mnth, day, hours, minutes].join("-");
	}

	const showImage = (event, url, isShowImage) => {
		event.preventDefault();
		if (isShowImage) {
			setTestResultImageUrl(url);
			setOpen(true);
		}
	};

	const columns = [
		{
			Header: <div style={{ textAlign: "left" }}>Test Point</div>,
			accessor: "testPoint",
		},
		{
			Header: <div style={{ textAlign: "left" }}>Site</div>,
			accessor: "site",
		},
		{
			Header: <div style={{ textAlign: "left" }}>Sample Plan</div>,
			accessor: "samplePlan",
		},
		{
			Header: (
				<div
					style={{ textAlign: "left" }}
					onClick={() => console.log("hello world")}
				>
					Completed By
				</div>
			),
			accessor: "completedBy",
		},
		{
			Header: <div style={{ textAlign: "left" }}>Date</div>,
			accessor: "timeStamp",
			Cell: (obj) => <div>{moment(obj.value).format("DD/MM/YYYY")}</div>,
		},
		{
			Header: <div style={{ textAlign: "left" }}>Colour</div>,
			accessor: "colour",
			width: 70,
			Cell: (obj) => (
				<a
					href="#"
					onClick={(e) => {
						e.preventDefault();
					}}
				>
					<div
						style={{
							background: `#${obj.original.colour}`,
							width: "40px",
							height: "22px",
							borderRadius: "3px",
						}}
					/>
				</a>
			),
		},
		// Result type:
		// 1 = fail
		// 2 = warn
		// 3 = pass
		{
			Header: <div style={{ textAlign: "left" }}>Result</div>,
			Cell: (obj) => (
				<a
					href="#"
					onClick={(e) => {
						e.preventDefault();
					}}
				>
					{obj.original.resultType === 1 ? (
						<PassIcon />
					) : obj.original.resultType === 2 ? (
						<WarningIcon />
					) : obj.original.resultType === 3 ? (
						<FailIcon />
					) : (
						""
					)}
				</a>
			),
			width: 70,
			accessor: "result",
		},
		{
			Header: "",
			Cell: (obj) =>
				obj.original.image ? (
					<a
						href="#"
						onClick={(event) =>
							showImage(
								event,
								obj.original.image,
								obj.original.image ? true : false,
							)
						}
					>
						<CameraSolidSvgIcon selected={obj.original.image ? true : false} />
					</a>
				) : (
					<CameraSvgIcon selected={obj.original.image ? true : false} />
				),
			width: 70,
			accessor: "image",
		},
	];

	const handleCancelClick = (event) => {
		setIsShowConfirmAlertCancelFilter(true);
	};

	const handleOkCancelFilter = (event) => {
		event.preventDefault();
		setIsShowConfirmAlertCancelFilter(false);
		setFilterDialog(false);
	};

	const handleNoCancelFilter = (event) => {
		setFilterDialog(true); //needed? will reload page? selected values will go off?
		setIsShowConfirmAlertCancelFilter(false);
	};

	const handleClearClick = () => {
		setFilterDialog(false);
		setIsShowConfirmAlertClearFilter(true);
	};

	const handleOkClearFilter = async () => {
		setIsShowConfirmAlertClearFilter(false);
		//setFilterDialog(false);

		setSelectedSamplePlanId(defaultSamplePlanId);
		setSelectedSamplePlan(defaultSamplePlan);

		setSelectedSiteId(defaultSiteId);
		setSelectedSite(defaultSite);

		setSelectedTestPointId(defaultTestPointId);
		setSelectedTestPoint(defaultTestPoint);

		setSelectedUser(defaultUser);
		setSelectedUserId(defaultUserId);
		setDateRange(defaultDateRange);

		setTestResultToggle(defaultResultToggle);

		// Reset the current page and fetch new data with cleared filters
		setCurrentPage(0);
		setTestHistoryData([]);
		setHasMore(true);
		await getTestHistoryData(getUserData(), true, true);
	};

	const handleNoClearFilter = () => {
		setFilterDialog(true); //needed? will reload page? selected values will go off?
		setIsShowConfirmAlertClearFilter(false);
	};

	const handleDropdownClick = (event) => {
		setPopVisibility(!popVisible);
	};

	const handlePrint = (event) => {
		event.preventDefault();
		setPopVisibility(!popVisible);
		setTimeout(
			function () {
				window.print();
			}.bind(this),
			1000,
		);
	};

	// pagination for the table
	const [currentPage, setCurrentPage] = useState(0); // Current page index

	// archive filter data
	const [archiveFilterData, setArchiveFilterData] = useState({
		siteId: 0,
		samplePlanId: 0,
		testPointId: 0,
		userId: 0,
		dateFrom: null,
		dateTo: null,
	});

	const arhiveCancelButtonRef = useRef(null);
	const [archiveFilterDialog, setArchiveFilterDialog] = useState(false);

	const [selectedSiteFilter, setSelectedSiteFilter] = useState(null);
	const [selectedSamplePlanFilter, setSelectedSamplePlanFilter] =
		useState(null);
	const [selectedTestPointFilter, setSelectedTestPointFilter] = useState(null);
	const [selectedUserFilter, setSelectedUserFilter] = useState(null);
	const [selectedDateFilter, setSelectedDateFilter] = useState([null, null]);

	// archive popup
	const handleArchivePopup = (event) => {
		event.preventDefault();
		setEmptyArchiveFilterDateError(false);
		const endOfDay = moment()
			.endOf("day")
			.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
		const adjustedEndOfDay = endOfDay.add(endOfDay.utcOffset(), "minutes");
		setArchiveFilterData((prev) => ({
			...prev,
			dateFrom: moment().subtract(30, "days").startOf("day").toDate(),
			dateTo: adjustedEndOfDay.toDate(),
		}));
		// display the filters modal
		setArchiveFilterDialog(true);
	};

	const updateSiteFilter = (event) => {
		if (event.value === null) {
			return;
		}
		setArchiveFilterData({
			...archiveFilterData,
			siteId: event.value,
		});
		if (siteOptions) {
			setSelectedSiteFilter(siteOptions.find((o) => o.value === event.value));
		}
	};

	const updateSamplePlanFilter = (event) => {
		if (event.value === null) {
			return;
		}
		setArchiveFilterData({
			...archiveFilterData,
			samplePlanId: event.value,
		});
		if (samplePlanOptions) {
			setSelectedSamplePlanFilter(
				samplePlanOptions.find((o) => o.value === event.value),
			);
		}
	};

	const updateTestPointFilter = (event) => {
		if (event.value === null) {
			return;
		}
		setArchiveFilterData({
			...archiveFilterData,
			testPointId: event.value,
		});
		if (testPointOptions) {
			setSelectedTestPointFilter(
				testPointOptions.find((o) => o.value === event.value),
			);
		}
	};

	const updateDateFilter = (dates) => {
		setArchiveFilterData({
			...archiveFilterData,
			dateFrom: dates[0],
			dateTo: dates[1],
		});
		setSelectedDateFilter(dates);
	};

	const handleClearFilters = () => {
		setArchiveFilterData({
			siteId: 0,
			samplePlanId: 0,
			testPointId: 0,
			userId: 0,
			dateFrom: null,
			dateTo: null,
		});
		setSelectedSiteFilter(null);
		setSelectedSamplePlanFilter(null);
		setSelectedTestPointFilter(null);
		setSelectedUserFilter(null);
		setSelectedDateFilter([null, null]);
	};

	const handleCancelFilters = () => {
		setArchiveFilterDialog(false);
		setPopVisibility(false);
	};

	const [emptyArchiveFilterDateError, setEmptyArchiveFilterDateError] =
		useState(false);
	const handleArchiveSave = async () => {
		setEmptyArchiveFilterDateError(false);
		if (!archiveFilterData.dateFrom || !archiveFilterData.dateTo) {
			setEmptyArchiveFilterDateError(true);
			return;
		}

		// get the user data
		const userData = getUserData();

		var input = {
			siteId: archiveFilterData.siteId,
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
			samplePlanId: archiveFilterData.samplePlanId,
			dateFrom: moment(archiveFilterData.dateFrom, "yyyy-mm-dd"),
			dateTo: moment(archiveFilterData.dateTo, "yyyy-mm-dd"),
			archiveId: 0,
		};

		const response = await testApi.RequestArchive(input);

		// the response will be a string of the user email
		if (response) {
			if (response.status === 200) {
				showSweetAlert("successOkOnly", response.data);
				handleCancelFilters();
				handleClearFilters();
			} else {
				let errorMessage = response?.data ?? "Something went wrong.";
				showSweetAlert("errorCustomMessage", errorMessage);
			}
		}
	};

	const returnArchiveFilterModal = () => {
		return (
			<Transition.Root show={archiveFilterDialog} as={Fragment}>
				<Dialog
					as="div"
					className="fixed z-10 inset-0 overflow-y-auto"
					initialFocus={cancelButtonRef}
					onClose={setFilterDialog}
				>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="hidden sm:inline-block sm:align-middle sm:h-screen"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="inline-block w-1/2 align-bottom bg-white font-large rounded-2xl px-4 pt-5 pb-4 text-left transform transition-all sm:align-middle sm:px-10">
								<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
									<button
										type="button"
										className="bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
										onClick={() => handleCancelFilters()}
									>
										<span className="sr-only">Close</span>
										<CloseIcon />
									</button>
								</div>
								<div className="sm:flex sm:items-start">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center w-full mb-5">
										<Dialog.Title
											as="h3"
											className="text-lg leading-6  text-gray-500"
										>
											Filter
										</Dialog.Title>
									</div>
								</div>
								<form>
									<div className="flex">
										<div className="block pl-1 text-fclabel w-1/5 align-middle">
											Site
										</div>
										<div className="w-2/5 align-top">
											<FCSelectSiteField
												id="sites"
												onChange={(event) => updateSiteFilter(event)}
												options={siteOptions}
												value={selectedSiteFilter}
												className="ml-1"
											/>
										</div>
									</div>
									<div>&nbsp;</div>
									<div className="flex">
										<div className="block pl-1 text-fclabel w-1/5">
											Sample Plan
										</div>
										<div className="w-2/5">
											<FCSelectSiteField
												id="plans"
												onChange={(event) => updateSamplePlanFilter(event)}
												options={samplePlanOptions}
												value={selectedSamplePlanFilter}
												className="ml-1"
											/>
										</div>
									</div>
									<div>&nbsp;</div>
									<div className="flex">
										<div className="block pl-1 text-fclabel w-1/5">Date</div>
										<div className="w-2/5">
											<DatePicker
												dateFormat="dd/MM/yyyy"
												selectsRange
												startDate={archiveFilterData.dateFrom}
												endDate={archiveFilterData.dateTo}
												onChange={(value) => updateDateFilter(value)}
												className={`border-2 p-2 rounded-2xl ml-1 blueText w-full ${
													emptyArchiveFilterDateError
														? "border-red-500"
														: "border-gray-300"
												}`}
												wrapperClassName="w-full"
											/>
											{emptyArchiveFilterDateError && (
												<p className="text-red-500 pl-3 text-sm mt-1">
													Please select a valid date range
												</p>
											)}
										</div>
									</div>
									<div>&nbsp;</div>
									<div className="pb-5 sm:items-center">
										<div className="mt-3 flex sm:mt-0">
											<div className="w-3/5 align-middle flex">
												<div className="w-1/3">&nbsp;</div>
												<div className="w-1/3 align-middle">
													<FCButtonWithClick
														onHandleClick={handleClearFilters}
														bgColor="bg-fcbutton mr-3"
														type="button"
														icon=""
														value="Clear"
													/>
												</div>
												<div className="w-1/3">&nbsp;</div>
											</div>
											<div className="w-1/5 align-top">
												<FCButtonWithClick
													onHandleClick={handleCancelFilters}
													bgColor="bg-fcbutton mr-3"
													type="button"
													icon=""
													value="Cancel"
												/>
											</div>
											<div className="w-1/5 align-top">
												<FCButtonWithClick
													bgColor="bg-primary mr-3"
													type="button"
													onHandleClick={(event) => handleArchiveSave(event)}
													icon=""
													value="Save"
												/>
											</div>
										</div>
									</div>
								</form>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		);
	};

	// This is when filters are applied
	const getFilteredTestHistory = (event) => {
		const usrData = getUserData();
		setCurrentPage(0);
		setTestHistoryData([]);
		setHasMore(true);
		getTestHistoryData(usrData, true);
		setFilterDialog(false);
	};

	const [isLoading, setIsLoading] = useState(false);
	const [hasMore, setHasMore] = useState(true); // Default to true to allow initial load
	const [take, setTake] = useState(20);

	// sets the sortBy column
	const [sortBy, setSortBy] = useState({ id: "", desc: true });

	const getTestHistoryData = async (
		usrData,
		resetData = false,
		isClearFilter = false,
		newSortBy = null,
	) => {
		const skip = resetData ? 0 : currentPage * take;
		setIsTestHistoryLoaded(false);

		try {
			const response = await testHistoryApi.getAllWithImages({
				userId: usrData.id,
				userToken: usrData.userToken,
				skip: skip,
				take: take,
				search: searchText,
				sorting: newSortBy || (isClearFilter ? [] : sortBy),
				samplePlanId: isClearFilter ? 0 : selectedSamplePlanId,
				testPointId: isClearFilter ? 0 : selectedTestPointId,
				completedByUserId: isClearFilter ? 0 : selectedUserId,
				dateFrom: isClearFilter
					? null
					: startDate
						? moment(startDate, "yyyy-mm-dd")
						: null,
				dateTo: isClearFilter
					? null
					: endDate
						? moment(endDate, "yyyy-mm-dd")
						: null,
			});

			if (response && response?.status === 200) {
				setIsTestHistoryLoaded(true);

				if (response.data.data.length > 0) {
					setTestHistoryData(
						resetData
							? response.data.data
							: (prevData) => [...prevData, ...response.data.data],
					);
					setCurrentPage((prevPage) => prevPage + 1);
					setHasMore(true);
				} else {
					setHasMore(false);
				}

				if (response.data.data.length === 0 && currentPage === 0) {
					setNoTestHistoryData(true);
				}
			} else {
				setError(response.statusText);
			}
		} catch (error) {
			console.log("Error fetching data", error);
			setError(error.message);
		} finally {
			setIsLoading(false);
		}
	};

	const handleUserSearch = (txtValue) => {
		setSearchText(txtValue);
		setTestHistoryData([]);
		setCurrentPage(0);
		setHasMore(true);
	};

	useEffect(() => {
		const usrData = getUserData();
		setTestHistoryData([]);
		getTestHistoryData(usrData, true);
	}, [searchText]);

	const updateData = async () => {
		if (!hasMore || isLoading) return;

		setIsLoading(true);
		await new Promise((resolve) => setTimeout(resolve, 500));

		try {
			const usrData = getUserData();
			await getTestHistoryData(usrData);
		} catch (e) {
			console.log(e);
		} finally {
			setIsLoading(false);
		}
	};

	const returnLazyLoadTable = () => {
		return (
			<div>
				<InfiniteScroll
					dataLength={testHistoryData.length}
					next={updateData}
					hasMore={hasMore}
					loader={!hasMore && <h5>Loading more data...</h5>}
					height={600}
				>
					<ReactTable
						data={testHistoryData}
						minRows={3}
						columns={columns}
						showPagination={false}
						resizable={false}
						onSortedChange={(newSort) => {
							setSortBy(newSort);
							setCurrentPage(0);
							setTestHistoryData([]);
							setHasMore(true);
							const usrData = getUserData();
							getTestHistoryData(usrData, true, false, newSort);
						}}
						noDataText={
							<h2 className="text-center align-middle">No results found</h2>
						}
						className="mt-9 -highlight border-0 mb-1 align-left hide-scrollbar"
						pageSize={testHistoryData.length > 0 ? testHistoryData.length : 0}
					/>
					<div className="w-full flex justify-center">
						{isLoading && <h5>Loading more data...</h5>}
						{!isLoading && !hasMore && <h5>No more data available</h5>}
					</div>
				</InfiniteScroll>
			</div>
		);
	};
	return (
		<>
			<FCConfirmAlert
				message="Are you sure you wish to cancel?"
				isOpen={isShowConfirmAlertCancelFilter}
				title="Filter"
				onOkClick={(event) => handleOkCancelFilter(event)}
				onCancelClick={(event) => handleNoCancelFilter(event)}
			/>
			<FCConfirmAlert
				message="Are you sure you wish to clear all filter data?"
				isOpen={isShowConfirmAlertClearFilter}
				title="Test Point"
				onOkClick={handleOkClearFilter}
				onCancelClick={handleNoClearFilter}
			/>
			<div className="w-full h-full mt-10 px-4">
				<div className="w-full flex pb-3">
					<div className="w-1/2 flex">
						<div className="w-1/2">
							<FCInputSearchField
								className=""
								placeholder="Search"
								id="search"
								value={searchText}
								isClearSearch={isClearSearch}
								onChange={(event) => handleUserSearch(event)}
							/>
						</div>
						<div className="w-1/2 flex justify-left items-center">
							<span className="text-1xl w-1/3 text-center">
								<a
									href="#"
									className="text-gray-500"
									onClick={(event) => handlePopup(event, "filter")}
								>
									Filter
								</a>
							</span>
						</div>
					</div>

					{/* Filter Modal for archiving */}
					<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
						{returnArchiveFilterModal()}
					</div>

					<div className="w-1/2">
						<div className="w-full pr-5 text-right">
							<button ref={setReferenceRef} onClick={handleDropdownClick}>
								<DotVerticalSvgSvgIcon />
							</button>
							<div
								ref={setPopperRef}
								style={styles.popper}
								{...attributes.popper}
								className={
									!popVisible
										? "hidden"
										: "w-26 rounded-2xl border-2 border-gray z-10"
								}
							>
								<div
									className="rounded-2xl border-2 border-white"
									style={{ backgroundColor: "#fff" }}
								>
									<a
										href="#"
										className="block flex flex-inline w-full py-1 px-3"
										onClick={(event) => handlePopup(event, "Data")}
									>
										<PlusCircleSvgIconTransparent /> Download Data
									</a>
									<a
										href="#"
										className="block flex flex-inline w-full py-1 px-3"
										onClick={(event) => handleArchivePopup(event)}
									>
										<PlusCircleSvgIconTransparent /> Request Archive
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="mt-5">{returnLazyLoadTable()}</div>
			</div>
			<Transition.Root show={open} as={Fragment}>
				<Dialog
					as="div"
					className="fixed z-10 inset-0 overflow-y-auto"
					initialFocus={cancelButtonRef}
					onClose={setOpen}
				>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="hidden sm:inline-block sm:align-middle sm:h-screen"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 items-center">
								<div className="text-center">
									{testResultImageUrl && testResultImageUrl.length > 0 ? (
										<img src={testResultImageUrl} alt="Test Result Image" />
									) : (
										"No Image"
									)}
								</div>
								<div className="mt-5 sm:mt-6 flex justify-center">
									<FCButtonWithClick
										onHandleClick={() => setOpen(false)}
										bgColor="bg-fcbutton mr-3"
										type="button"
										icon=""
										value="Close"
									/>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>

			<Transition.Root show={FilterDialog} as={Fragment}>
				<Dialog
					as="div"
					className="fixed z-10 inset-0 overflow-y-auto"
					initialFocus={cancelButtonRef}
					onClose={setFilterDialog}
				>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="hidden sm:inline-block sm:align-middle sm:h-screen"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="inline-block w-1/2 align-bottom bg-white font-large rounded-2xl px-4 pt-5 pb-4 text-left transform transition-all sm:align-middle sm:px-10">
								<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
									<button
										type="button"
										className="bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
										onClick={() => setFilterDialog(false)}
									>
										<span className="sr-only">Close</span>
										<CloseIcon />
									</button>
								</div>
								<div className="sm:flex sm:items-start">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center w-full mb-5">
										<Dialog.Title
											as="h3"
											className="text-lg leading-6  text-gray-500"
										>
											Filter
										</Dialog.Title>
									</div>
								</div>
								<form>
									<div className="flex">
										<div className="block pl-1 text-fclabel w-1/5 align-middle">
											Site
										</div>
										<div className="w-2/5 align-top">
											<FCSelectSiteField
												id="sites"
												onChange={(event) => onSiteChange(event)}
												options={siteOptions}
												value={selectedSite}
												className=" ml-1 "
											/>
										</div>
									</div>
									<div>&nbsp;</div>
									<div className="flex">
										<div className="block pl-1 text-fclabel w-1/5">
											Sample Plan
										</div>
										<div className="w-2/5">
											<FCSelectSiteField
												id="plans"
												onChange={(event) => onPlanChange(event)}
												options={samplePlanOptions}
												value={selectedSamplePlan}
												className=" ml-1 "
											/>
										</div>
									</div>
									<div>&nbsp;</div>
									<div className="flex">
										<div className="block pl-1 text-fclabel w-1/5">
											Test Point
										</div>
										<div className="w-2/5">
											<FCSelectSiteField
												id="testpoints"
												onChange={(event) => onTestPointChange(event)}
												options={testPointOptions}
												value={selectedTestPoint}
												className=" ml-1 "
											/>
										</div>
									</div>
									<div>&nbsp;</div>
									<div className="flex">
										<div className="block pl-1 text-fclabel w-1/5">User</div>
										<div className="w-2/5">
											<FCSelectSiteField
												id="users"
												onChange={(event) => onUserChange(event)}
												options={userOptions}
												value={selectedUser}
												className=" ml-1 "
											/>
										</div>
									</div>
									<div>&nbsp;</div>
									<div className="flex">
										<div className="block pl-1 text-fclabel w-1/5">Date</div>
										<div className="w-2/5">
											<DatePicker
												dateFormat={moment().format("DD/MM/YYYY")}
												selectsRange={true}
												startDate={startDate}
												endDate={endDate}
												onChange={(value) => onDateChange(value)}
												className="border-2 border-gray-300 p-2 rounded-2xl ml-1 blueText w-full"
												wrapperClassName="w-full"
												width="100%"
											/>
										</div>
									</div>
									<div>&nbsp;</div>
									<div className="w-3/5 flex">
										<div className="w-1/5">&nbsp;</div>
										<div className="w-4/5">
											<ToggleSwitch
												checked={testResultToggle}
												onChange={(value) => handleResultToggle(value)}
												enableText="All Results"
												disableText="Fail Only"
												enableColor="bg-primary"
												disableColor="bg-primary"
												name="testResult"
											/>
										</div>
									</div>
									<div>&nbsp;</div>
									<div className="pb-5 sm:items-center">
										<div className="mt-3 flex sm:mt-0">
											<div className="w-3/5 align-middle flex">
												<div className="w-1/3">&nbsp;</div>
												<div className="w-1/3 align-middle">
													<FCButtonWithClick
														onHandleClick={(event) => handleClearClick(event)}
														bgColor="bg-fcbutton mr-3"
														type="button"
														icon=""
														value="Clear"
													/>
												</div>
												<div className="w-1/3">&nbsp;</div>
											</div>
											<div className="w-1/5 align-top">
												<FCButtonWithClick
													onHandleClick={(event) => handleCancelClick(event)}
													bgColor="bg-fcbutton mr-3"
													type="button"
													icon=""
													value="Cancel"
												/>
											</div>
											<div className="w-1/5 align-top">
												<FCButtonWithClick
													bgColor="bg-primary mr-3"
													type="button"
													onHandleClick={(event) =>
														getFilteredTestHistory(event)
													}
													icon=""
													value="Save"
												/>
											</div>
										</div>
									</div>
								</form>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
};

export default TestHistory;
