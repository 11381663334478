import { Http } from "../Http";
import { AxiosPromise } from "axios";
import authHeader from "../common/functions";
import apiCall from "./apiCall";

const accountApi = {
	register: async (input) => {
		const obj = JSON.stringify(input);
		const url = "Account/Register";
		const response = await apiCall(url, "post", obj);
		return response;
	},

	login: async (input) => {
		const obj = JSON.stringify(input);
		const url = "Account/Login";
		const response = await apiCall(url, "post", obj);
		return response;
	},

	forgotPassword: async (email) => {
		const url = "Account/ForgotPassword?email=" + email;
		const response = await apiCall(url, "post");
		return response;
	},

	forgetPasswordValidateToken: async (email, token) => {
		const url =
			"Account/ForgotPwdValidateToken?email=" + email + "&resetToken=" + token;
		const response = await apiCall(url, "post");
		return response;
	},

	forgotPasswordChange: async (input) => {
		const obj = JSON.stringify(input);
		const url = "Account/ChangePassword";
		const response = await apiCall(url, "post", obj);
		return response;
	},

	logout: async () => {
		const url = "Account/Logout";
		const response = await apiCall(url, "get");
		return response;
	},

	fetchCheckLoggedUser: async (userName) => {
		const url = "Account/CheckIsCurrentUserValid?userName=" + userName;
		const response = await apiCall(url, "get");
		return response;
	},

	getData: async (userName) => {
		const url = "Account/GetData?userName=" + userName;
		const response = await apiCall(url, "get");
		return response;
	},

	validateCompanyExist: async (input) => {
		const url = "Account/ValidateCompanyExists?companyName=" + input.name;
		const response = await apiCall(url, "get");
		return response;
	},

	// public static register(input: any) {
	//   var obj = JSON.stringify(input);
	//   return Http.axios().post('/Account/RegisterAccount', obj, { headers: { 'Content-Type': 'application/json' } })
	//     .then(response => {
	//       return response;
	//     }).catch((e) => {
	//       return e.response;
	//     }) as AxiosPromise<any>;
	// }

	// public static login(input: any) {
	//   var obj = JSON.stringify(input);
	//   return Http.axios().post('/Account/Login', obj, { headers: { 'Content-Type': 'application/json' } })
	//     .then(response => {
	//       return response;
	//     }).catch((e) => {
	//       return e.response;
	//     }) as AxiosPromise<any>;
	// }

	// public static forgotPassord(email: string) {
	//   return Http.axios().post('/Account/ForgetPwd?email=' + email)
	//     .then(response => {
	//       return response;
	//     }).catch((e) => {
	//       return e.response;
	//     }) as AxiosPromise<any>;
	// }

	// public static forgetPasswordValidateToken(email:string, token: string) {
	//   var obj = JSON.stringify("");
	//   return Http.axios().post('/Account/ForgotPwdValidateToken?email=' + email + '&resetToken=' + token)
	//     .then(response => {
	//       return response;
	//     })
	//     .catch((e) => {
	//       return e.response;
	//     }) as AxiosPromise<any>;
	// }

	// public static forgotPassordChange(input: any) {
	//   var obj = JSON.stringify(input);
	//   return Http.axios().post('/Account/ChangePassword', obj, { headers: { 'Content-Type': 'application/json' } })
	//     .then(response => {
	//       return response;
	//     }).catch((e) => {
	//       return e.response;
	//     }) as AxiosPromise<any>;
	// }

	// public static logout() {
	//   return Http.axios().get('/Account/logout')
	//     .catch((e) => {
	//       return e.response;
	//     }) as AxiosPromise<any>;
	// }

	// //TODO: may have to remove
	// public static fetchCheckLoggedUser(userName: string) {
	//   return Http.axios().get('/Account/CheckIsCurrentUserValid?userName=' + userName)
	//     .catch((e) => {
	//       return e.response;
	//     }) as AxiosPromise<any>;
	// }

	// public static getData(userName: string) {
	//   return Http.axios().get('/Account/getData?userName=' + userName, { headers: { authHeader } })
	//     .catch((e) => {
	//       return e.response;
	//     }) as AxiosPromise<any>;
	//   }

	//   public static validateCompanyExist(input: any) {
	//       return Http.axios().get('/Account/ValidateCompanyExist?companyName=' + input.name)
	//           .catch((e) => {
	//               return e.response;
	//           }) as AxiosPromise<any>;
	//   }
};

export default accountApi;
