import * as React from "react";
import PropTypes from "prop-types";
import { Switch } from "@headlessui/react";

import {
	UserSvgIcon,
	LockSvgIcon,
	CheveronCircleRightSvgIcon,
	ClientsSvgIconLogin,
	EnvelopSvgIcon,
} from "../common/icon-hooks.js";

const ToggleSwitch = ({
	onChange,
	checked,
	id,
	enableText,
	disableText,
	enableColor,
	disableColor,
}) => {
	var enableStyleAssign =
		!enableColor || enableColor === ""
			? "translate-x-1 bg-primary"
			: "translate-x-1 " + enableColor;
	var disableStyleAssign =
		!disableColor || disableColor === ""
			? "translate-x-10 bg-gray-200"
			: "translate-x-10 " + disableColor;

	function classNames(...classes) {
		return classes.filter(Boolean).join(" ");
	}

	const handleChange = (event) => {
		onChange(event);
	};
	return (
		<>
			<Switch.Group as="div" className="flex items-center">
				<Switch.Label as="span" className="mr-3">
					<span className="text-fclabel">
						{enableText === "" ? "Enable" : enableText}
					</span>
				</Switch.Label>
				<Switch
					id={id}
					checked={checked}
					onChange={handleChange}
					className={
						"relative bg-fcinput inline-flex flex-shrink-0 h-9 w-20 border-2 rounded-full cursor-pointer transition-colors ease-in-out duration-200"
					}
				>
					<span
						aria-hidden="true"
						className={classNames(
							checked ? enableStyleAssign : disableStyleAssign,
							"pointer-events-none inline-block h-8 w-8 rounded-full shadow transform ring-0 transition ease-in-out duration-200"
						)}
					/>
				</Switch>
				<Switch.Label as="span" className="ml-3">
					<span className="text-fclabel">
						{disableText === "" ? "Disable" : disableText}
					</span>
				</Switch.Label>
			</Switch.Group>
		</>
	);
};

ToggleSwitch.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	id: PropTypes.string,
	name: PropTypes.string,
	checked: PropTypes.bool,
	enableText: PropTypes.string,
	disableText: PropTypes.string,
};

ToggleSwitch.defaultProps = {
	value: "",
	enableText: "",
	disableText: "",
	enableColor: "",
	disableColor: "",
};

export default ToggleSwitch;
