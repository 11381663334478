import axios from "axios";
import { authToken } from "./common/functions";

let API_URL = window.location.origin.toString() + "/"; // "http://localhost:6006/";

export const Http = {
	axios: function () {
		return axios.create({
			baseURL: API_URL,
			headers: {
				Authorization: authToken(),
			},
		});
	},
};

export const HttpLocal = {
	axios: function () {
		return axios.create({
			baseURL: API_URL,
		});
	},
};
