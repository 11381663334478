import Swal from "sweetalert2";

export const showSweetAlert = (type, name, confirmCallback) => {
	switch (type) {
		case "deleteWarning":
			Swal.fire({
				title: `Are you sure you want to delete ${name}?`,
				icon: "warning",
				showDenyButton: true,
				denyButtonText: `No`,
				denyButtonColor: "#ACACAC ",
				confirmButtonColor: "#FF680B",
				confirmButtonText: "Yes",
			}).then((result) => {
				if (result.isConfirmed) {
					if (confirmCallback) {
						confirmCallback();
					}
				}
			});
			break;
		case "deleteSuccess":
			Swal.fire({
				position: "center",
				icon: "success",
				title: `${name} deleted successfully`,
				showConfirmButton: false,
				timer: 1500,
			});
			break;
		case "saveSuccess":
			Swal.fire({
				position: "center",
				icon: "success",
				title: `Saved successfully`,
				showConfirmButton: false,
				timer: 1500,
			});
			break;
		case "success":
			Swal.fire({
				position: "center",
				icon: "success",
				title: `Success!`,
				showConfirmButton: false,
				timer: 1500,
				html: name,
			});
			break;
		case "successOkOnly":
			Swal.fire({
				position: "center",
				icon: "success",
				title: `Success!`,
				html: name,
				showConfirmButton: true,
			});
			break;
		case "saveSuccessCustomMessage":
			Swal.fire({
				position: "center",
				icon: "success",
				title: `Saved successfully`,
				showConfirmButton: false,
				timer: 1500,
				html: name,
			});
			break;
		case "serverError":
			Swal.fire({
				position: "center",
				icon: "error",
				title: `Internal Server Error`,
				confirmButtonColor: "#ACACAC",
				confirmButtonText: "OK",
			});
			break;
		case "errorCustomMessage":
			Swal.fire({
				position: "center",
				icon: "error",
				title: name,
				confirmButtonColor: "#ACACAC",
				confirmButtonText: "OK",
			});
			break;
		case "unauthorized":
			Swal.fire({
				position: "center",
				icon: "warning",
				title: `You are not authorized to use this functionality.`,
				confirmButtonColor: "#ACACAC",
				confirmButtonText: "OK",
			});
			break;
		case "systemCategory":
			Swal.fire({
				position: "center",
				icon: "warning",
				title: `You cannot edit or delete system categories.`,
				confirmButtonColor: "#ACACAC",
				confirmButtonText: "OK",
			});
			break;
		case "warning":
			Swal.fire({
				position: "center",
				icon: "warning",
				title: "Are you sure?",
				text: name,
				confirmButtonColor: "#FF680B",
				confirmButtonText: "Yes",
				showDenyButton: true,
				denyButtonColor: "#ACACAC",
				denyButtonText: "No",
			}).then((result) => {
				if (result.isConfirmed) {
					if (confirmCallback) {
						confirmCallback();
					}
				}
			});
			break;
		case "warningCustomMessage":
			Swal.fire({
				position: "center",
				icon: "warning",
				text: name,
				confirmButtonColor: "#FF680B",
				confirmButtonText: "Yes",
				showDenyButton: true,
				denyButtonColor: "#ACACAC",
				denyButtonText: "No",
			}).then((result) => {
				if (result.isConfirmed) {
					if (confirmCallback) {
						confirmCallback();
					}
				}
			});
			break;
		case "warningOkOnly":
			Swal.fire({
				position: "center",
				icon: "warning",
				html: name,
				confirmButtonColor: "#ACACAC",
				confirmButtonText: "OK",
			}).then((result) => {
				if (result.isConfirmed) {
					if (confirmCallback) {
						confirmCallback();
					}
				}
			});
			break;
		default:
			break;
	}
};
