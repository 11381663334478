import { Http } from "../Http";
import { AxiosPromise } from "axios";
import apiCall from "./apiCall";

const clientPreferencesApi = {
	getTestResultTypes: async (input) => {
		const url =
			"ClientPreferences/GetTestResultTypes?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken;
		const response = await apiCall(url);
		return response;
	},

	getClientPreferences: async (input) => {
		const url =
			"ClientPreferences/GetClientPreferences?userId=" +
			input.userId +
			"&userToken=" +
			input.userToken;
		const response = await apiCall(url);
		return response;
	},

	saveClientPreferences: async (input) => {
		const obj = JSON.stringify(input);
		const url = "ClientPreferences/UpdateClientPreferences";
		const response = await apiCall(url, "put", obj);
		return response;
	},
};

export default clientPreferencesApi;

// export class ClientPreferencesApi {

// 	public static getTestResultTypes(input: any) {
// 		return Http.axios().get('/ClientPreferences/GetTestResultTypes?userId=' + input.userId + "&userToken=" + input.userToken)
// 			.catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}

// 	public static getClientPreferences(input: any) {
// 		return Http.axios().get('/ClientPreferences/GetClientPreferences?userId=' + input.userId + "&userToken=" + input.userToken)
// 			.catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}

// 	public static saveClientPreferences(input: any) {
// 		var obj = JSON.stringify(input);
// 		return Http.axios().put('/ClientPreferences/UpdateClientPreferences', obj, { headers: { 'Content-Type': 'application/json' } })
// 			.then(response => {
// 				return response;
// 			}).catch((e) => {
// 				return e.response;
// 			}) as AxiosPromise<any>;
// 	}
// }
